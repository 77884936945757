import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import Chart from 'react-apexcharts';
import { getClientName } from '../../../utilities/clientUtility';
import { isEmpty } from '../../../utilities/genericUtility';
import DashSidebarButton from '../DashSidebarButton/DashSidebarButton';

const DashChart = ({ openSelectClient, orderToKit, orderToPack, searchString }) => {
  const labelOfOrders = orderToKit.map(serviceKit => getClientName(serviceKit.externalClientId));
  const countOfOrders = orderToKit.map(serviceKit => serviceKit.count);
  const colorCodes = orderToKit.map(serviceKit => {
    const clientName = getClientName(serviceKit.externalClientId);
    switch (clientName) {
      case 'AT&T':
        return '#31c7ff';
      case 'AT&T NDES':
        return '#8223D2';
      case 'Sprint':
        return '#ffe438';
      case 'COX':
        return '#2CC84D';
      case 'US Cellular':
        return '#0731A1';
      case 'Verizon':
      default:
        return '#fb3c42';
    }
  });

  const chartData = {
    series: countOfOrders,
    labels: labelOfOrders,
    dataLabels: {
      enabled: false
    },
    colors: colorCodes,
    chart: {
      events: {
        click: function(event, chartContext, config) {
          console.info('Chart Clicked');
        }
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '36px',
              color: '#000000'
            },
            value: {
              show: true,
              fontSize: '36px',
              color: '#000000'
            },
            total: {
              show: true,
              label: 'Orders to kit'
            }
          }
        }
      }
    }
  };

  return (
    <div className="width-match">
      {orderToPack > 0 ? (
        <Chart
          type="donut"
          options={chartData}
          series={chartData.series}
          width="550"
          height="500"
        />
      ) : (
        <div className="flex-column no-chart-data">
          No Orders
          <p>to Kit</p>
        </div>
      )}
      <DashSidebarButton
        className="width-90"
        disable={orderToPack < 1 || !isEmpty(searchString)}
        onClick={openSelectClient}
        title="Start"
      />
    </div>
  );
};

DashChart.defaultProps = {
  orderToPack: 0
};

DashChart.propTypes = {
  orderToKit: PropTypes.array.isRequired,
  orderToPack: PropTypes.number,
  searchString: PropTypes.string
};

export default memo(DashChart);
