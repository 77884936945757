import React, { PureComponent } from 'react';
import HelpButton from '../WorkflowStep/HelpButton/HelpButton';
import OrderInfo from './OrderInfo/OrderInfo';
import './WorkflowHeader.scss';
import iconClose from './icon-close.svg';

class WorkflowHeader extends PureComponent {
  handleClick = () => {
    if (
      this.props.step === 1 ||
      (this.props.workflowOrder && this.props.step === this.props.workflowOrder.length + 1) ||
      this.props.returnWorkflow
    ) {
      this.props.closeWorkflow();
    } else {
      this.props.openModal('LeaveWorkflow');
    }
  };

  render() {
    return (
      <div className="workflow-header-container">
        <header className="workflow-header">
          <nav className="workflow-header__nav">
            <button
              onClick={this.handleClick.bind(this)}
              className="workflow-header-btn workflow-close-btn"
            >
              <img src={iconClose} className="workflow-header-btn__icon" alt="close" />
            </button>
            {((!this.props.returnWorkflow &&
              this.props.step !== this.props.workflowOrder.length + 1) ||
              (this.props.returnWorkflow && this.props.step !== 2)) && (
              <HelpButton {...this.props} />
            )}
          </nav>
          <OrderInfo {...this.props} />
          {this.props.timer}
        </header>
        {this.props.children}
      </div>
    );
  }
}

export default WorkflowHeader;
