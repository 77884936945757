import React, { memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { isEmpty } from '../../../utilities/genericUtility';
import DashAlert from '../DashAlert/DashAlert';
import DashChart from './DashChart';
import DashModal from './DashModal';
import DashTable from './DashTable';
import DashTableFilter from './DashTableFilter';
import '../../../styles/_reactTable-overrides.scss';
import './DashMain.scss';
import { TechnicianTable } from './TechnicianTable';

const DashMain = ({
  clientCounts,
  clientFilterSelect,
  currentOrder,
  displayRowInfo,
  openSelectClient,
  orderToKit,
  orderToPack,
  resumeable,
  rowSelected,
  searchString,
  showAlert,
  showCustomerInfo,
  showExceptions,
  showTable,
  showUnfinished,
  tableContent,
  unfinishedOnes,
  technicianOrders,
  technicianBucket
}) => {
  const rowToSelect = kitSequenceNumber => {
    const selectedData = tableContent.filter(
      order => order.kitSequenceNumber === kitSequenceNumber
    )[0];
    rowSelected(selectedData);
  };
  const data = tableContent.filter(order => {
    order.customerFullName = `${order.customerFirstName ? order.customerFirstName : ''} ${order.customerLastName ? order.customerLastName : ''
      }`.trim();
    order.customerFullName = !isEmpty(order.customerFullName)
      ? `${order.customerFullName}|${order.externalClientId}`
      : `|${order.externalClientId}`;
    return order;
  });
  const chartProps = { openSelectClient, orderToKit, orderToPack, searchString };
  let content = showTable ? (
    <>
      {currentOrder && (
        <DashModal
          closeModal={showCustomerInfo}
          currentOrder={currentOrder}
          searchString={searchString}
          showContent={displayRowInfo}
        />
      )}
      {clientCounts.length > 1 && <DashTableFilter clientFilterSelect={clientFilterSelect} />}
      <DashTable
        exceptions={showExceptions}
        orders={data}
        resumeable={resumeable}
        rowSelected={rowToSelect}
        searchString={searchString}
        showCustomerInfo={showCustomerInfo}
      />
    </>
  ) : (
    <DashChart {...chartProps} />
  );
  if (technicianOrders) {
    content = <TechnicianTable technicianBucket={technicianBucket} />
  }
  return (
    <main className={showTable ? 'dash-main' : 'dash-main-chart'} role="main" id="main-content">
      {showAlert && (
        <DashAlert
          buttonValue="View order"
          message={`You have ${unfinishedOnes} unfinished order${unfinishedOnes > 1 ? 's' : ''}`}
          onClick={showUnfinished}
          showAlert={unfinishedOnes !== 0}
        />
      )}
      {content}
    </main>
  );
};

DashMain.defaultProps = {
  orderToKit: [
    {
      externalClientId: 300,
      count: 0
    },
    {
      externalClientId: 31,
      count: 0
    },
    {
      externalClientId: 244,
      count: 0
    }
  ],
  orderToPack: 0,
  resumeable: false,
  showAlert: false,
  showTable: false,
  unfinishedOnes: 0
};

DashMain.propTypes = {
  openSelectClient: PropTypes.func,
  orderToKit: PropTypes.array,
  orderToPack: PropTypes.number,
  resumeable: PropTypes.bool,
  rowSelected: PropTypes.func,
  showAlert: PropTypes.bool,
  showExceptions: PropTypes.bool,
  showTable: PropTypes.bool,
  showUnfinished: PropTypes.func,
  tableContent: PropTypes.array,
  unfinishedOnes: PropTypes.number,
  technicianOrders: PropTypes.bool,
  technicianBucket: PropTypes.any
};

const mapStateToProps = ({ order }) => ({
  clientCounts: order.clientCounts
});

export default connect(
  mapStateToProps,
  null
)(memo(DashMain));
