import React, { memo } from 'react';
import { formatPhoneNumber } from '../../../../utilities/genericUtility';

const ContactInfo = ({ name, description, email, number }) => (
  <tr>
    <td>{name}</td>
    <td>{description}</td>
    <td>{email}</td>
    <td>{formatPhoneNumber(number)}</td>
  </tr>
);

export default memo(ContactInfo);
