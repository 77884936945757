import React, { Component } from 'react';
import { MenuContentType } from '../../../utilities/enums';
import NewsSection from './NewsSection/NewsSection';
import HelpSection from './HelpSection/HelpSection';
import ExpertList from './ExpertListSection/ExpertList';
import './MenuContent.scss';

const ContentRouter = props => {
  switch (props.contentType) {
    case MenuContentType.ExpertList:
      return <ExpertList {...props} />;
    case MenuContentType.HotNews:
      return <NewsSection {...props} />;
    case MenuContentType.NeedHelp:
    default:
      return <HelpSection {...props} />;
  }
};

class MenuContent extends Component {
  render() {
    return (
      <main className="menu-content-container">
        <ContentRouter contentType={this.props.contentType} />
      </main>
    );
  }
}

export default MenuContent;
