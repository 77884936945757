import {
  UPDATE_CONTACT_INFO,
  UPDATE_EXPERT_LIST_ARRAY,
  UPDATE_HOT_NEWS,
  UPDATE_HOT_NEWS_COUNT
} from '../actions/configurationActions';

const initialState = {
  contactInfo: [],
  expertListInfo: [],
  hotNews: [],
  hotNewsCount: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTACT_INFO:
      return {
        ...state,
        contactInfo: action.payload
      };
    case UPDATE_EXPERT_LIST_ARRAY:
      return {
        ...state,
        expertListInfo: action.payload
      };
    case UPDATE_HOT_NEWS:
      return {
        ...state,
        hotNews: action.payload.newsInfo,
        updatedHotNews: action.payload.latestHotNews,
        hotNewsCount: action.payload.count
      };
    case UPDATE_HOT_NEWS_COUNT:
      return {
        ...state,
        updatedHotNews: action.payload.latestHotNews,
        hotNewsCount: action.payload.count
      };
    default:
      return state;
  }
};
