import React, { Component } from 'react';
import './SecondaryButton.scss';
// import FocusLock from "react-focus-lock";

class SecondaryButton extends Component {
  render() {
    return (
      <button onClick={this.props.onClick} className="workflow-secondary-btn" autoFocus>
        {this.props.icon ? (
          <img src={this.props.icon} className="workflow-secondary-btn__icon" alt="" />
        ) : null}
        {this.props.text}
      </button>
    );
  }
}

export default SecondaryButton;
