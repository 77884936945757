import React, { memo } from 'react';
import classNames from 'classnames';
import { patienceDiff } from '../../../utilities/genericUtility';
import './DifferenceSpan.scss';

const getDisplayList = (difference, value) => {
  const values = [];
  let substr = '',
    uncommon = '';
  const chars = [...value];
  for (let i = 0; i < chars.length; i++) {
    if (!difference.includes(i)) {
      substr += chars[i];
      if (uncommon !== '') {
        values.push(<span class="underline">{uncommon}</span>);
        uncommon = '';
      }
    } else {
      if (substr !== '') {
        values.push(substr);
        substr = '';
      }
      uncommon += chars[i];
    }
  }
  if (uncommon !== '') {
    values.push(<span class="underline">{uncommon}</span>);
  }
  if (substr !== '') {
    values.push(substr);
    substr = '';
  }
  return values;
};

const getValueWithDifference = (actual, expected) => {
  const actuals = actual.split('-');
  const expecteds = expected.split('-');
  const returnValue = [];
  for (let i = 0; i < actuals.length; i++) {
    if (i !== 0) returnValue.push('-');
    if (expecteds[i]) {
      const difference = patienceDiff(actuals[i], expecteds[i]);
      const differIndex = [];
      difference.lines.forEach(line => {
        if (line.bIndex === -1) differIndex.push(line.aIndex);
      });
      const displayValues = getDisplayList(differIndex, actuals[i]);
      displayValues.forEach(value => returnValue.push(value));
    } else {
      returnValue.push(<span class="underline">{actuals[i]}</span>);
    }
  }
  return returnValue;
};

const DifferenceSpan = ({ before, expected, type, value }) => {
  const classes = classNames(
    'difference-span-div',
    { 'error-ds': type === 'error' },
    { 'info-ds': type === 'info' }
  );
  const neededValue = getValueWithDifference(value, expected);
  return (
    <div className="difference-span-container">
      <p>{before}</p>
      <div className={classes}>{neededValue}</div>
    </div>
  );
};

export default memo(DifferenceSpan);
