import React, { memo } from 'react';
import './Modal.scss';
import ErrorIcon from './error-icon.svg';
import WarningIcon from './warning-icon.svg';

const Modal = ({ buttons, closeModal, icon, isLoader, message, title }) => {
  let iconDetails = null;
  switch (icon) {
    case 'error':
      iconDetails = ErrorIcon;
      break;
    case 'warning':
      iconDetails = WarningIcon;
      break;
    default:
      iconDetails = null;
      break;
  }
  return (
    <div className="modal">
      <div className="modal-in">
        {!isLoader ? (
          <>
            {typeof closeModal === 'function' && <></>}
            <div className="modal-main">
              {iconDetails !== null && (
                <img src={iconDetails} alt="" className="workflow-step__messaging__icon" />
              )}
              <h1 className="modal-title">{title}</h1>
              <div className="modal-message">{message}</div>
              <div className="modal-buttons">{buttons}</div>
            </div>
          </>
        ) : (
          <div className="modal-main">
            <h1 className="modal-title">{title}</h1>
            <div className="modal-loader">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isLoader: false
};

export default memo(Modal);
