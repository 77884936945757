import {
  CLEAR_CLIENT_SELECTION,
  GET_ORDERS,
  SELECT_CLIENT_FOR_WORKFLOW,
  UPDATE_TOTAL_COUNT,
  SET_CURRENT_SKU_INFO
} from '../actions/orderActions';

const initialState = {
  clientCounts: [],
  orderToPack: -1,
  selectedClient: {},
  serviceKitDisplayRows: [],
  currentOrderSkuInfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CLIENT_SELECTION:
      return {
        ...state,
        selectedClient: {}
      };
    case GET_ORDERS:
      const orders = JSON.parse(JSON.stringify(action.payload));

      orders.serviceKitOrders.forEach(bucket => {
        if (bucket.serviceKitDisplayRows.length > 0) {
          bucket.serviceKitDisplayRows.sort((a, b) => {
            let aLastTwo = a.kitSequenceNumber;
            let bLastTwo = b.kitSequenceNumber;

            if (aLastTwo && bLastTwo) {
              aLastTwo = a.kitSequenceNumber.slice(-2);
              bLastTwo = b.kitSequenceNumber.slice(-2);
            }

            return parseInt(aLastTwo) - parseInt(bLastTwo);
          });
        }
      });

      return {
        ...state,
        clientCounts: action.payload.clientCounts,
        orderToPack: action.payload.orderToPack,
        serviceKitDisplayRows: orders.serviceKitOrders
      };
    case SELECT_CLIENT_FOR_WORKFLOW:
      return {
        ...state,
        selectedClient: action.payload
      };
    case UPDATE_TOTAL_COUNT:
      return {
        ...state,
        orderToPack: action.count
      };
    case SET_CURRENT_SKU_INFO:
      return {
        ...state,
        currentOrderSkuInfo: action.payload
      };
    default:
      return state;
  }
};
