import axios from 'axios';
import config from '../config';
import { isEmpty } from './genericUtility';

export const uriName = 'fsl-kitting';
export const authUriName = 'fsl-authentication';

const apiMapping = {
  GET: axios.get,
  POST: axios.post
};

export const userInfo = {
  companyId: 0,
  token: '',
  refreshToken: '',
  userName: 'fsluser',
  apiKey: '',
  sessionId: ''
};

const getBaseUri = baseUrl => endpoint => `${baseUrl}${endpoint}`;

const getUrl = name => {
  const endpoints = config.API.endpoints.filter(ep => ep.name.toLowerCase() === name.toLowerCase());
  userInfo.apiKey = endpoints[0].apikey;
  return getBaseUri(
    `${endpoints[0].endpoint}${!isEmpty(endpoints[0].suburi) ? endpoints[0].suburi : ''}`
  );
};

export const getWSUrl = () => {
  return `${config.WS.endpoint}?token=${userInfo.token}&correlationId=${sessionStorage.getItem(
    'correlationId'
  )}
          &sessionId=${userInfo.sessionId}&sender=fslsdsui`;
};

const getHeaders = customHeaders => {
  if (userInfo.apiKey === '') {
    const endpoints = config.API.endpoints.filter(ep => ep.name.toLowerCase() === 'fsl-kitting');
    userInfo.apiKey = endpoints[0].apikey;
  }
  const commonHeaders = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'x-api-key': userInfo.apiKey,
    Accept: 'application/json',
    Authorization: `Bearer ${userInfo.token}`,
    companyId: userInfo.companyId,
    correlationId: sessionStorage.getItem('correlationId'),
    sender: 'fslui',
    sessionId: userInfo.sessionId,
    username: userInfo.userName
  };
  return { ...commonHeaders, ...customHeaders };
};

const executeApi = (resolve, reject) => (apiName, endpoint, bodyContent, config) => {
  const { method = 'GET', body = null } = bodyContent;
  const arrayOfData = [getUrl(apiName)(endpoint), body, config].filter(item => item !== null);
  apiMapping[method]
    .apply(axios, arrayOfData)
    .then(res => manageResponse(resolve, reject, res))
    .catch(err => {
      const errorToReturn = manageError(err);
      reject(errorToReturn);
    });
};

const manageResponse = (resolve, reject, res) =>
  res.status === 200 ? resolve(res.data) : reject('Error occurred while getting the response');

const manageError = payload => {
  if (payload.response && payload.response.status !== 200) {
    if (payload.response.status !== 400 && payload.response.statusText !== '')
      return buildErrorMessage(payload.response.status, payload.response.statusText);
    else if (payload.response.data && payload.response.data.Error) {
      const { Code, Message } = payload.response.data.Error;
      return buildErrorMessage(Code, Message);
    }
  } else
    return buildErrorMessage('Server Issue', 'Server Issue : Please contact system administrator');
};

const buildErrorMessage = (code, message) => ({
  Error: {
    Code: `${code}`,
    Message: message
  }
});

export const Get = (apiName, path, headers) => {
  return new Promise((resolve, reject) => {
    const custom_headers = {
      headers: headers ? headers : getHeaders(),
      data: {} // Needed for passing Content-Type in headers
    };
    executeApi(resolve, reject)(apiName, path, {}, custom_headers);
  });
};

export const Post = (apiName, path, data) => {
  return new Promise((resolve, reject) => {
    const content = {
      body: { ...data },
      method: 'POST'
    };
    const custom_headers = {
      headers: getHeaders()
    };
    executeApi(resolve, reject)(apiName, path, content, custom_headers);
  });
};
