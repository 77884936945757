import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { getClientId, getClientName } from '../../../../utilities/clientUtility';
import {
  dashboardApiCall,
  getOrders,
  selectClientForWorkflow,
  updateTotalCount
} from '../../../../actions/orderActions';
import Button from '../../../Common/Button/Button';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import SuccessIcon from './icon-success--large.svg';
import './Done.scss';

const DoneInfo = ({ kitSequenceNumber }) => (
  <>
    <img src={SuccessIcon} alt="" className="workflow-step__messaging__icon" />
    <h1 className="workflow-step__messaging__title">
      Order{' '}
      <span className="workflow-order-number date-lbl--inverted">
        <span className="workflow-order-number__orderID">
          {parseInt(kitSequenceNumber.toString().slice(2))}
        </span>
        <span className="workflow-order-number__dateCode">
          {parseInt(kitSequenceNumber.toString().substring(0, 2))}
        </span>
      </span>{' '}
      is ready for pickup.
    </h1>
  </>
);

class Done extends Component {
  state = {
    currentCarrierCount: -1,
    otherCarrierName: ''
  };

  componentDidMount = async () => {
    await this.getDataOnLoad();
  };

  getDataOnLoad = async () => {
    let sko = this.props.serviceKitOrders;
    if (!this.props.wsconnected) {
      const res = await dashboardApiCall();
      sko = res.serviceKitOrders;
    }
    const orderToPack = sko.filter(order => order.serviceKitOrderStatusType === 'OrdersToPack')[0];
    const clientData = orderToPack.serviceKitOrderClientCounts.filter(
      order => order.externalClientId === this.props.selectedClient.externalClientId
    )[0];
    const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
      order =>
        order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
    );
    this.setState({
      currentCarrierCount: clientData ? clientData.count : 0,
      otherCarrierName: otherCarrierData[0]
        ? getClientName(otherCarrierData[0].externalClientId)
        : null
    });
    if (!this.props.wsconnected)
      await this.props.updateTotalCount(parseInt(orderToPack.serviceKitDisplayRows.length));
  };

  handleClick = async () => {
    if (this.props.orderToPack > 0) {
      if (!this.props.wsconnected) await this.props.getOrders(false);
      if (this.state.currentCarrierCount === 0)
        await this.props.selectClientForWorkflow(getClientId(this.state.otherCarrierName));
      await this.props.setAsPacked();
      if (this.props.processingStarted) this.props.resetCurrentFlow();
    } else await this.props.closeWorkflow();
  };

  render() {
    const currentClient = getClientName(this.props.selectedClient.externalClientId);
    const infoContent =
      this.props.orderToPack > 0 ? (
        this.state.currentCarrierCount > 0 ? (
          <p>
            You have <span className="bold">{this.state.currentCarrierCount}</span> more{' '}
            {getClientName(this.props.selectedClient.externalClientId)} orders to pack.
          </p>
        ) : (
          <>
            <p>All {currentClient} orders are packed, for now.</p>
            <p>
              Be sure to check after you’re done packing for other carriers.
              <p>There might be more {currentClient} orders.</p>
            </p>
          </>
        )
      ) : (
        <p>All orders are packed</p>
      );
    const buttonLabel =
      this.props.orderToPack > 0
        ? this.state.currentCarrierCount > 0
          ? 'Next Order'
          : `Start ${this.state.otherCarrierName} orders`
        : 'Complete';
    const showReturn = this.props.orderToPack > 0 && this.state.currentCarrierCount === 0;
    return (
      <div className="workflow-main">
        <CSSTransitionGroup
          transitionName="workflow-step"
          transitionAppear={true}
          transitionAppearTimeout={5000}
          transitionEnterTimeout={5000}
          transitionLeaveTimeout={3000}
        >
          {this.state.currentCarrierCount !== -1 && (
            <div className="workflow-step">
              <div className="workflow-step__main">
                <div className="workflow-step__messaging">
                  <DoneInfo kitSequenceNumber={this.props.kitInfo.kitSequenceNumber} />
                  {infoContent}
                </div>
              </div>
              <div className="workflow-step__footer">
                <PrimaryButton text={buttonLabel} onClick={this.handleClick.bind(this)} />
              </div>
            </div>
          )}
          {showReturn && (
            <div className="flex-row done-page--end">
              <Button text="Return to dashboard" onClick={this.props.closeWorkflow} type="link" />
            </div>
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

Done.defaultProps = {
  kitInfo: {},
  orderToPack: -1
};

Done.propTypes = {
  getOrders: PropTypes.func,
  kitInfo: PropTypes.object,
  orderToPack: PropTypes.number,
  processingStarted: PropTypes.bool
};

const mapStateToProps = ({ order, serviceKit, websocket }) => {
  const { orderToPack, selectedClient, serviceKitDisplayRows } = order;
  const { processingServiceKit, processingStarted } = serviceKit;
  return {
    kitInfo: processingServiceKit,
    orderToPack,
    processingStarted,
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    wsconnected: websocket.connected
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrders,
      selectClientForWorkflow,
      updateTotalCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Done);
