import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Barcode from 'react-barcode';
import PropTypes from 'prop-types';
import Row from '../Common/Row';
import Col from '../Common/Column';
import './PackingLabel.scss';

class PackingLabel extends PureComponent {
  render() {
    const { customerContact, kitSequenceNumber, zone } = this.props.kitInfo;
    const dateCode = parseInt(kitSequenceNumber.toString().substring(0, 2));
    const orderID = parseInt(kitSequenceNumber.toString().slice(2));
    const { customerFirstName, customerLastName } = customerContact;
    const name = `${customerFirstName ? customerFirstName + ' ' : ''}${
      customerLastName ? customerLastName : ''
      }`;
    return (
      <div className={this.props.class}>
        {this.props.printEsn ?
          <Barcode
            fontSize={24}
            height={70}
            width={3}
            text={this.props.esnNumber}
            value={this.props.esnNumber}
          />
          :
          <>
            <Row>
              <Col threeoffour>
                <Barcode
                  fontSize={24}
                  height={50}
                  marginLeft={10}
                  text={this.props.kitInfo.kitReferenceNumber}
                  value={this.props.kitInfo.kitReferenceNumber}
                />
              </Col>
              <Col oneoffour>
                <div className="date-label">
                  <div className="order-id">{orderID}</div>
                  <div className="date-check">{dateCode}</div>
                </div>
              </Col>
            </Row>
            {zone && <div className="zone-info">{`Zone ${zone}`}</div>}
            <div className="address-label">
              <div className="packingLabelText--bold">{name.toUpperCase()}</div>
            </div>
          </>}
      </div>
    );
  }
}

PackingLabel.defaultProps = {
  kitInfo: {}
};

PackingLabel.propTypes = {
  kitInfo: PropTypes.object,
  esnNumber: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  return {
    kitInfo: serviceKit.processingServiceKit,
    esnNumber: serviceKit.esnNumber
  };
};

export default connect(mapStateToProps, null)(PackingLabel);
