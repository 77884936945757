import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { returnOrder } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import Envelope from '../Envelope/Envelope';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import prettyBox from './pretty-box-label.png';
import './SKU.scss';

class ReturnSKU extends PureComponent {
  goToNextStep() {
    if (this.props.resumeKitCompleted) this.props.nextStep(this.props.step);
    else setTimeout(() => this.goToNextStep(), 500);
  }

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Scan the ESN on the device box."
        footer={
          <PrimaryInput
            nextStep={this.goToNextStep.bind(this)}
            onSubmit={this.props.returnOrder}
            placeholder="Scan barcode"
            rules="required|esn"
            title="SKU Barcode"
            type="SKU"
          />
        }
        after={<></>}
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Scan the TOP barcode on the box, labeled "ESN (dec)".</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={prettyBox} className="step-5-graphic" alt="" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

ReturnSKU.propTypes = {
  resumeKitCompleted: PropTypes.bool,
  returnOrder: PropTypes.func
};

Envelope.defaultProps = {
  resumeKitCompleted: false
};

const mapStateToProps = ({ serviceKit }) => {
  const { partStatus } = serviceKit;
  return {
    resumeKitCompleted: !isEmpty(partStatus)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      returnOrder
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReturnSKU);
