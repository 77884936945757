import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { isEmpty } from '../../../utilities/genericUtility';
import { getClientName } from '../../../utilities/clientUtility';
import './WorkflowStep.scss';

const ClientInfo = ({ clientId }) => {
  const clientName = getClientName(clientId);
  const classes = classNames(
    'workflow-step__clienttag',
    { 'sprint-background': clientName === 'Sprint' },
    { 'verizon-background': clientName === 'Verizon' },
    { 'att-background': clientName === 'AT&T' },
    { 'attndes-background': clientName === 'AT&T NDES' },
    { 'cox-background': clientName === 'COX' },
    { 'uscellular-background': clientName === 'US Cellular' }
  );
  return (
    <div className={classes}>
      <label>{clientName}</label>
    </div>
  );
};

const WorkflowStep = ({
  after,
  banner,
  children,
  footer,
  selectedClient,
  showStep,
  step,
  subtitle,
  title
}) => (
  <div className="workflow-main">
    <CSSTransitionGroup
      transitionAppear={true}
      transitionAppearTimeout={5000}
      transitionEnterTimeout={5000}
      transitionLeaveTimeout={3000}
      transitionName="workflow-step"
    >
      <div className="workflow-step">
        <div className="workflow-step__main">
          <div className="workflow-step__header">
            {showStep && <h2 className="workflow-step__number">Step {step}</h2>}
            <h1 className="workflow-step__title">{title}</h1>
            {subtitle ? <p className="workflow-step__subtitle">{subtitle}</p> : null}
          </div>
          {banner}
          <div className="workflow-step__content">{children}</div>
          {!isEmpty(selectedClient) && <ClientInfo clientId={selectedClient.externalClientId} />}
        </div>
        <div className="workflow-step__footer">{footer}</div>
      </div>
      {after}
    </CSSTransitionGroup>
  </div>
);

WorkflowStep.propTypes = {
  showStep: PropTypes.bool
};

WorkflowStep.defaultProps = {
  showStep: true
};

export default memo(WorkflowStep);
