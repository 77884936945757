import React, { memo } from 'react';
import classNames from 'classnames';
import { getClientName } from '../../../utilities/clientUtility';

const ClientLabel = ({ externalClientId }) => {
  const clientName = getClientName(`${externalClientId}`);

  const classes = classNames(
    'table-client-name',
    { 'sprint-border--left': clientName === 'Sprint' },
    { 'verizon-border--left': clientName === 'Verizon' },
    { 'att-border--left': clientName === 'AT&T' },
    { 'attndes-border--left': clientName === 'AT&T NDES' },
    { 'cox-border--left' : clientName === 'COX'},
    { 'uscellular-border--left' : clientName === 'US Cellular'}
  );

  return clientName ? <div className={classes}>{clientName}</div> : <div></div>;
};

export default memo(ClientLabel);
