import React, { memo } from 'react';
import Section from '../../Section/Section';

const PrintLabel = () => (
  <div>
    <Section title="Label printer not working?">
      <label>See if the facility has another printer on site, and try printing to that.</label>
    </Section>
    <Section title="No available printers?">
      <label>Write the Order ID and unique number on the short side of the box.</label>
    </Section>
    <Section title="Out of labels?">
      <label>
        Contact Asurion FSL Management. Then try printing to another printer in your facility.
      </label>
    </Section>
  </div>
);

export default memo(PrintLabel);
