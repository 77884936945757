import React, { memo } from 'react';
import classNames from 'classnames';
import {
  convertDateToES,
  downloadFile,
  getCurrentTimeStamp
} from '../../../../utilities/genericUtility';
import attachmentIcon from '../../../../icons/paperclip.svg';

const NewsHeading = ({ createdOn, heading, postedOn }) => {
  const badgeClass = classNames('news-heading__badge', {
    'news-heading__badge-new': Date.parse(createdOn) + '' > getCurrentTimeStamp()
  });
  return (
    <div className="news-heading__container">
      <div className={badgeClass} />
      <div className="news-heading__title">
        <label>{heading}</label>
        Posted {convertDateToES(postedOn)}
      </div>
    </div>
  );
};

const NewsBody = ({ message, attachments, getFile }) => (
  <div className="news-body__container">
    <div className="news-body__content">{message}</div>
    <NewsAttachment attachments={attachments} getFile={getFile} />
  </div>
);

const NewsAttachment = ({ attachments, getFile }) => (
  <div className="news-attachment__container">
    <div className="news-attachment__heading">
      <img src={attachmentIcon} alt="Attachment Heading" />
      <h1>Attachments</h1>
    </div>
    {attachments.map(
      (attachment, index) =>
        attachment.action !== 'DELETE' && (
          <div
            onClick={() =>
              (attachment.file && downloadFile(attachment.file.fileName, attachment.file)) ||
              (attachment.s3FileName && getFile(attachment))
            }
            className="news-attachment__link"
            key={index}
          >
            {attachment.attachmentName}
          </div>
        )
    )}
  </div>
);

const NewsContent = ({ hotNews, getFile }) =>
  hotNews.map((news, index) => (
    <div className="new-content__container" key={index}>
      <NewsHeading
        heading={news.title}
        postedOn={news.updatedTimeStamp}
        createdOn={news.createdTimeStamp}
      />
      <NewsBody
        message={news.info.articleContent}
        attachments={news.info.attachments}
        getFile={getFile}
      />
    </div>
  ));

export default memo(NewsContent);
