import React, { memo } from 'react';
import classNames from 'classnames';
import './FilterTab.scss';

const FilterTab = ({ name, onClick, selected }) => {
  const classes = classNames('filter-tab__item', {
    'filter-tab__item-selected': selected
  });
  return (
    <div className={classes} onClick={onClick}>
      {name}
    </div>
  );
};

export default memo(FilterTab);
