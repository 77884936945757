import React, { Component } from 'react';
import NoFirstNetSim from './NoFirstNetSim';
import FirstNetStep from './FirstNetStep';

class FirstNetSim extends Component {
  state = {
    noFirstNetFlow: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noFirstNetFlow) {
      this.setState({
        noFirstNetFlow: false
      });
    }
  }

  noFirstNetInStock = () => {
    this.setState({
      noFirstNetFlow: true
    });
  };

  render() {
    if (this.state.noFirstNetFlow) return <NoFirstNetSim {...this.props} />;
    else return <FirstNetStep {...this.props} secondaryClick={this.noFirstNetInStock} />;
  }
}

export default FirstNetSim;
