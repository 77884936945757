import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { AlertType, CollateralType } from '../../../utilities/enums';
import { isEmpty } from '../../../utilities/genericUtility';
import Alert from '../Alert';

const AlertRouter = ({ alert, prevStep, resetCurrentStep, step, workflowOrder }) => {
  const workflowStep = !isEmpty(workflowOrder[step - 1])? workflowOrder[step - 1] : 'done';
  switch (alert) {
    case AlertType.ExceptionalFlow:
      return (
        <Alert
          type="warning"
          message="This is an exception. Please follow the instructions below carefully."
        />
      );
    case AlertType.FirstNetSimAvailable:
      return (
        <Alert
          type="help"
          message="Not out of FirstNet SIM?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.OutOfCollateral:
      return (
        <Alert
          type="help"
          message={`Not out of${
            CollateralType[workflowStep] ? ' ' + CollateralType[workflowStep] : ''
          } setup guides?`}
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.OutOfEnvelope:
      return (
        <Alert
          type="help"
          message="Not out of return envelopes?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.OutOfHomePlusGuide:
      return (
        <Alert
          type="help"
          message="Not out of AT&T HomeTech Protection brochures?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.OutOfVPHGuide:
      return (
        <Alert
          type="help"
          message="Not out of VHDP brochures?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.OutOfRightToCancelBrochures:
      return (
        <Alert
          type="help"
          message="Not out of Right to Cancel brochures?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.OutOfVPHTOSBooklet:
      return (
        <Alert
          type="help"
          message="Not out of booklets?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    case AlertType.RetryPrinting:
      return (
        <Alert
          type="help"
          message="Shipping label not print correctly?"
          onClickText="GO BACK."
          onClick={prevStep}
        />
      );
    case AlertType.SKUAvailable:
      return (
        <Alert
          type="help"
          message="Not out of this SKU?"
          onClickText="GO BACK."
          onClick={resetCurrentStep}
        />
      );
    default:
      return <Alert type="help" message="Alert!" />;
  }
};

AlertRouter.defaultProps = {
  alert: ''
};

AlertRouter.propTypes = {
  alert: PropTypes.string,
  prevStep: PropTypes.func,
  resetCurrentStep: PropTypes.func
};

export default memo(AlertRouter);
