import React, { memo } from 'react';
import classNames from 'classnames';
import './DashSidebarButton.scss';

const DashSidebarButton = ({ className, disable, onClick, title }) => {
  const classes = classNames(
    { 'dash-sidebar-button': !disable },
    { 'dash-sidebar-button--disabled': disable },
    className
  );
  return (
    <button onClick={onClick} className={classes} disabled={disable}>
      {title}
    </button>
  );
};

export default memo(DashSidebarButton);
