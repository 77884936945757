import React, { memo } from 'react';
import Section from '../../Section/Section';

const AffixAndScan = () => (
  <div>
    <Section title="Where does the label go on the box?">
      <label>Affix the label to one of the two shorter ends of the box.</label>
    </Section>
    <Section title="Scanner not scanning the label?">
      <label>Enter the barcode number manually using the keyboard and press enter.</label>
    </Section>
  </div>
);

export default memo(AffixAndScan);
