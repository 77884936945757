import React, { memo } from 'react';
import classNames from 'classnames';
import './DashWidget.scss';

const DashWidgetHeader = ({ title, count }) => {
  const classes = classNames('dash-widget__header', { 'dash-widget__count0': count === 0 });
  return (
    <div className={classes}>
      <h2 className="dash-widget__title">{title}</h2>
      <h2 className="dash-widget__count">{count}</h2>
    </div>
  );
};

const DashWidgetMain = ({ children }) => <div className="dash-widget__main">{children}</div>;

const DashWidget = ({ title, count, onClick, selected, children }) => {
  const classes = classNames({ 'dash-widget': !selected }, { 'dash-widget__selected': selected });

  return (
    <div className={classes} onClick={onClick}>
      <DashWidgetHeader title={title} count={count} />
      <DashWidgetMain>{children}</DashWidgetMain>
    </div>
  );
};

export default memo(DashWidget);
