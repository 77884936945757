import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AlertType } from '../../../../utilities/enums';
import {
  dashboardApiCall,
  getOrders,
  outOfStockSku,
  selectClientForWorkflow,
  updateTotalCount
} from '../../../../actions/orderActions';
import { getClientId, getClientName } from '../../../../utilities/clientUtility';
import Col from '../../../Common/Column';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import './SKU.scss';

class NoSKU extends Component {
  state = {
    currentCarrierCount: -1,
    otherCarrierName: ''
  };

  componentDidMount = async () => {
    await this.getDataOnLoad();
    this.props.openAlert(AlertType.SKUAvailable);
  };

  getDataOnLoad = async () => {
    let sko = this.props.serviceKitOrders;
    if (!this.props.wsconnected) {
      const res = await dashboardApiCall();
      sko = res.serviceKitOrders;
    }
    const orderToPack = sko.filter(order => order.serviceKitOrderStatusType === 'OrdersToPack')[0];
    const clientData = orderToPack.serviceKitOrderClientCounts.filter(
      order => order.externalClientId === this.props.selectedClient.externalClientId
    )[0];
    const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
      order =>
        order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
    );
    this.setState({
      currentCarrierCount: clientData ? clientData.count : 0,
      otherCarrierName: otherCarrierData[0]
        ? getClientName(otherCarrierData[0].externalClientId)
        : null
    });
    if (!this.props.wsconnected)
      await this.props.updateTotalCount(parseInt(orderToPack.serviceKitDisplayRows.length));
  };

  handleClick = async e => {
    await this.props.outOfStockSku();
    if (this.props.orderToPack > 0) {
      if (!this.props.wsconnected) await this.props.getOrders(false);
      if (this.state.currentCarrierCount === 0)
        await this.props.selectClientForWorkflow(getClientId(this.state.otherCarrierName));
      await this.props.setAsPacked();
      if (this.props.processingStarted) this.props.resetCurrentFlow();
    } else {
      await this.props.closeWorkflow();
    }
  };

  componentWillUnmount() {
    this.props.closeAlert();
  }

  render() {
    const buttonLabel =
      this.props.orderToPack > 0
        ? this.state.currentCarrierCount > 0
          ? 'Next Order'
          : `Start ${this.state.otherCarrierName} orders`
        : 'Complete';
    return (
      <WorkflowStep
        {...this.props}
        title="The SKU below is out of stock."
        showStep={false}
        footer={<PrimaryButton onClick={this.handleClick.bind(this)} text={buttonLabel} />}
        banner={
          <div className="workflow-primary-field">
            <div className="workflow-step__sku">
              <span className="workflow-step__sku__number">{this.props.kitInfo.sku}</span>
            </div>
          </div>
        }
        after={<div />}
      >
        <Row>
          <Col oneofone>
            <label className="cancelled-notes">
              Returned or canceled order with this SKU available or clicked out of stock by mistake?
            </label>
          </Col>
        </Row>
        <div style={{ paddingTop: '15px' }}>
          <Row gutter>
            <Col oneofone>
              <>
                {/* <label className="cancelled-subnotes">
                  If there are no returned or canceled orders with this SKU:
                </label> */}
                <ol className="cancelled-notes-steps">
                  <li>
                    <div style={{ display: 'flex' }}>
                      Yes—&nbsp;
                      <div onClick={this.props.resetCurrentStep} className="hyperlink-text">
                        GO BACK
                      </div>
                      &nbsp; and use available SKU for the current order.
                    </div>
                  </li>

                  <li>
                    No—move this order to the unfinished table and contact Asurion FSL Management for next
                    steps.
                  </li>
                </ol>
              </>
            </Col>
          </Row>
        </div>
      </WorkflowStep>
    );
  }
}

NoSKU.propTypes = {
  kitInfo: PropTypes.object,
  noSkuAvailable: PropTypes.func
};

NoSKU.defaultProps = {
  kitInfo: {}
};

const mapStateToProps = ({ order, serviceKit, websocket }) => {
  const { processingServiceKit, processingStarted } = serviceKit;
  const { orderToPack, selectedClient, serviceKitDisplayRows } = order;
  return {
    kitInfo: processingServiceKit,
    orderToPack,
    processingStarted,
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    wsconnected: websocket.connected
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrders,
      outOfStockSku,
      selectClientForWorkflow,
      updateTotalCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NoSKU);
