import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import attESimInfo from './SetUpGuideEsim.svg';
import './Collateral.scss';

const CollateralFirstNetEsim = props => {
  return (
    <WorkflowStep
      {...props}
      title="Pack a FIRSTNETESIM Setup Guide."
      footer={
        <>
          <PrimaryInput
            nextStep={() => props.packCollateral('FIRSTNETESIM')}
            placeholder="Scan barcode"
            rules={'required|collateralfirstnetesim'}
            title="Collateral barcode"
            type="barcode"
          />
          {props.isSkipAllowed && (
            <Button text="Skip Step" onClick={() => props.skipStep()} type="compact" />
          )}
        </>
      }
      after={
        !props.isSkipAllowed && (
          <SecondaryButton text="Out of FirstNetEsim setup guides?" onClick={props.noCollateral} />
        )
      }
    >
      <Row gutter>
        <Col oneoftwo>
          <ul>
            <li>
              Get an FIRSTNETESIM Setup Guide from the{' '}
              <span className="color-label blue">Blue bin</span> in inventory.
            </li>
            <li>Scan the setup guide barcode on the bottom of the first page.</li>
            <li>Place it in the shipping box.</li>
          </ul>
        </Col>
        <Col oneoftwo>
          <img src={attESimInfo} className="step-4-graphic" alt="" />
        </Col>
      </Row>
    </WorkflowStep>
  );
};

CollateralFirstNetEsim.defaultProps = {};

CollateralFirstNetEsim.propTypes = {
  packCollateral: PropTypes.func.isRequired
};

export default memo(CollateralFirstNetEsim);
