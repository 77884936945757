import React, { memo } from 'react';
import Section from '../../Section/Section';

const SKU = () => (
  <div>
    <Section title="Scanner not working?">
      <label>Enter the ESN (dec) manually using the keyboard and press enter.</label>
    </Section>
    <Section title='I keep getting the error "Wrong device".'>
      <label>
        Check that the SKU (3rd barcode on the white device box) has the correct letter (P, S, or N)
        in front of it. You may have the correct device, but not device type.
      </label>
    </Section>
    <Section title="Out of return envelopes?">
      <label>
        There may be a cancelled order in the Kitted area with the device you are looking for. Click
        “Out of stock” on the screen to check for a cancelled order or report an out of stock SKU.
      </label>
    </Section>
  </div>
);

export default memo(SKU);
