import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Button from '../../Common/Button/Button';
import './DashAlert.scss';

const DashAlert = ({ buttonValue, message, onClick, showAlert }) => {
  const classes = classNames({ 'dash-alert': showAlert }, { 'dash-alert-hide': !showAlert });
  return (
    <div className={classes}>
      <div className="dash-alert-icon" />
      <div className="dash-alert-message">{message}</div>
      <Button type="dash-alert" onClick={onClick} text={buttonValue} />
    </div>
  );
};

DashAlert.propTypes = {
  buttonValue: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired
};

export default memo(DashAlert);
