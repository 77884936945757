import React, { Component } from 'react';
import DashCountdown from './DashCountdown';
import DashTimerLabel from './DashTimerLabel';
import clock from './icon-alarm-clock.svg';
import config from '../../../config';
import './DashTimer.scss';

class DashTimer extends Component {
  state = {
    shiftTiming: {},
    changeInShift: false
  };

  componentDidMount() {
    this.resetShifts();
  }

  resetShifts() {
    const { shiftTiming } = config.App;
    const sysdate = new Date();
    const newShifts = {};
    shiftTiming.forEach(timeslot => {
      newShifts[timeslot] = false;
      const ts = timeslot.split(':');
      const shiftTime = new Date();
      shiftTime.setHours(ts[0], ts[1], 0, 0, 0);
      if (shiftTime < sysdate) {
        newShifts[timeslot] = true;
      }
    });
    this.setState({
      shiftTiming: newShifts
    });
  }

  render() {
    let previousSlot = '00:00',
      currentSlot = '00:01',
      itr = 0,
      lastSelection = '',
      allSlotsCompleted = true;
    for (var time in this.state.shiftTiming) {
      if (!this.state.shiftTiming[time]) {
        if (itr !== 0) {
          previousSlot = lastSelection;
        }
        currentSlot = time;
        allSlotsCompleted = false;
        break;
      }
      lastSelection = time;
      itr++;
    }
    if (allSlotsCompleted) {
      previousSlot = lastSelection;
      currentSlot = '00:00';
    }
    return (
      <div className="dash-timer">
        <img className="dash-timer__icon" src={clock} alt="timer icon" />
        <DashCountdown
          previousSlot={previousSlot}
          currentSlot={currentSlot}
          slotsCompleted={allSlotsCompleted}
          resetShifts={this.resetShifts.bind(this)}
        />
        <DashTimerLabel
          previousSlot={previousSlot}
          currentSlot={currentSlot}
          slotsCompleted={allSlotsCompleted}
        />
      </div>
    );
  }
}

export default DashTimer;
