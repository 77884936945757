import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import './Alert.scss';

const Alert = ({ children, message, onClick, onClickText, type }) => (
  <div className={`alert alert--${type}`}>
    <div className="alert__icon" />
    <p className="alert__text">{message}</p>
    {onClick ? (
      <button onClick={onClick} className="alert__btnlbl">
        {onClickText}
      </button>
    ) : null}
    {children}
  </div>
);

Alert.defaultProps = {
  onClickText: ''
};

Alert.propTypes = {
  children: PropTypes.object,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClickText: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default memo(Alert);
