import React, { memo } from 'react';
import { isEmpty } from '../../../utilities/genericUtility';
import { WorkflowNames } from '../../../utilities/enums';
import AffixAndScan from '../Steps/AffixAndScan/AffixAndScan';
import AffixReshipLabel from '../Steps/AffixReshipLabel/AffixReshipLabel';
import Collateral from '../Steps/Collateral/Collateral';
import CompleteReturn from '../Steps/CompleteReturn/CompleteReturn';
import Done from '../Steps/Done/Done';
import Envelope from '../Steps/Envelope/Envelope';
import FirstNetSim from '../Steps/FirstNetSim/FirstNetSim';
import HomePlusGuide from '../Steps/HomePlusGuide/HomePlusGuide';
import PrintLabel from '../Steps/PrintLabel/PrintLabel';
import ReturnSKU from '../Steps/SKU/ReturnSKU';
import RmaLabel from '../Steps/RmaLabel/RmaLabel';
import ScanZip from '../Steps/AffixReshipLabel/ScanZip';
import SKU from '../Steps/SKU/SKU';
import VPHGuide from '../Steps/VPHGuide/VPHGuide';
import RightToCancel from '../Steps/RightToCancel/RightToCancel';
import VPHTOSBooklet from '../Steps/VPHBooklet/VPHB';
import AffixAndScanNDES from '../Steps/AffixAndScanNDES';
import NDESScansku from '../Steps/NDESScansku';
import PrintEsnLabel from '../Steps/PrintEsnLabel';
import Scanesnlabel from '../Steps/ScanEsnLabel';

const StepRouter = props => {
  const mainWorkFlow = props => {
    const workflowStep = !isEmpty(props.workflowOrder[props.step - 1])
      ? props.workflowOrder[props.step - 1]
      : 'done';
    switch (workflowStep) {
      case WorkflowNames.affixandscan:
        if (props.selectedClient) {
          return props.selectedClient.externalClientId !== '314' ?
            // For all other clients
            <AffixAndScan {...props} /> :
            // For NDES client
            <AffixAndScanNDES {...props} />;
        }

        return <></>;
      case WorkflowNames.affixandscanreshiplabel:
        return <AffixReshipLabel {...props} />;
      case WorkflowNames.scanzip:
        return <ScanZip {...props} />;
      case WorkflowNames.scanfirstnetsim:
        return <FirstNetSim {...props} />;
      case WorkflowNames.scanhomeplusguide:
        return <HomePlusGuide {...props} />;
      case WorkflowNames.scanreturnenvelope:
        return <Envelope {...props} />;
      case WorkflowNames.packcollateral:
      case WorkflowNames.packcollateraldual:
      case WorkflowNames.packcollateralfirstnet:
      case WorkflowNames.packcollateralinbox:
      case WorkflowNames.packcollateralesim:
      case WorkflowNames.packcollateralfirstnetesim:
        return <Collateral {...props} type={workflowStep} />;
      case WorkflowNames.scanskuesn:
        if (props.selectedClient) {
          return props.selectedClient.externalClientId !== '314' ?
            // For all other clients
            <SKU {...props} /> :
            // For NDES client
            <NDESScansku {...props} />;
        }

        return <></>;
      case WorkflowNames.scanvphguide:
        return <VPHGuide {...props} />;
      case WorkflowNames.scanrighttocancel:
        return <RightToCancel {...props} />;
      case WorkflowNames.scanvphtosbooklet:
        return <VPHTOSBooklet {...props} />;
      case WorkflowNames.done:
        return <Done {...props} />;
      case WorkflowNames.scanrma:
        return <RmaLabel {...props} />;
      case WorkflowNames.printesnlabel:
        return <PrintEsnLabel {...props} />;
      case WorkflowNames.scanesn:
        if (!props.kitInfo.workflow.serviceKitTasks.includes("printesnlabel") && props.selectedClient){
            return props.selectedClient.externalClientId !== '314' ?
              // For all other clients
              <SKU {...props} /> :
              // For NDES client
              <NDESScansku {...props} />;
        }
        return <Scanesnlabel {...props} />;    
      case WorkflowNames.printpackinglabel:
      default:
        return <PrintLabel {...props} />;
    }
  };

  const returnWorkFlow = props => {
    switch (props.step) {
      case 1:
        return <ReturnSKU {...props} />;
      case 2:
        return <CompleteReturn {...props} />;
      default:
        return <></>;
    }
  };

  if (!props.returnWorkflow) return mainWorkFlow(props);
  else return returnWorkFlow(props);
};

export default memo(StepRouter);
