import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const Row = ({ children, gutter }) => (
  <div className={classNames('pp-row', { 'pp-row--w-gutters': gutter })}>{children}</div>
);
Row.defaultProps = {
  gutter: false
};

Row.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  gutter: PropTypes.bool
};

export default memo(Row);
