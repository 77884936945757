import React, { Component } from 'react';
import { isEmpty } from '../../../utilities/genericUtility';
import Button from '../../Common/Button/Button';
import './DashSearch.scss';

class DashSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: ''
    };
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  searchSubmit = e => {
    e && e.preventDefault();
    this.props.onSearch(this.state.searchString);
  };

  render() {
    return (
      <form onSubmit={this.searchSubmit} style={{ width: '100%' }}>
        <div className="dash-search">
          <label htmlFor="search" className="dash-search__label">
            Search orders
          </label>
          <input
            type="text"
            id="search"
            className="dash-search__input"
            placeholder="Search orders"
            onChange={e => {
              e.preventDefault();
              this.setState({
                searchString: e.target.value
              });
              if (isEmpty(e.target.value)) this.props.onSearch('');
            }}
            value={this.state.searchString}
          />
          <button className="dash-search__button" type="submit" />
          {!isEmpty(this.state.searchString) && (
            <>
              <Button
                text="Search"
                className="search-addon__button search-border-active"
                onClick={this.searchSubmit}
              ></Button>
              <Button
                text="Clear"
                type="ghost"
                className="search-addon__button"
                onClick={() => {
                  this.setState({
                    searchString: ''
                  });
                  this.props.onSearch('');
                }}
              ></Button>
            </>
          )}
        </div>
      </form>
    );
  }
}

export default DashSearch;
