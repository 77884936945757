import React, { memo } from 'react';
import Section from '../../Section/Section';

const AffixReshipLabel = () => (
  <div>
    <Section title="Scanner not working?">
      <label>Enter the barcode number manually using the keyboard and press enter.</label>
    </Section>
    <Section title="Damaged return label or envelope?">
      <label>Rip in half, throw away, and choose another.</label>
    </Section>
    <Section title="Out of return envelopes?">
      <label>
        You can continue without packing a return envelope. Click the “Out of return envelopes?”
        link on the screen to trigger an email to your Asurion FSL Management to send a return only shipment to
        the customer.
      </label>
    </Section>
  </div>
);

export default memo(AffixReshipLabel);
