import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getClientName } from '../../../utilities/clientUtility';
import FilterTab from '../../Common/FilterTab/FilterTab';

class DashTableFilter extends Component {
  state = {
    selectedId: 'NONE'
  };

  onSelect = clientId => {
    this.setState({
      selectedId: clientId
    });
    this.props.clientFilterSelect(clientId);
  };

  render() {
    const clients = this.props.clientCounts.map(({ externalClientId }) => (
      <FilterTab
        key={externalClientId}
        name={getClientName(externalClientId)}
        onClick={() => this.onSelect(externalClientId)}
        selected={this.state.selectedId === externalClientId}
      />
    ));
    return (
      <div className="dash-table-filter__container">
        <FilterTab
          key={'NONE'}
          name="All"
          onClick={() => this.onSelect('NONE')}
          selected={this.state.selectedId === 'NONE'}
        />
        {clients}
      </div>
    );
  }
}
const mapStateToProps = ({ order }) => ({
  clientCounts: order.clientCounts
});

export default connect(mapStateToProps, null)(DashTableFilter);
