import { getCurrentTimeStamp, downloadFileFromUrl } from '../utilities/genericUtility';
import { Get, uriName } from '../utilities/apiUtitlity';
import { hasError, showErrorMessage, showInputErrorMessage } from './errorActions';
import { hideLoading, showLoading } from '../actions/appInteractionActions';

export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const UPDATE_EXPERT_LIST_ARRAY = 'EXPERT_LIST_ARRAY';
export const UPDATE_HOT_NEWS = 'SET_HOT_NEWS';
export const UPDATE_HOT_NEWS_COUNT = 'UPDATE_HOT_NEWS_COUNT';

export const getContactInfo = () => async (dispatch, getState) => {
  dispatch(showLoading());
  try {
    const res = await Get(uriName, '/v1/servicekit/contacts');
    if (!hasError(res)) {
      dispatch(updateContactInfo(res.contactInfo));
    } else dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  } catch (error) {
    hasError(error) && dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  }
  dispatch(hideLoading());
};

export const updateContactInfo = contactInfo => async dispatch =>
  dispatch(saveContactInfo(contactInfo));

const saveContactInfo = payload => ({
  type: UPDATE_CONTACT_INFO,
  payload
});

export const getExpertListInfo = () => async dispatch => {
  dispatch(showLoading());
  try {
    const res = await Get(uriName, '/v1/expertlist');
    if (hasError(res)) dispatch(showInputErrorMessage('Unable to load the data for ExpertList'));
    dispatch(expertListActionCreator(res));
  } catch (error) {
    hasError(error) && dispatch(showInputErrorMessage('Unable to load the data for ExpertList'));
  }
  dispatch(hideLoading());
};

export const getHotNews = () => async dispatch => {
  dispatch(showLoading());
  try {
    const res = await Get(uriName, '/v1/servicekit/news');
    if (!hasError(res)) {
      const countData = calculateHotNewsCount(res);
      const newRes = { ...res, ...countData };
      dispatch(saveHotNews(newRes));
    } else dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  } catch (error) {
    hasError(error) && dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  }
  dispatch(hideLoading());
};

export const clearHotNewsCountData = () => async dispatch =>
  dispatch(
    updateHotNewsCount({
      count: 0,
      latestHotNews: []
    })
  );

export const getS3File = attachment => async dispatch => {
  dispatch(showLoading());
  try {
    const formData = await Get(uriName, `/v1/servicekit/news?s3FileName=${attachment.s3FileName}`);
    const res = await fetch(formData.url);
    if (res.status === 200) downloadFileFromUrl(res, attachment.fileName);
    else dispatch(showInputErrorMessage('Something went wrong. Please try again.'));
  } catch (error) {
    dispatch(showInputErrorMessage('Something went wrong. Please try again.'));
  }
  dispatch(hideLoading());
};

export const calculateHotNewsCount = req => {
  let resultArray = [...req.newsInfo];
  let result = {};
  const timeStamp = getCurrentTimeStamp();
  if (timeStamp) {
    let filterArray = resultArray.filter(ele => Date.parse(ele.createdTimeStamp) + '' > timeStamp);
    result = {
      latestHotNews: [...filterArray],
      count: filterArray.length
    };
  } else {
    result = {
      count: resultArray.length,
      latestHotNews: []
    };
  }
  return result;
};

const expertListActionCreator = res => ({
  type: UPDATE_EXPERT_LIST_ARRAY,
  payload: truncateExpertLis(res)
});

const truncateExpertLis = res => {
  let rotationArray = [...res.expertListResponse.providers];
  let resultData = [];
  rotationArray.forEach((ele, index) => {
    resultData = [...resultData.concat(ele.experts)];
  });
  return resultData;
};

export const saveHotNews = payload => ({
  type: UPDATE_HOT_NEWS,
  payload
});

const updateHotNewsCount = payload => ({
  type: UPDATE_HOT_NEWS_COUNT,
  payload
});
