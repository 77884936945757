import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { isEmpty } from '../../../utilities/genericUtility';
import Col from '../../Common/Column';
import OrderId from '../../Common/OrderId/OrderId';
import Row from '../../Common/Row';
import SearchSpan from '../../Common/SearchSpan/SearchSpan';
import iconClose from '../../Modal/icon-close.svg';

const CustomerInfo = ({ label, searchString, value }) => {
  const checkData = data =>
    data ? (
      <SearchSpan searchString={searchString} value={data.toString()} />
    ) : (
      <span className="greyText">Unavailable</span>
    );
  return (
    <Row>
      <Col oneofthree>
        <span className="dash-modal_customer-info_dd">{label}</span>
      </Col>
      <Col twoofthree>
        <span className="dash-modal_customer-info_dt">{checkData(value)}</span>
      </Col>
    </Row>
  );
};

class DashModal extends PureComponent {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  }

  closeModalWindow = e => {
    e.preventDefault();
    this.props.closeModal();
  };

  render() {
    const { currentOrder, searchString, showContent } = this.props;
    const {
      appointmentWindow,
      completedDateTime,
      createdDateTime,
      customerFirstName,
      customerLastName,
      envelopeTrackingNumber,
      kitReferenceNumber,
      kitSequenceNumber,
      partSerialNumber,
      serviceKitSKU
    } = currentOrder;
    let customerFullName = `${customerFirstName ? customerFirstName : ''} ${
      customerLastName ? customerLastName : ''
    }`.trim();
    customerFullName =
      customerFullName === '' ? <span className="greyText">Unavailable</span> : customerFullName;
    const modalClasses = classNames(
      { 'dash-modal': showContent },
      { 'hide-content': !showContent }
    );
    return (
      <div className={modalClasses}>
        <div className="dash-modal_container" ref={this.setWrapperRef}>
          <div className="dash-modal_header">
            <div>
              <OrderId
                inverted
                kitSequenceNumber={kitSequenceNumber}
                selected={
                  !isEmpty(searchString) &&
                  searchString.length <= 2 &&
                  kitSequenceNumber.endsWith(searchString)
                }
              />
            </div>
            <div>
              <span className="dash-modal_customer-name">
                <SearchSpan value={customerFullName} searchString={searchString}></SearchSpan>
              </span>
              <div>
                <strong>Created on:</strong> {createdDateTime}{' '}
                {completedDateTime && (
                  <>
                    &nbsp;
                    <strong>Completed on</strong> {completedDateTime}
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="dash-modal_close-btn">
                <button
                  onClick={this.closeModalWindow.bind(this)}
                  className="workflow-header-btn workflow-close-btn"
                >
                  <img src={iconClose} className="workflow-header-btn__icon" alt="close" />
                </button>
              </div>
            </div>
          </div>
          <div className="dash-modal_customer-info">
            <CustomerInfo
              label="Appointment window"
              value={appointmentWindow}
              searchString={searchString}
            />
            <CustomerInfo
              label="Service job number"
              value={kitReferenceNumber}
              searchString={searchString}
            />
            <CustomerInfo label="SKU" value={serviceKitSKU} searchString={searchString} />
            <CustomerInfo label="ESN" value={partSerialNumber} searchString={searchString} />
            <CustomerInfo
              label="USPS tracking number"
              value={envelopeTrackingNumber}
              searchString={searchString}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DashModal;
