import { WS_CONNECTED, WS_DISCONNECTED, WS_ERROR } from '../actions/wsConnectActions';

const websocketInitialState = {
  connected: false,
  errored: false
};

export default (state = websocketInitialState, action) => {
  switch (action.type) {
    case WS_CONNECTED:
      return { ...state, connected: true };
    case WS_DISCONNECTED:
      return { ...state, connected: false };
    case WS_ERROR:
      return { ...state, errored: true };
    default:
      return state;
  }
};
