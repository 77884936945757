import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { outOfStock, scanReturnEnvelope } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
// import returnEnvelope from './return-envelope.svg';
import rmaEnvelope from './rma-envelope.svg';
import './Envelope.scss';
import returnEnvelopeNoZip from './return-envelope-nozip.svg';
import NoEnvelope from './NoEnvelope';
import Button from '../../../Common/Button/Button';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import { WorkflowTaskMapping } from '../../../../utilities/enums';

class Envelope extends Component {
  state = {
    noEnvelope: false
  };
  
  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noEnvelope) {
      this.setState({
        noEnvelope: false
      });
    }
  }

  goToNextStep() {
    if (isEmpty(this.props.updatedTrackingNumber)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  noEnvelopeFlow() {
    this.setState({
      noEnvelope: true
    });
  }

  skipStep = async () => {
    await this.props.outOfStock(WorkflowTaskMapping.ENVELOPE);
    if (this.props.stepSkipped === WorkflowTaskMapping.ENVELOPE) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.ENVELOPE];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  render() {
    const attClient = this.props.selectedClient.externalClientId === '313';
    if (this.state.noEnvelope)
      return <NoEnvelope {...this.props} skipStepName={WorkflowTaskMapping.ENVELOPE} />;
    const { isSkipAllowed } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.ENVELOPE] || false;

    return (
      <WorkflowStep
        {...this.props}
        title="Scan &amp; pack a return envelope."
        footer={
          <>
            <PrimaryInput
              nextStep={this.goToNextStep.bind(this)}
              onSubmit={this.props.scanReturnEnvelope}
              placeholder="Scan tracking number"
              rules="required|usps"
              title="Envelope tracking barcode"
              type="tracking number"
            />
            {!attClient && isSkipAllowed && (
              <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
            )}
          </>
        }
        after={
          !attClient && !isSkipAllowed && (
            <SecondaryButton
              onClick={this.noEnvelopeFlow.bind(this)}
              text="Out of return labels or envelopes?"
            />
          )
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              {!attClient ? (
                <li>Scan the BOTTOM barcode, labeled “USPS Tracking #”.</li>
              ) : (
                <li>Scan the BOTTOM barcode on the return envelope.</li>
              )}
              <li>Place it at the bottom of the shipping box, barcode side down.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={attClient ? rmaEnvelope : returnEnvelopeNoZip} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

Envelope.defaultProps = {
  updatedTrackingNumber: ''
};

Envelope.propTypes = {
  scanReturnEnvelope: PropTypes.func.isRequired,
  updatedTrackingNumber: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, stepSkipped } = serviceKit;
  return {
    updatedTrackingNumber: serviceKit.uspsTrackingNumber,
    stepSkipped,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      scanReturnEnvelope
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Envelope);
