import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { outOfStock, scanFlier } from '../../../../actions/orderActions';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import NoRightToCancelBrochure from './NoRightToCancelBrochure';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import rightToCancelImg from './right-to-cancel-img.svg';

class RightToCancel extends Component {
  state = {
    noRightToCancelBrochure: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noRightToCancelBrochure) {
      this.setState({
        noRightToCancelBrochure: false
      });
    }
  }

  goToNextStep() {
    if (isEmpty(this.props.flierGuide)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  noRightToCancelBrochureFlow() {
    this.setState({
      noRightToCancelBrochure: true
    });
  }

  skipStep = async () => {
    await this.props.outOfStock(WorkflowTaskMapping.SCANRIGHTTOCANCEL);
    if (this.props.stepSkipped === WorkflowTaskMapping.SCANRIGHTTOCANCEL) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.SCANRIGHTTOCANCEL];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  render() {
    if (this.state.noRightToCancelBrochure)
      return <NoRightToCancelBrochure {...this.props} skipStepName={WorkflowTaskMapping.SCANRIGHTTOCANCEL} />;
    const { isSkipAllowed } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.SCANRIGHTTOCANCEL];

    return (
      <WorkflowStep
        {...this.props}
        title="Scan &amp; pack a Verizon Right to Cancel Card"
        footer={
          <>
            <PrimaryInput
              nextStep={this.goToNextStep.bind(this)}
              onSubmit={itemNumber =>
                this.props.scanFlier(itemNumber, WorkflowTaskMapping.SCANRIGHTTOCANCEL)
              }
              placeholder="Scan barcode"
              rules="required|rtc"
              title="Right to Cancel Card"
              type="barcode"
            />
            {isSkipAllowed && (
              <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
            )}
          </>
        }
        after={
          !isSkipAllowed && (
            <SecondaryButton
              onClick={this.noRightToCancelBrochureFlow.bind(this)}
              text="Out of Right to Cancel Cards?"
            />
          )
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Get a Verizon Right to Cancel Card</li>
              <li>Scan the barcode on the bottom right</li>
              <li>Place it in the shipping bag</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={rightToCancelImg} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

RightToCancel.defaultProps = {
  flierGuide: ''
};

RightToCancel.propTypes = {
  scanFlier: PropTypes.func.isRequired,
  outOfStock: PropTypes.func.isRequired,
  flierGuide: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, stepSkipped, flierGuide } = serviceKit;
  return {
    stepSkipped,
    flierGuide,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      scanFlier
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RightToCancel);
