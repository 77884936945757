import React, { Component } from 'react';
import './PrimaryButton.scss';
import FocusLock from 'react-focus-lock';

class PrimaryButton extends Component {
  state = {
    clickedOnce: false
  };

  resetClicked = () => {
    setTimeout(() => {
      this.setState({
        clickedOnce: false
      });
    }, 2000);
  };

  handleClick = () => {
    this.props.onClick();
    this.setState(
      {
        clickedOnce: true
      },
      this.resetClicked()
    );
  };

  render() {
    return (
      <FocusLock persistentFocus>
        <button
          onClick={this.handleClick.bind(this)}
          className="workflow-primary-btn"
          autoFocus
          disabled={this.state.clickedOnce}
        >
          {this.props.icon ? (
            <img src={this.props.icon} className="workflow-primary-btn__icon" alt="" />
          ) : null}
          {this.props.text}
        </button>
      </FocusLock>
    );
  }
}

export default PrimaryButton;
