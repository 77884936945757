import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getContactInfo } from '../../../../actions/configurationActions';
import Section from '../../../Common/Section/Section';
import ContactInfo from './ContactInfo';
import FilterTab from '../../../Common/FilterTab/FilterTab';
import { ContactKeys, HelpTabs } from '../../../../utilities/enums';
import RoomContent from './HelpRoom/RoomContent';
import './HelpSection.scss';

class HelpSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHelpTab: HelpTabs.CONTACTS
    };
  }

  componentDidMount() {
    this.props.getContactInfo();
  }

  onSelect = filter => {
    this.setState({
      selectedHelpTab: filter
    });
  };

  render() {
    let weekendContacts = [];
    let helpRooms = [];
    this.props.contactInfo.forEach(item => {
      if (item.key === ContactKeys.WEEKENDCONTACT) weekendContacts = item.info.contacts;
      else if (item.key === ContactKeys.HELPROOM) helpRooms = item.info.contacts;
    });

    const contacts = weekendContacts.map((contact, index) => (
      <ContactInfo
        name={contact.name}
        email={contact.email}
        description={contact.location}
        number={contact.phone}
        key={index}
      />
    ));

    return (
      <Section name="Need help?">
        <div className="needhelp-filter-subsection">
          <div className="needhelp-filter-conainer">
            <FilterTab
              name={HelpTabs.CONTACTS}
              selected={this.state.selectedHelpTab === HelpTabs.CONTACTS}
              onClick={() => this.onSelect(HelpTabs.CONTACTS)}
            />
            <FilterTab
              name={HelpTabs.ROOMS}
              selected={this.state.selectedHelpTab === HelpTabs.ROOMS}
              onClick={() => this.onSelect(HelpTabs.ROOMS)}
            />
          </div>
        </div>
        {this.state.selectedHelpTab === HelpTabs.CONTACTS
          ? weekendContacts &&
            weekendContacts.length > 0 && (
              <table className="contact-info-edit__table">
                <thead>
                  <tr>
                    <th>Manager name</th>
                    <th>Location</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>{contacts}</tbody>
              </table>
            )
          : this.state.selectedHelpTab === HelpTabs.ROOMS && <RoomContent helpRooms={helpRooms} />}
      </Section>
    );
  }
}
const mapStateToProps = ({ configuration }) => ({
  contactInfo: configuration.contactInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getContactInfo
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HelpSection);
