import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty } from '../../../utilities/genericUtility';
import { WorkflowNames } from '../../../utilities/enums';
import AffixAndScan from './Steps/AffixAndScan/AffixAndScan';
import AffixReshipLabel from './Steps/AffixReshipLabel/AffixReshipLabel';
import Collateral from './Steps/Collateral/Collateral';
import Envelope from './Steps/Envelope/Envelope';
import FirstNetSim from './Steps/FirstNetSim/FirstNetSim';
import HomePlusGuide from './Steps/HomePlusGuide/HomePlusGuide';
import PrintLabel from './Steps/PrintLabel/PrintLabel';
import SKU from './Steps/SKU/SKU';
import VPHGuide from './Steps/VPHGuide/VPHGuide';
import VPHTOSBooklet from './Steps/VPHBooklet/VPHBooklet';
import './HelpDrawer.scss';
import backButton from './icon-back.svg';

const HelpDrawer = ({ closeHelpDrawer, returnWorkflow, step, workflowOrder }) => {
  const mainWorkflowContent = () => {
    const workflowStep = !isEmpty(workflowOrder[step - 1]) ? workflowOrder[step - 1] : 'done';
    switch (workflowStep) {
      case WorkflowNames.affixandscan:
        return <AffixAndScan />;
      case WorkflowNames.affixandscanreshiplabel:
      case WorkflowNames.scanrma:
      case WorkflowNames.scanzip:
        return <AffixReshipLabel />;
      case WorkflowNames.scanfirstnetsim:
        return <FirstNetSim />;
      case WorkflowNames.scanhomeplusguide:
        return <HomePlusGuide />;
      case WorkflowNames.scanreturnenvelope:
        return <Envelope />;
      case WorkflowNames.scanvphguide:
        return <VPHGuide />;
      case WorkflowNames.scanvphtosbooklet:
        return <VPHTOSBooklet />;
      case WorkflowNames.packcollateral:
        return <Collateral />;
      case WorkflowNames.scanskuesn:
        return <SKU />;
      case WorkflowNames.printpackinglabel:
        return <PrintLabel />;
      default:
        closeHelpDrawer();
        return <PrintLabel />;
    }
  };

  const returnWorkFlowContent = () => {
    switch (step) {
      case 1:
        return <SKU />;
      default:
        closeHelpDrawer();
        return <SKU />;
    }
  };

  const drawerContent = !returnWorkflow ? mainWorkflowContent() : returnWorkFlowContent();

  return (
    <div className="HelpDrawer">
      <header className="HelpDrawer__header">
        <button onClick={closeHelpDrawer} className="HelpDrawer__back-button">
          <img className="HelpDrawer__back-button__icon" src={backButton} alt="" />
        </button>
        <h1 className="HelpDrawer__title">Help</h1>
      </header>
      <div className="HelpDrawer__main">{drawerContent}</div>
      <div className="HelpDrawer__footer">
        <p>
          <span className="HelpDrawer__footer__title">Still need help?</span> <br />
          Contact Asurion FSL Management.
        </p>
      </div>
    </div>
  );
};

HelpDrawer.propTypes = {
  closeHelpDrawer: PropTypes.func,
  returnWorkflow: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired
};

export default memo(HelpDrawer);
