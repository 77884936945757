import React, { Component } from 'react';
import './Section.scss';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = e => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: true });
    }

    e.preventDefault();
  };

  render() {
    return (
      <section
        className={'HelpDrawer__section ' + (this.state.isOpen ? 'HelpDrawer__section--open' : '')}
      >
        <h2 onClick={this.handleClick} className="HelpDrawer__section__title">
          {this.props.title}
        </h2>
        <div className="HelpDrawer__section__answer">{this.props.children}</div>
      </section>
    );
  }
}

export default Section;
