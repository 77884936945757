import React, { Component } from 'react';

const minCalc = 60 * 1000;
const hrCalc = 60 * minCalc;

let timer = null,
  decrementor = null;

class DashCountdown extends Component {
  state = {
    countdownDate: new Date(),
    countdownTime: '00:00',
    showContent: true,
    updated: false
  };

  componentDidUpdate() {
    const { previousSlot, slotsCompleted } = this.props;
    const previousts = previousSlot.split(':');
    let showContent = true;
    if (previousts[0] !== '00') {
      const currentDate = new Date();
      const previousDate = new Date();
      previousDate.setHours(previousts[0], previousts[1], 0, 0, 0);
      const secDiff = currentDate - previousDate;
      const minDiff = secDiff / minCalc;
      showContent = minDiff > 30;
      if (!showContent)
        timer = setTimeout(() => {
          this.setState({
            showContent: true
          });
        }, 30 * minCalc - secDiff);
    }
    if (!this.state.updated && !slotsCompleted) {
      const dt = this.state.countdownDate;
      const ts = this.props.currentSlot.split(':');
      const currentDate = new Date();
      dt.setHours(ts[0], ts[1], 0, 0, 0);
      const diff = dt - currentDate;
      const ms = ((diff / 1000) % 60) * 1000;
      const mins = ((diff / minCalc) % 60) + 1;
      const hrs = diff / minCalc > 60 ? diff / hrCalc : 0;
      dt.setHours(hrs, mins, 0, 0, 0);
      this.setState({
        countdownDate: dt,
        countdownTime: `${this.getStats(dt.getHours())}:${this.getStats(dt.getMinutes())}`,
        showContent: showContent,
        updated: true
      });
      timer = setTimeout(() => {
        this.countdownStart();
      }, ms);
    }
    if (slotsCompleted && this.state.showContent) {
      this.setState({
        showContent: false,
        updated: false
      });
      const { resetShifts } = this.props;
      const sysdate = new Date();
      const nextDate = new Date();
      nextDate.setDate(sysdate.getDate() + 1);
      nextDate.setHours(0, 0, 0, 0, 0);
      const diff = nextDate - sysdate;
      timer = setTimeout(() => {
        resetShifts();
        this.setState({
          showContent: true
        });
      }, diff);
    }
  }

  countdownStart() {
    const currDate = this.state.countdownDate;
    if (currDate.getHours() !== 0 || currDate.getMinutes() !== 0) {
      const timedifference = currDate - minCalc;
      const dt = new Date(timedifference);
      this.setState({
        countdownDate: dt,
        countdownTime: `${this.getStats(dt.getHours())}:${this.getStats(dt.getMinutes())}`
      });
      if (dt.getHours() === 0 && dt.getMinutes() === 0) this.countdownStart();
      else this.decreaseTime();
    } else if (currDate.getHours() === 0 && currDate.getMinutes() === 0) {
      this.setState({
        showContent: false
      });
      this.props.resetShifts();
    }
  }

  decreaseTime = () => {
    decrementor = setTimeout(() => {
      this.countdownStart();
    }, minCalc);
  };

  getStats = time => (time > 9 ? time : `0${time}`);

  render() {
    return this.state.showContent ? (
      <>
        <span className="dash-timer__text">Time until next pickup:</span>
        <span className="dash-timer__time">{this.state.countdownTime}</span>
      </>
    ) : (
      <></>
    );
  }
}

export default DashCountdown;
