import React from 'react';
import { useDispatch } from 'react-redux';
import { Table, Input, Select } from 'antd';
import styled from 'styled-components'
import Not from './technicianAssets/not.png'
import Need from './technicianAssets/need.png'
import Tick from './technicianAssets/tick.png'
import Search from './technicianAssets/Union.png'

import { useState } from 'react';
import { useEffect } from 'react';
import { ScanCheckoutModal, SubmitCheckoutModal } from './technicianAssets/ScanCheckout';
import { Banner } from './technicianAssets/banner';
import { CheckOutlined } from '@ant-design/icons';

import { technicianCheckout } from '../../../actions/orderActions';

export const TechnicianTable = ({ technicianBucket }) => {
    const [tableData, setTableData] = useState()
    const [indexArray, setindexArray] = useState()
    const [modal, setModal] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [data, setData] = useState(technicianBucket)
    const [technicianforCheckout, setTechnicianforCheckout] = useState("")
    const [esnScanned, setEsnScanned] = useState('')
    const [sorter, setSorter] = useState({})
    const [tempVar, setTempVar] = useState(0)
    const [searchValueTechName, setSearchValueTechName] = useState('')

    const dispatch = useDispatch();

    useEffect(() => {
        setData(technicianBucket)
        searchByTechnicianName()
        setTempVar(tempVar => tempVar + 1)
    }, [technicianBucket])

    useEffect(() => {
        sortTable(sorter)
    }, [data, tempVar])

    useEffect(() => {
        let temp = data.map(a => a.jobs)
        temp = [].concat.apply([], temp);
        setTableData(temp)
        setindexArray(getUniquerowsindex())
    }, [])

    useEffect(() => {
        let temp = data.map(a => a.jobs)
        temp = [].concat.apply([], temp);
        setTableData(temp)
        setindexArray(getUniquerowsindex())
    }, [data])

    useEffect(() => {
        setindexArray(getUniquerowsindex())
    }, [tableData, data])


    useEffect(() => {
        searchByTechnicianName()
    }, [searchValueTechName])

    const getUniquerowsindex = () => {
        let a = 0;
        return data.map(value => {
            let k = a
            a = a + value.jobs.length
            return k
        })
    }

    const getRowspan = (techname) => data.find(a => a.techName === techname)?.jobs?.length

    const getStatusTag = (techname) => {
        const techdetails = data.find(a => a.techName === techname)
        if (techdetails) {
            switch (techdetails.status) {
                case 'NOT_COMPLETE':
                    return <NotComplete />
                case 'NEEDS_CHECKOUT':
                    return <NeedsCheckout />
                case 'COMPLETE':
                    return <Complete />
                default:
                    return <></>
            }
        } else {
            return <></>
        }
    }

    const getPriority = (techdetails) => {
        switch (techdetails.status) {
            case 'COMPLETE':
                return 0;
            case 'NEEDS_CHECKOUT':
                return 1;
            case 'NOT_COMPLETE':
                return 2;
            default:
                return 100;
        }
    }

    const getPackageScan = (techname) => {
        const techdetails = data.find(a => a.techName === techname)
        return techdetails?.count + ' of ' + techdetails?.jobs?.length
    }

    const handleChange = (a, b, sorter) => {
        setSorter(sorter)
        sortTable(sorter);
    }

    const sortTable = (sorter) => {
        let tempdata
        if (sorter.field === 'techName') {
            if (sorter.order === 'ascend') {
                tempdata = data.sort((a, b) => a.techName.toLowerCase().substr(0, a.techName.indexOf(' ')).localeCompare(b.techName.toLowerCase().substr(0, b.techName.indexOf(' '))))
            } else {
                tempdata = data.sort((a, b) => b.techName.toLowerCase().substr(0, b.techName.indexOf(' ')).localeCompare(a.techName.toLowerCase().substr(0, a.techName.indexOf(' '))))
            }
            let temp = tempdata.map(a => a.jobs)
            temp = [].concat.apply([], temp);
            setTableData(temp)
            setData(tempdata)
        } else if (sorter.field === 'package') {

            if (sorter.order === 'ascend') {
                tempdata = data.sort((a, b) => a?.count - b?.count)
            } else {
                tempdata = data.sort((a, b) => b?.count - a?.count)
            }
            let temp = tempdata.map(a => a.jobs)
            temp = [].concat.apply([], temp);
            setTableData(temp)
            setData(tempdata)
        } else if (sorter.field === 'status') {
            if (sorter.order === 'ascend') {
                tempdata = data.sort((a, b) => getPriority(a) - getPriority(b))
            } else {
                tempdata = data.sort((a, b) => getPriority(b) - getPriority(a))
            }
            let temp = tempdata.map(a => a.jobs)
            temp = [].concat.apply([], temp);
            setTableData(temp)
            setData(tempdata)
        }
    }

    const searchByTechnicianName = () => {
        let tempdata = technicianBucket.filter(a => a.techName.toLowerCase().indexOf(searchValueTechName.toLowerCase()) > -1)
        let temp = tempdata.map(a => a.jobs)
        temp = [].concat.apply([], temp);
        setTableData(temp)
        setData(tempdata)
    }

    const onClickScanCheckout = () => {
        setSuccess(false)
        setError(false)
        setErrorMessage('')
        openModal('ScanCheckout')
    }

    const openModal = newModal => {
        setModal(newModal)
    };

    const closeModal = () => {
        setModal(false)
    };


    const technicianCheckoutCheck = (scanesn) => {
        const techname = tableData?.find(a => a.kitReferenceNumber === scanesn)?.techName
        const techData = technicianBucket?.find(a => a.techName === techname)

        const scanItem = tableData?.find(a => a.kitReferenceNumber === scanesn)

        if (!techData) {
            setError(true)
            setErrorMessage('Package not found.')
        }
        else if (techData?.status !== 'NEEDS_CHECKOUT') {
            setError(true)
            setErrorMessage('Technician not ready to check out.')
        }
        else if (techData?.status == 'NEEDS_CHECKOUT') {
            if (scanItem.checkedOutBy === null) {
                setTechnicianforCheckout(techname)
                setEsnScanned(scanesn)
                setModal('SubmitCheckout')
            } else {
                setError(true)
                setErrorMessage('This Part is already checked out')
            }
        }
    }

    const completeCheckout = () => {
        dispatch(technicianCheckout(esnScanned, technicianforCheckout, () => {
            setSuccess(true)
        }))
    }

    const columns = [
        {
            title: <Title>Technician</Title>,
            dataIndex: 'techName',
            //render: (text) => <Text>{text.toLowerCase().indexOf('null') > -1 ? 'Unassigned' : text}</Text>,
            render: (text) => <Text>{text}</Text>,
            sorter: true,
            onCell: (value, index) => {
                if (indexArray.indexOf(index) > -1) {
                    return { rowSpan: getRowspan(value['techName']) };
                }
                else return { rowSpan: 0 };
            }
        },
        {
            title: <Title>Status</Title>,
            dataIndex: 'status',
            render: (text, source) => <>{getStatusTag(source.techName)}</>,//<Text>{text}</Text>,
            sorter: true,
            onCell: (value, index) => {
                if (indexArray.indexOf(index) > -1) {
                    return { rowSpan: getRowspan(value['techName']) };
                }
                else return { rowSpan: 0 };
            }

        },
        {
            title: <Title>Packages Scanned</Title>,
            dataIndex: 'package',
            render: (text, source) => <Text>{getPackageScan(source?.techName)}</Text>,
            sorter: true,
            onCell: (value, index) => {
                if (indexArray.indexOf(index) > -1) {
                    return { rowSpan: getRowspan(value['techName']) };
                }
                else return { rowSpan: 0 };
            }

        },
        {
            title: <Title>Order Number</Title>,
            dataIndex: 'kitSequenceNumber',
            render: (text, source) => <OrderNumber status={source?.status}><div>{text.toString().slice(2)}</div>{source?.status === 'PICKED_UP' && <img src={Tick} />}</OrderNumber>

        },
        {
            title: <Title>Reference Number</Title>,
            dataIndex: 'kitReferenceNumber',
            render: (text) => <Text>{text}</Text>,


        },
        {
            title: <Title>Client</Title>,
            dataIndex: 'customerFirstName',
            render: (text, source) => <Text>{source.customerFirstName + " " + source.customerLastName}</Text>

        },
        {
            title: <Title>Appt. Window</Title>,
            dataIndex: 'appointmentWindow',
            render: (text) => <Text>{text}</Text>,

        },
        {
            title: <Title>SKU</Title>,
            render: (text) => <Text>{text}</Text>,
            dataIndex: 'serviceKitSKU',

        },

    ]
    return <>

        <SearchWrap>
            <Input.Group compact style={{ display: 'flex', minWidth: '750px' }}>
                <BorderBox>
                    <div><img src={Search} /></div>
                    <SelectWrap>
                        <SearchTitle>Search By</SearchTitle>
                        <SelectDiv>
                            <Select size="large" placeholder="Technician Name" value={'techName'} bordered={false} suffixIcon={false}
                                options={[
                                    {
                                        value: 'techName',
                                        label: 'Technician Name',
                                    },
                                ]} >
                            </Select>
                        </SelectDiv></SelectWrap></BorderBox>
                <Input style={{ width: '70%', border: '1px solid #A5AAAF' }} size="large" value={searchValueTechName} onChange={(e) => setSearchValueTechName(e.target.value)} placeholder='Search technician name' />
            </Input.Group>
            <ScanButton onClick={onClickScanCheckout}>
                <ScanButtonTitle>Scan</ScanButtonTitle>
            </ScanButton>
        </SearchWrap>
        <Banner alertBannerType='error' isOpen={error} onCloseClick={() => setError(false)} message={errorMessage} />
        <Banner alertBannerType='success' isOpen={success} onCloseClick={() => setSuccess(false)} message={`Success! ${technicianforCheckout}'s pickup is complete.`} iconSrc={<CheckOutlined />} />
        <Table
            columns={columns}
            dataSource={tableData?.map(data => ({ key: `id-${data.kitSequenceNumber}`, ...data }))}
            style={{
                width: '100%'
            }}
            pagination={false}
            onChange={handleChange}
            bordered
        />
        <ScanCheckoutModal isOpen={modal === 'ScanCheckout'} closeModal={closeModal} technicianCheckoutCheck={technicianCheckoutCheck} />
        <SubmitCheckoutModal isOpen={modal === 'SubmitCheckout'} closeModal={closeModal} technician={technicianforCheckout} submit={completeCheckout} />
    </>
}

const BorderBox = styled.div`
    border: 1px solid #A5AAAF;
    border-radius: 8px 0px 0px 8px;
    height: 58px;
    color: #000000;
    width: 30%;
    display: flex !important;
    img{
        margin: 18px 14px;
    }
`;

const SearchTitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #6E767D;
`;




const SelectWrap = styled.div`
    margin: 10px;
`;

const SearchWrap = styled.div`
    &&& {
        align-self: baseline;
        margin: 18px;
        display: flex;
    }
`;

const SelectDiv = styled.div`
    margin: -12px;
`;

const OrderNumber = styled.div`
    width: 100%;
    margin: auto 12px;
    text-align: left;
    display: flex;
    img{
        width: 16px;
        height: 16px;
        margin: 10px;
    }
    div{
        font-weight: 400;
        font-size: 19.5px;
        line-height: 130%;
        color: #FFFFFF;
        margin: 8px 2px;
        text-align: center;
        height: 33px;
        padding-left: 5px;
        padding-top: 3px;
        padding-right: 5px;
        border-radius: 4px;
        background: ${props => props.status === 'PICKED_UP' ? '#008662' : '#07366E'};
    }
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 19.5px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #333F48;
    text-align: center;
    width: 100%;

`;

const Text = styled.div`
    font-weight: 400;
    font-size: 19.5px;
    line-height: 130%;
    color: #000000;
    text-align: center;
`;

const NotComplete = () => { return <NotCompleteStyle col='#FFFFFF' background='#DB1B1B'><span><img src={Not} />Not complete</span></NotCompleteStyle> }

const NeedsCheckout = () => { return <NotCompleteStyle col='#000000' background='#F9D51C'><span><img src={Need} />Needs Checkout</span></NotCompleteStyle> }

const Complete = () => { return <NotCompleteStyle col='#000000' background='#37E7A7'><span><img src={Tick} />Complete</span></NotCompleteStyle> }

const NotCompleteStyle = styled.div`
span{
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.col};
    margin: 0 5px;
}
img{
    margin: 0 3px;
}
    min-width: 125px;
    width: max-content;
    height: 25px;
    background: ${props => props.background};
    border: 1px solid ${props => props.background};
    border-radius: 2px;
`;

const ScanButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4a90e2;
    margin-left: 15px;
    padding: 5px;
    width: 100px;
    border: 1px solid #4a90e2;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
`;

const ScanButtonTitle = styled.span`
    color: white;
    font-size: 20px;
    font-weight: 800;
`;

function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

export const dummydata = {
    "serviceKitOrderStatusType": "OrdersByTechnician",
    "serviceKitDisplayRows": [
        {
            "techName": "Ketaki",
            "status": "NEEDS_CHECKOUT",
            "count": 2,
            "jobs": [
                {
                    "serviceKitNumber": "SK-031323-XX897C95",
                    "kitSequenceNumber": "131",
                    "serviceKitWindowType": "Afternoon",
                    "kitReferenceNumber": "SNWR3X5B514B-1",
                    "status": "PICKED_UP",
                    "serviceKitSKU": "S314-0470-IPHXSM256GRY",
                    "externalClientId": "313",
                    "appointmentWindow": "1pm – 3pm",
                    "customerFirstName": "Jessica",
                    "customerLastName": "ATT",
                    "expertCompanyId": "1002631708",
                    "expertLocationId": "1002631709",
                    "techFirstName": null,
                    "techLastName": null,
                    "checkedOutBy": 'darshan',
                    "checkOutScan": true,
                    "techName": "Ketaki"
                },
                {
                    "serviceKitNumber": "SK-031323-XX897C96",
                    "kitSequenceNumber": "132",
                    "serviceKitWindowType": "Afternoon",
                    "kitReferenceNumber": "SNWR3X5B514E-1",
                    "status": "PICKED_UP",
                    "serviceKitSKU": "S314-0470-IPHXSM256GRY",
                    "externalClientId": "313",
                    "appointmentWindow": "1pm – 3pm",
                    "customerFirstName": "Jessica",
                    "customerLastName": "ATT",
                    "expertCompanyId": "1002631708",
                    "expertLocationId": "1002631709",
                    "techFirstName": null,
                    "techLastName": null,
                    "checkedOutBy": 'darshan',
                    "checkOutScan": true,
                    "techName": "Ketaki"
                },
                {
                    "serviceKitNumber": "SK-031323-XX897C97",
                    "kitSequenceNumber": "133",
                    "serviceKitWindowType": "Afternoon",
                    "kitReferenceNumber": "SNWR3X5B514F-1",
                    "status": "PICKED_UP",
                    "serviceKitSKU": "S314-0470-IPHXSM256GRY",
                    "externalClientId": "313",
                    "appointmentWindow": "1pm – 3pm",
                    "customerFirstName": "Jessica",
                    "customerLastName": "ATT",
                    "expertCompanyId": "1002631708",
                    "expertLocationId": "1002631709",
                    "techFirstName": null,
                    "techLastName": null,
                    "checkedOutBy": null,
                    "checkOutScan": null,
                    "techName": "Ketaki"
                },
                {
                    "serviceKitNumber": "SK-031323-XX897C99",
                    "kitSequenceNumber": "135",
                    "serviceKitWindowType": "Afternoon",
                    "kitReferenceNumber": "SNWR3X5B514G-1",
                    "status": "PICKED_UP",
                    "serviceKitSKU": "S314-0470-IPHXSM256KSJ",
                    "externalClientId": "313",
                    "appointmentWindow": "1pm – 3pm",
                    "customerFirstName": "Jessica",
                    "customerLastName": "ATT",
                    "expertCompanyId": "1002631708",
                    "expertLocationId": "1002631709",
                    "techFirstName": null,
                    "techLastName": null,
                    "checkedOutBy": null,
                    "checkOutScan": null,
                    "techName": "Ketaki"
                }
            ]
        },
        {
            "techName": "Pritam Nikam",
            "status": "NOT_COMPLETE",
            "count": 0,
            "jobs": [
                {
                    "serviceKitNumber": "SK-031323-XX897C98",
                    "kitSequenceNumber": "134",
                    "serviceKitWindowType": "Afternoon",
                    "kitReferenceNumber": "SNWR3X5B5150-1",
                    "status": "KITTED",
                    "serviceKitSKU": "S314-0470-IPHXSM256GRY",
                    "externalClientId": "313",
                    "appointmentWindow": "1pm – 3pm",
                    "customerFirstName": "Jessica",
                    "customerLastName": "ATT",
                    "expertCompanyId": "1002631708",
                    "expertLocationId": "1002631709",
                    "techFirstName": "Pritam",
                    "techLastName": "Nikam",
                    "checkedOutBy": null,
                    "checkOutScan": null,
                    "techName": "Pritam Nikam"
                }
            ]
        }
    ]
}