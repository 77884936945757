import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { affixAndScanLabel } from '../../../../actions/orderActions';
import { AlertType } from '../../../../utilities/enums';
import Col from '../../../Common/Column';
import PackingLabel from '../../../PackingLabel/PackingLabel';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
// import SVG from "react-inlinesvg";
import affixGraphic from './graphic-affix-label.svg';
import './AffixAndScan.scss';

class AffixAndScan extends PureComponent {
  componentDidMount() {
    this.props.openAlert(AlertType.RetryPrinting);
  }

  componentWillUnmount() {
    this.props.closeAlert();
  }

  goToNextStep = async () => {
    await this.props.affixAndScanLabel();
    if (this.props.scanAndAffixComplete) this.props.nextStep(this.props.step);
  };

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Affix packing label &amp; scan it."
        subtitle="Label should go on the short side of the box."
        footer={
          <PrimaryInput
            nextStep={this.goToNextStep.bind(this)}
            placeholder="Scan barcode"
            rules={'required|in:' + this.props.kitInfo.kitReferenceNumber}
            title="Packing label barcode"
            type="barcode"
          />
        }
      >
        <Row gutter>
          <Col oneofone>
            <div className="step-2-graphic">
              <img src={affixGraphic} className="step-2-graphic__box" alt="Affix graphic" />
              <div className="step-2-graphic__label">
                <PackingLabel class="printdialog" {...this.props} />
              </div>
            </div>
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

AffixAndScan.defaultProps = {
  kitInfo: {},
  scanAndAffixComplete: false
};

AffixAndScan.propTypes = {
  kitInfo: PropTypes.object,
  scanAndAffixComplete: PropTypes.bool
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, scanAndAffixComplete } = serviceKit;
  return {
    kitInfo: processingServiceKit,
    scanAndAffixComplete
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      affixAndScanLabel
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AffixAndScan);
