import React, { memo } from 'react';
import Section from '../../Section/Section';

const FirstNetSim = () => (
  <div>
    <Section title="Scanner not working?">
      <label>Enter the barcode manually using the keyboard and press enter.</label>
    </Section>
    <Section title="FirstNet SIM out of stock?">
      <label>Click the "Out of FirstNet SIM" link and follow the instructions presented.</label>
    </Section>
  </div>
);

export default memo(FirstNetSim);
