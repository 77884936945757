import { Input, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Barcode from './barcode.png';
import CrossButton from './Button.png';


export const ScanCheckoutModal = ({ isOpen, closeModal, technicianCheckoutCheck }) => {
    const [esn, setEsn] = useState()
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0)
    }, [isOpen]);


    return <CustomModal
        open={isOpen}
        closeIcon={<img src={CrossButton} alt="barcode" />}
        footer={null}
        onCancel={() => {
            setEsn('')
            closeModal()
        }}
        style={{ borderRadius: '16px' }}
        centered
    >
        <div onKeyPress={(e) => {
            if (e.key === 'Enter') {
                setEsn('')
                technicianCheckoutCheck(esn)
            }
        }}>
            <Title>Scan a Bag</Title>
            <InputWrapper><Input value={esn} ref={inputRef} size="large" onChange={(e) => setEsn(e.target.value)} prefix={<img src={Barcode} alt="barcode" />} /></InputWrapper>
        </div>
    </CustomModal>
}


export const SubmitCheckoutModal = ({ isOpen, closeModal, technician, submit }) => {
    return <CustomModal
        open={isOpen}
        closeIcon={<img src={CrossButton} alt="barcode" />}
        footer={null}
        onCancel={() => closeModal()}
        style={{ borderRadius: '16px' }}
        centered
    >
        <Title>Are you checking out for {technician} ?</Title>
        <ButtonWrapper>
            <RoundButton type='button' onClick={() => closeModal()}>
                No, cancel
            </RoundButton>
            <RoundButtonFilled type='button' onClick={() => {
                closeModal()
                submit()
            }}>
                Yes
            </RoundButtonFilled>
        </ButtonWrapper>
    </CustomModal>
}

const Title = styled.div`
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #000000;
`;

const InputWrapper = styled.div`
    margin-top: 60px;
`;

const RoundButtonFilled = styled.button`
    background: #8223D2;
    color: #FFFFFF;
    border-radius: 9999px;
    padding: 12px 20px;
    width: 217px;
    height: 48px;
`;


const RoundButton = styled.button`
    background: #FFFFFF;
    border-radius: 9999px;
    padding: 12px 20px;
    width: 217px;
    color: #8223D2;
    height: 48px;
    border: 2px solid #8223D2;
`;

const ButtonWrapper = styled.div`
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
`;

const CustomModal = styled(Modal)`
    .ant-modal-close{
        margin-right:10px ;
    }
`;