import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { affixAndESNScanLabel, saveEsnNumber } from '../../../../actions/orderActions';
import { AlertType } from '../../../../utilities/enums';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import backScanBag from './back_scan_bag.png'
import styled from 'styled-components';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import { isEmpty } from '../../../../utilities/genericUtility';
class ScanEsnLabel extends PureComponent {
    componentDidMount() {
        this.props.openAlert(AlertType.RetryPrinting);
    }

    componentWillUnmount() {
        this.props.closeAlert();
    }

    goToNextStep = async () => {
        if (isEmpty(this.props.esnNumber)) setTimeout(() => this.goToNextStep(), 1000);
        else this.props.nextStep(this.props.step);
    };

    render() {
        return (
            <WorkflowStep
                {...this.props}
                title="Affix label, scan barcode, seal the bag."
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={(val) => { 
                            this.props.dispatch(saveEsnNumber(''));
                            this.props.affixAndESNScanLabel(val)
                         }}
                        placeholder="Scan ESN"
                        title="ESN label barcode"
                        rules="required|sku"
                        type="SKU"
                        showModal={this.props.showError && this.props.errorMessage.actual}
                        openModal={() => this.props.openModal('WrongSKUScan')}
                    />
                }
                after={
                    <SecondaryButton
                        onClick={this.props.prevStep}
                        text="Reprint ESN"
                    />
                }
            >
                <Row gutter>
                    <Col oneofone>
                        <ul>
                            <li>Affix ESN label to the <SubtitleBold>back of the bag</SubtitleBold></li>
                            <li>Scan ESN barcode</li>
                            <li>Seal the bag</li>
                        </ul>
                    </Col>
                </Row>
                <ImageWrapper>
                    <BagStyle src={backScanBag} alt='back_scan_bag' />
                </ImageWrapper>
            </WorkflowStep>
        );
    }
}

ScanEsnLabel.defaultProps = {
    kitInfo: {},
    scanESNAndAffixComplete: false
};

ScanEsnLabel.propTypes = {
    kitInfo: PropTypes.object,
    scanESNAndAffixComplete: PropTypes.bool
};

const mapStateToProps = ({ serviceKit }) => {
    const { processingServiceKit, scanESNAndAffixComplete, esnNumber } = serviceKit;
    return {
        kitInfo: processingServiceKit,
        scanESNAndAffixComplete,
        esnNumber
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            affixAndESNScanLabel
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ScanEsnLabel);


const ImageWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: -90px;
`;

const BagStyle = styled.img`
    width: 70%;
`;

const SubtitleBold = styled.span`
    font-weight: 900;
`;
