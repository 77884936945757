import React, { Component } from 'react';
import NoSKU from './NoSKU';
import SKUStep from './SKUStep';

class SKU extends Component {
  state = {
    noSkuFlow: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noSkuFlow) {
      this.setState({
        noSkuFlow: false
      });
    }
  }

  noSkuInStock = () => {
    this.setState({
      noSkuFlow: true
    });
  };

  render() {
    if (this.state.noSkuFlow) return <NoSKU {...this.props} />;
    else return <SKUStep {...this.props} secondaryClick={this.noSkuInStock} />;
  }
}

export default SKU;
