import { FlowTypes, WorkflowTaskMapping } from '../utilities/enums';
import { Get, Post, uriName, userInfo } from '../utilities/apiUtitlity';
import { isEmpty } from '../utilities/genericUtility';
import { hasError, showErrorMessage, showInputErrorMessage } from './errorActions';
import { hideLoading, showLoading,showSSOT } from '../actions/appInteractionActions';
import {
  updateRefreshRate,
  updateRefreshRateOnError,
  updateSessionId
} from '../utilities/sessionUtility';
import { wsDisconnect, wsDisconnected } from './wsConnectActions';
import { calculateHotNewsCount, saveHotNews } from './configurationActions';
import { extractJobNumber, generateCorrelationID } from '../utilities/genericUtility';

export const CLEAR_CLIENT_SELECTION = 'CLEAR_CLIENT_SELECTION';
export const CLEAR_PROCESSING_KIT = 'CLEAR_PROCESSING_KIT';
export const COLLATERAL_STEP_COMPLETE = 'COLLATERAL_STEP_COMPLETE';
export const GET_ORDERS = 'GET_ORDERS';
export const PRINT_STEP_COMPLETE = 'PRINT_STEP_COMPLETE';
export const RESUME_KIT_SUCCESSFUL = 'RESUME_KIT_SUCCESSFUL';
export const RESUME_ORDER = 'RESUME_ORDER';
export const SAVE_ESN_NUMBER = 'SAVE_ESN_NUMBER';
export const SAVE_FIRSTNET_SIM_NUMBER = 'SAVE_FIRSTNET_SIM_NUMBER';
export const SAVE_RMA_NUMBER = 'SAVE_RMA_NUMBER';
export const SAVE_SKIP_STEP = 'SAVE_SKIP_STEP';
export const SAVE_TRACKING_NUMBER = 'SAVE_TRACKING_NUMBER';
export const SAVE_FLIER_GUIDE = 'SAVE_FLIER_GUIDE';
export const SCAN_AFFIX_COMPLETE = 'SCAN_AFFIX_COMPLETE';
export const SELECT_CLIENT_FOR_WORKFLOW = 'SELECT_CLIENT_FOR_WORKFLOW';
export const START_NEXT_ORDER = 'START_NEXT_ORDER';
export const UPDATE_RESHIP_STATUS = 'UPDATE_RESHIP_STATUS';
export const UPDATE_TOTAL_COUNT = 'UPDATE_TOTAL_COUNT';
export const ORDER_CANCELED_FLAG = 'ORDER_CANCELED_FLAG';
export const SET_CURRENT_SKU_INFO = 'SET_CURRENT_SKU_INFO';

export const getOrders = refreshFlag => async (dispatch, getState) => {
  try {
    generateCorrelationID(userInfo.companyId, FlowTypes.DASHBOARD);
    if (userInfo.sessionId === '') updateSessionId();
    const res = await dashboardApiCall();
    
    if (hasError(res) && !refreshFlag) {
      if(res?.error?.code === 'LOCATION_SSOT_ENABLED'){
        dispatch(showSSOT())
      }else {
        updateRefreshRateOnError();
        dispatch(showErrorMessage('Unable to load the data for Dashboard'));
      }
      dispatch(hideLoading());
    } else {
      const orderToPack = res.serviceKitOrders.filter(
        order => order.serviceKitOrderStatusType === 'OrdersToPack'
      )[0];
      updateRefreshRate(res.refreshTime);
      const dataToUpdate = {
        ...res,
        clientCounts: orderToPack.serviceKitOrderClientCounts.sort((a, b) =>
          parseInt(a.externalClientId - b.externalClientId)
        ),
        orderToPack: orderToPack.serviceKitDisplayRows.length
      };
      if (res.websocketON !== undefined && !res.websocketON && getState().websocket.connected) {
        await dispatch(wsDisconnect(true));
        await dispatch(wsDisconnected());
      }
      dispatch(updateOrdersOnLoad(dataToUpdate));
      dispatch(hideLoading());
    }
  } catch (error) {
    updateRefreshRateOnError();
    hasError(error) &&
      !refreshFlag &&
      dispatch(showErrorMessage('Unable to load the data for Dashboard'));
  }
};

export const dashboardApiCall = async () => await Get(uriName, '/v1/servicekit/dashboard');

export const updateOrderData = res => {
  if (res.error || res.message) {
    return showErrorMessage('Unable to load the data for Dashboard');
  }
  if (res.serviceKitOrders) {
    const orderToPack = res.serviceKitOrders.filter(
      order => order.serviceKitOrderStatusType === 'OrdersToPack'
    )[0];
    const dataToUpdate = {
      ...res,
      clientCounts: orderToPack.serviceKitOrderClientCounts.sort((a, b) =>
        parseInt(a.externalClientId - b.externalClientId)
      ),
      orderToPack: orderToPack.serviceKitDisplayRows.length
    };
    return updateOrdersOnLoad(dataToUpdate);
  } else if (res.newsInfo) {
    const countData = calculateHotNewsCount(res);
    const newRes = { ...res, ...countData };
    return saveHotNews(newRes);
  }
};

export const selectClientForWorkflow = clientId => (dispatch, getState) => {
  console.log(getState().order.clientCounts, 'getState().order.clientCounts');
  const payload = getState().order.clientCounts.filter(
    client => client.externalClientId === clientId
  )[0];
  dispatch({ type: SELECT_CLIENT_FOR_WORKFLOW, payload });
};

export const clearClientSelection = () => dispatch => {
  dispatch({ type: CLEAR_CLIENT_SELECTION });
};

export const searchOrder = serviceKitNumber => async dispatch => {
  try {
    dispatch(showLoading());
    const res = await Get(uriName, `/v1/servicekit/search?serviceKitNumber=${serviceKitNumber}`);
    dispatch(hideLoading());
    if (hasError(res)) return null;
    return res;
  } catch (error) {
    dispatch(hideLoading());
    return null;
  }
};

export const nextServiceKitOrder = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    updateSessionId();

    generateCorrelationID(
      extractJobNumber(
        getState().order.serviceKitDisplayRows[0].serviceKitDisplayRows,
        getState().order.selectedClient.externalClientId
      ),
      FlowTypes.ORDER_TO_KIT
    );


    const clientId = getState().order.selectedClient.externalClientId;

    const res = await Get(
      uriName,
      `/v1/servicekit/nextorder?externalClientId=${clientId
      }`
    );

    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(clearProcessingKit());
      dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
    } else {
      // skip scanvphguide and scanrighttocancel
      const nextOrderBT = res.businessType;
      if (clientId === '31') {
        if (nextOrderBT === 'Business' || nextOrderBT === 'Government') {
          if (res.workflow.serviceKitTasks.length > 0) {
            const idxOfScanVPHGuide = res.workflow.serviceKitTasks.indexOf('scanvphguide');
            if (idxOfScanVPHGuide !== -1) {
              res.workflow.serviceKitTasks.splice(idxOfScanVPHGuide, 1);
            }

            const idxOfRightToCancel = res.workflow.serviceKitTasks.indexOf('scanrighttocancel');
            if (idxOfRightToCancel !== -1) {
              res.workflow.serviceKitTasks.splice(idxOfRightToCancel, 1);
            }

            if (res.workflow.outOfStock.packcollateral.nextKitTask === 'scanvphguide') {
              res.workflow.outOfStock.packcollateral.nextKitTask = 'scanskuesn';
            }
          }
        }
      }

      res.zone = '';
      const orderToKit = getState().order.serviceKitDisplayRows.filter(
        order => order.serviceKitOrderStatusType === 'OrdersToPack'
      )[0];
      orderToKit.serviceKitDisplayRows.forEach(serviceKit => {
        if (serviceKit.serviceKitNumber === res.serviceKitNumber && serviceKit.zone)
          res.zone = serviceKit.zone;
      });
      dispatch(startNewServiceKitOrder(res));
    }
  } catch (error) {
    dispatch(clearProcessingKit());
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  }
};

export const retrieveNDESSkuInfo = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    updateSessionId();

    const clientId = getState().order.selectedClient.externalClientId;

    // retrieve bin number
    if (clientId === '314') {
      const sku = getState().serviceKit.processingServiceKit.sku;
      const ordersToKitBucket = getState().order.serviceKitDisplayRows.filter(rows => rows.serviceKitOrderStatusType === 'OrdersToPack')[0].serviceKitDisplayRows;
      const onlyNDESOrders = ordersToKitBucket.filter(rows => rows.externalClientId === '314');
      let expertLocation = '';

      if (Array.isArray(onlyNDESOrders) && onlyNDESOrders.length > 0) {
        expertLocation = onlyNDESOrders[0].expertLocationId;
      } else {
        const unfinishedBucket = getState().order.serviceKitDisplayRows
          .filter(rows => rows.serviceKitOrderStatusType === 'Unfinished')[0]
          .serviceKitDisplayRows;
        const onlyNDESOrdersUnfinished = unfinishedBucket.filter(rows => rows.externalClientId === '314');

        if (Array.isArray(onlyNDESOrdersUnfinished) && onlyNDESOrdersUnfinished.length > 0) {
          expertLocation = onlyNDESOrdersUnfinished[0].expertLocationId;
        }
      }

      const esnLocationRes = await esnLocationApiCall(sku, expertLocation);
      if (hasError(esnLocationRes)) {
        dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
      } else {
        if (esnLocationRes.Data.length > 0) {
          dispatch(setCurrentNDESSkuInfo(esnLocationRes.Data[0]));
        }
        dispatch(hideLoading());
      }
    }
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  }
};

export const esnLocationApiCall = async (sku, location) => await Get(uriName, `/v1/servicekit/esnlocation?skunumber=${sku}&aelocationid=${location}`);

export const printPackagingLabel = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { kitReferenceNumber, sku } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitSKU: sku,
      serviceKitTasks: [
        {
          taskNumber: 'PRINT',
          taskDescription: 'Print packaging label'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/${getState().serviceKit.processingServiceKit.serviceKitNumber
      }/printpackinglabel`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(printStepCompleted());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to send the data to the server.'));
  }
};

export const affixAndScanLabel = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'AFFIX',
          taskDescription: 'Affix and scan'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/affixandscan`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(scanAndAffixComplete());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const affixReshipLabel = zipCode => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'AFFIXANDSCANRESHIPLABEL',
          taskDescription: 'Affix and scan reship label',
          taskName: 'affixandscanreshiplabel',
          taskDetail: zipCode
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/${serviceKitNumber}/affixandscanreshiplabel`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to upload the Envelope Data'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(updateReshipStatus());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
  }
};

export const scanZip = zipCode => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'SCANZIP',
          taskDescription: 'Scan zip bar code',
          taskName: 'scanzip',
          taskDetail: zipCode
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanzip`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to upload the Envelope Data'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(updateReshipStatus());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
  }
};

export const scanRmaNumber = smartLabel => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: 'RMA',
          itemName: 'RMA Number',
          itemType: 'RMA',
          rmaNumber: smartLabel.substr(smartLabel.indexOf('AS'), 9)
        },
        {
          itemNumber: 'SMARTLABEL',
          itemName: 'Smart label number',
          itemType: 'SMARTLABEL',
          smartLabelNumber: smartLabel
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCANRMA',
          taskDescription: 'Scan rma'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanrma`, postData);
    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(showErrorMessage('Unable to upload the Envelope Data'));
      dispatch(saveRmaScanNumber());
    } else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(saveRmaScanNumber(smartLabel));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
    dispatch(saveRmaScanNumber(''));
  }
};

export const scanReturnEnvelope = uspsTrackingNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: 'ENVELOPE',
          itemName: 'Return Envelope',
          itemType: 'ENVELOPE',
          envelopeTrackingNumber: uspsTrackingNumber
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'ENVELOPE',
          taskDescription: 'Scan return envelope'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/${serviceKitNumber}/scanreturnenvelope`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(showInputErrorMessage(res.error.message));
      dispatch(saveEnvelopTrackingNumber(''));
    } else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(saveEnvelopTrackingNumber(uspsTrackingNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
    dispatch(saveEnvelopTrackingNumber(''));
  }
};

export const packCollateral = type => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    let collateralType = 'COLLATERAL',
      description = 'Pack collateral',
      itemName = 'Collateral',
      uri = 'packcollateral';
    switch (type) {
      case 'DUAL':
        collateralType = 'COLLATERALDUAL';
        description = 'Pack dual sim collateral';
        itemName = 'Collateral Dual';
        uri = 'packcollateraldual';
        break;
      case 'FIRSTNET':
        collateralType = 'COLLATERALFIRSTNET';
        description = 'Pack firstnet collateral';
        itemName = 'Collateral Firstnet';
        uri = 'packcollateralfirstnet';
        break;
      case 'INBOX':
        collateralType = 'COLLATERALINBOX';
        description = 'Pack inbox collateral';
        itemName = 'Collateral Inbox';
        uri = 'packcollateralinbox';
        break;
      case 'ESIM':
        collateralType = 'COLLATERALESIM';
        description = 'Pack esim collateral';
        itemName = 'Collateral Esim';
        uri = 'packcollateralesim';
        break;
      case 'FIRSTNETESIM':
        collateralType = 'COLLATERALFIRSTNETESIM';
        description = 'Pack firstnetesim collateral';
        itemName = 'Collateral FirstNetEsim';
        uri = 'packcollateralfirstnetesim';
        break;
      default:
        break;
    }
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: collateralType,
          itemName: itemName,
          itemType: 'COLLATERAL'
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: collateralType,
          taskDescription: description
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/${uri}`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(collateralStepComplete());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const scanFirstNetSim = simNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: 'FIRSTNETSIM',
          itemName: 'Firstnet SIM',
          itemType: 'SIM',
          simNumber
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCANFIRSTNETSIM',
          taskDescription: 'Scan firstnet sim'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scansim`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(saveFirstNetSimNumber(simNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveFirstNetSimNumber(''));
  }
};

export const scanFlier = (itemNumber, flierType) => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    let postData = {};
    switch (flierType) {
      case WorkflowTaskMapping.SCANVPHGUIDE:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'VPH Guide',
              itemType: 'VPHGUIDE'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANVPHGUIDE',
              taskDescription: 'Scan vhdp guide barcode'
            }
          ]
        };
        break;
      case WorkflowTaskMapping.SCANHOMEPLUSGUIDE:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'Homeplus Guide',
              itemType: 'HOMEPLUSGUIDE'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANHOMEPLUSGUIDE',
              taskDescription: 'Scan Hometech brochure barcode'
            }
          ]
        };
        break;
      case WorkflowTaskMapping.SCANVPHTOSBOOKLET:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'VPH TOS Booklet',
              itemType: 'VPHTOSBOOKLET'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANVPHTOSBOOKLET',
              taskDescription: 'Scan vhdp tos booklet barcode'
            }
          ]
        };
        break;
      case WorkflowTaskMapping.SCANRIGHTTOCANCEL:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'Right To Cancel',
              itemType: 'RIGHTTOCANCEL'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANRIGHTTOCANCEL',
              taskDescription: 'scan right to cancel barcode'
            }
          ],
        };
        break;
      default:
        postData = {};
        break;
    }
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanflier`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(saveFlierGuide(itemNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveFlierGuide(''));
  }
};

export const loadStockIndicator = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, sku } = getState().serviceKit.processingServiceKit;
    const res = await Get(
      uriName,
      `/v1/servicekit/skucount?skuReferenceNumber=${sku}&serviceKitNumber=${serviceKitNumber}`
    );
    dispatch(hideLoading());
    if (hasError(res)) return 0;
    return res.quantityAvailableInStock;
  } catch (error) {
    dispatch(hideLoading());
    return 0;
  }
};

export const scanSkuEsn = esnNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber, sku } =
      getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: sku,
          itemName: sku,
          itemType: 'PHONE',
          skuEsnNumber: esnNumber
        }
      ],
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/validateskuesn`, postData);
    dispatch(hideLoading());
    if (hasError(res.errResponse)) {
      if (res.errResponse.error.code === 'PART_MISMATCH') {
        // Eventually when scanskuesn api is updated to return
        // error message structure in a consistent manner
        // we can remove this condition for checking matching
        // code 'PART_MISMATCH'
        const customError = {
          error: {
            code: 'PART_MISMATCH',
            message: 'No matching part for scanned sku. Please scan another.'
          }
        };
        hasError(customError);
        dispatch(showInputErrorMessage(customError));
      } else {
        dispatch(showInputErrorMessage(res));
      }
    }
    else if (res.hasOwnProperty('code') && res.code === '4004') dispatch(orderCanceledFlag());
    else dispatch(saveEsnNumber(esnNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveEsnNumber(''));
  }
};

export const resumeOrder = (rowInfo, callback) => async (dispatch, getState) => {
  dispatch(showLoading());
  await selectClientForWorkflow(rowInfo.externalClientId)(dispatch, getState);
  await dispatch({ type: RESUME_ORDER, rowInfo });
  try {
    const clientId = getState().order.selectedClient.externalClientId;
    const res = await Get(
      uriName,
      `/v1/servicekit/nextorder?serviceKitNumber=${rowInfo.serviceKitNumber}`
    );

    // skip scanvphguide and scanrighttocancel
    const nextOrderBT = res.businessType;
    if (clientId === '31') {
      if (nextOrderBT === 'Business' || nextOrderBT === 'Government') {
        if (res.workflow.serviceKitTasks.length > 0) {
          const idxOfScanVPHGuide = res.workflow.serviceKitTasks.indexOf('scanvphguide');
          if (idxOfScanVPHGuide !== -1) {
            res.workflow.serviceKitTasks.splice(idxOfScanVPHGuide, 1);
          }

          const idxOfRightToCancel = res.workflow.serviceKitTasks.indexOf('scanrighttocancel');
          if (idxOfRightToCancel !== -1) {
            res.workflow.serviceKitTasks.splice(idxOfRightToCancel, 1);
          }

          if (res.workflow.outOfStock.packcollateral.nextKitTask === 'scanvphguide') {
            res.workflow.outOfStock.packcollateral.nextKitTask = 'scanskuesn';
          }
        }
      }
    }

    if (hasError(res)) {
      dispatch(showErrorMessage('Unable to load the data for Resume'));
    } else {
      res.zone = '';
      const orderToKit = getState().order.serviceKitDisplayRows.filter(
        order => order.serviceKitOrderStatusType === 'Unfinished'
      )[0];
      orderToKit.serviceKitDisplayRows.forEach(serviceKit => {
        if (serviceKit.serviceKitNumber === res.serviceKitNumber && serviceKit.zone)
          res.zone = serviceKit.zone;
      });
      await dispatch(startNewServiceKitOrder(res));
      callback(res.serviceKitTasks[0].taskNumber);
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to load the data for Resume'));
  }
};

export const outOfStock = stepName => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      outofStockWorkflow: stepName
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/outofstock`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else dispatch(saveSkipStep(stepName));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveSkipStep(''));
  }
};

export const outOfStockSku = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber, sku } =
      getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      outofStockWorkflow: 'scanskuesn',
      partNumber: sku
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/outofstock`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const returnOrder = skuEsnNumber => async dispatch => {
  try {
    dispatch(showLoading());
    generateCorrelationID(skuEsnNumber, FlowTypes.RETURN_SHIPMENT);
    const res = await Post(uriName, '/v1/servicekit/return', { skuEsnNumber });
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showInputErrorMessage('Unable to load the data for Return'));
    else await dispatch(resumeKitSuccessfull(res.partStatus));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to load the data for Return'));
  }
};

export const technicianCheckout = (esn, techName, successCallback) => async (dispatch, getState) => {
  try {
    const {
      serviceKitDisplayRows: kits
    } = getState().order;

    const orders = !isEmpty(kits)
      ? kits.filter(order => order.serviceKitOrderStatusType === 'OrdersByTechnician')[0]
        ?.serviceKitDisplayRows
      : [];

    let techJobs = !isEmpty(orders)
      ? orders.filter(order => order.techName === techName)[0]
        ?.jobs
      : [];

    techJobs = techJobs.filter(a => a.checkedOutBy === null)
    const requestServiceKits = techJobs.map(job => (
      {
        serviceKitNumber: job.serviceKitNumber,
        checkedOutBy: userInfo.userName,
        checkoutScan: job.kitReferenceNumber === esn ? true : false
      }
    ));

    dispatch(showLoading());
    const res = await Post(uriName, '/v1/servicekit/update', {
      requestType: 'multikit',
      serviceKits: requestServiceKits
    });
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showInputErrorMessage('Unable to process data for checkout'));
    else successCallback();
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to process data for checkout'));
  }
};

export const affixAndESNScanLabel = esnNumberScan => async (dispatch, getState) => {
  try {

    let { esnNumber } = getState().serviceKit;
    esnNumber = esnNumber || esnNumberScan
    dispatch(showLoading());
    const {
      serviceKitNumber,
      kitReferenceNumber,
      sku
    } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: sku,
          itemName: sku,
          itemType: 'PHONE',
          skuEsnNumber: esnNumber
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCANESN',
          taskDescription: 'Affix & Scan ESN'
        },
        {
          taskNumber: 'PRINTESNLABEL',
          taskDescription: 'Print ESN'
        },
        {
          taskNumber: 'SCANSKUESN',
          taskDescription: 'Scan SKU ESN'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanskuesn`, postData);
    dispatch(hideLoading());
    if (hasError(res) || hasError(res.errResponse)) {
      if (res?.errResponse?.error?.code === 'PART_MISMATCH' || res?.error?.code === 'PART_MISMATCH') {
        // Eventually when scanskuesn api is updated to return
        // error message structure in a consistent manner
        // we can remove this condition for checking matching
        // code 'PART_MISMATCH'
        const customError = {
          error: {
            code: 'PART_MISMATCH',
            message: 'No matching part for scanned sku. Please scan another.'
          }
        };
        hasError(customError);
        dispatch(showInputErrorMessage(customError));
      } else if (res?.Error?.Code === 'PROCESSING_ERROR' || res?.error?.code === 'PROCESSING_ERROR') {
        hasError(res?.Error?.Message);
        dispatch(showInputErrorMessage(res?.Error?.Message?.message));
        dispatch(saveEsnNumber(''));
      }
      else {
        dispatch(showInputErrorMessage(res));
        dispatch(saveEsnNumber(''));
      }
    }
    else if (res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(saveEsnNumber(esnNumber));
  } catch (error) {
    dispatch(hideLoading());
    console.log("error", error)
    if (error?.Error?.Code === 'PROCESSING_ERROR' || error?.error?.code === 'PROCESSING_ERROR') {
      hasError(error?.Error?.Message);
      dispatch(showInputErrorMessage(error?.Error?.Message?.message));
      dispatch(saveEsnNumber(''));
    } else {
      hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
      dispatch(saveEsnNumber(''));
    }
  }
};

export const clearProcessingKit = () => dispatch =>
  dispatch({
    type: CLEAR_PROCESSING_KIT
  });

export const updateTotalCount = count => dispatch =>
  dispatch({
    type: UPDATE_TOTAL_COUNT,
    count
  });

const updateOrdersOnLoad = payload => ({
  type: GET_ORDERS,
  payload
});

const startNewServiceKitOrder = payload => ({
  type: START_NEXT_ORDER,
  payload
});

export const setCurrentNDESSkuInfo = payload => ({
  type: SET_CURRENT_SKU_INFO,
  payload
});

const collateralStepComplete = () => ({ type: COLLATERAL_STEP_COMPLETE });

const printStepCompleted = () => ({ type: PRINT_STEP_COMPLETE });

const scanAndAffixComplete = () => ({ type: SCAN_AFFIX_COMPLETE });

const updateReshipStatus = () => ({ type: UPDATE_RESHIP_STATUS });

const saveRmaScanNumber = payload => ({
  type: SAVE_RMA_NUMBER,
  payload
});

const saveEnvelopTrackingNumber = payload => ({
  type: SAVE_TRACKING_NUMBER,
  payload
});

const saveFirstNetSimNumber = payload => ({
  type: SAVE_FIRSTNET_SIM_NUMBER,
  payload
});

const saveFlierGuide = payload => ({
  type: SAVE_FLIER_GUIDE,
  payload
});

export const saveEsnNumber = payload => ({
  type: SAVE_ESN_NUMBER,
  payload
});

const saveSkipStep = payload => ({
  type: SAVE_SKIP_STEP,
  payload
});

const resumeKitSuccessfull = partStatus => ({
  type: RESUME_KIT_SUCCESSFUL,
  partStatus
});

const orderCanceledFlag = () => ({
  type: ORDER_CANCELED_FLAG
});
