import { getWSUrl, userInfo } from '../utilities/apiUtitlity';
import { updateSessionId } from '../utilities/sessionUtility';

export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_ERROR = 'WS_ERROR';
export const WS_NEW_MESSAGE = 'NEW_MESSAGE';

export const wsConnect = () => {
  updateSessionId();
  return { type: WS_CONNECT, host: getWSUrl() };
};

export const wsConnected = () => ({ type: WS_CONNECTED, host: getWSUrl() });

export const wsDisconnect = sessionExpired => ({
  type: WS_DISCONNECT,
  host: getWSUrl(),
  sessionExpired
});

export const wsDisconnected = () => ({ type: WS_DISCONNECTED, host: getWSUrl() });

export const wsMessage = () => ({
  type: WS_NEW_MESSAGE,
  msg: {
    companyId: userInfo.companyId,
    correlationId: sessionStorage.getItem('correlationId'),
    sessionId: sessionStorage.getItem('sessionId'),
    userName: userInfo.userName,
    sender: 'fslsdsui'
  }
});

export const wsErrorScenario = () => ({ type: WS_ERROR });
