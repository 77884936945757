import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { outOfStock, scanFlier } from '../../../../actions/orderActions';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import NoVphBooklet from './NoVphBooklet';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import vphBookletImg from './VPH_terms.svg';

class VPHTOSBooklet extends Component {
  state = {
    noVphBooklet: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noVphBooklet) {
      this.setState({
        noVphBooklet: false
      });
    }
  }

  goToNextStep() {
    if (isEmpty(this.props.flierGuide)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  noVphBookletFlow() {
    this.setState({
      noVphBooklet: true
    });
  }

  skipStep = async () => {
    await this.props.outOfStock(WorkflowTaskMapping.SCANVPHTOSBOOKLET);
    if (this.props.stepSkipped === WorkflowTaskMapping.SCANVPHTOSBOOKLET) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.SCANVPHTOSBOOKLET];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  render() {
    if (this.state.noVphBooklet)
      return <NoVphBooklet {...this.props} skipStepName={WorkflowTaskMapping.SCANVPHTOSBOOKLET} />;
    const { isSkipAllowed } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.SCANVPHTOSBOOKLET];
    return (
      <WorkflowStep
        {...this.props}
        title="Scan &amp; pack a Verizon Home Device Protect Terms and Conditions booklet."
        footer={
          <>
            <PrimaryInput
              nextStep={this.goToNextStep.bind(this)}
              onSubmit={itemNumber =>
                this.props.scanFlier(itemNumber, WorkflowTaskMapping.SCANVPHTOSBOOKLET)
              }
              placeholder="Scan barcode"
              rules="required|vph_booklet"
              title="Verizon Home Device Protect TOS Booklet"
              type="barcode"
            />
            {isSkipAllowed && (
              <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
            )}
          </>
        }
        after={
          !isSkipAllowed && (
            <SecondaryButton
              onClick={this.noVphBookletFlow.bind(this)}
              text="Out of VPH tos booklet?"
            />
          )
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>
                Get a VPH Terms and Conditions booklet from inventory.
              </li>
              <li>Scan the barcode on the BACK of the guide.</li>
              <li>Place it in the shipping box.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={vphBookletImg} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

VPHTOSBooklet.defaultProps = {
  flierGuide: ''
};

VPHTOSBooklet.propTypes = {
  scanFlier: PropTypes.func.isRequired,
  flierGuide: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, stepSkipped, flierGuide } = serviceKit;
  return {
    stepSkipped,
    flierGuide,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      scanFlier
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VPHTOSBooklet);
