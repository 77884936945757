import React, { memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const AppLoader = ({ showLoading, hasText }) => (
  <div
    className={classNames(
      'loader-screen',
      { 'show-content': showLoading },
      { 'hide-content': !showLoading }
    )}
  >
    <div className="loader adg-loader adg-loader--light active">
      <span />
      <span />
      <span />
      <p>{hasText} </p>
    </div>
  </div>
);

AppLoader.defaultProps = {
  showLoading: false,
  hasText: 'Loading...'
};

AppLoader.propTypes = {
  hasText: PropTypes.string,
  showLoading: PropTypes.bool
};

const mapStateToProps = ({ appInteractions }) => ({
  hasText: appInteractions.hasText,
  showLoading: appInteractions.showLoading
});

export default connect(
  mapStateToProps,
  null
)(memo(AppLoader));
