import React, { memo } from 'react';
import classNames from 'classnames';
import { WorkflowLabel } from '../../../utilities/enums';
import WorkflowProgressBarItem from './WorkflowProgressBarItem/WorkflowProgressBarItem';
import activeState from './progress-bar-active-state-bg.svg';
import './WorkflowProgressBar.scss';

const WorkflowProgressBar = ({ step, workflowOrder }) => {
  let steps = workflowOrder.map(workflow => WorkflowLabel[workflow]);

  const progressConverter = () => {
    const val = (100 / steps.length) * (steps.length - step);

    if (val === 0) {
      return val - 2;
    } else {
      return val;
    }
  };

  const styles = {
    right: progressConverter() + '%'
  };

  const stepList = steps.map((step, index) => <WorkflowProgressBarItem name={step} key={index} />);

  return (
    <footer className="workflow-footer">
      {step !== workflowOrder.length + 1 ? (
        <div
          className={classNames('workflow-progress-bar', {
            'worflow-progress-bar-no-margin': step === 5
          })}
        >
          <img
            src={activeState}
            className="workflow-progress-bar__step-indicator"
            alt=""
            style={styles}
          />
          {stepList}
        </div>
      ) : null}
    </footer>
  );
};

export default memo(WorkflowProgressBar);
