import React, { memo } from 'react';
import './Section.scss';

const Section = ({ name, rightContent, children }) => (
  <section className="section-content">
    <div className="section-header">
      <label>{name}</label>
      {rightContent}
    </div>
    {children}
  </section>
);

export default memo(Section);
