import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { scanFirstNetSim } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import firstnetSim from './firstnetsim.svg';

class FirstNetStep extends PureComponent {
  goToNextStep() {
    if (isEmpty(this.props.updatedSimNumber)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Scan &amp; pack a FirstNet SIM."
        footer={
          <PrimaryInput
            nextStep={this.goToNextStep.bind(this)}
            onSubmit={this.props.scanFirstNetSim}
            placeholder="Scan barcode"
            rules="required|firstnetsim"
            title="Envelope tracking barcode"
            type="tracking number"
          />
        }
        after={<SecondaryButton text="Out of FirstNet SIM?" onClick={this.props.secondaryClick} />}
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Get a FirstNet SIM from inventory.</li>
              <li>Scan the BOTTOM barcode on the back of the package.</li>
              <li>Place it in the shipping box.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={firstnetSim} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

FirstNetStep.defaultProps = {
  updatedSimNumber: ''
};

FirstNetStep.propTypes = {
  scanFirstNetSim: PropTypes.func,
  updatedSimNumber: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  return {
    updatedSimNumber: serviceKit.firstNetSimNumber
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ scanFirstNetSim }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FirstNetStep);
