import {
  CLEAR_PROCESSING_KIT,
  COLLATERAL_STEP_COMPLETE,
  PRINT_STEP_COMPLETE,
  RESUME_KIT_SUCCESSFUL,
  RESUME_ORDER,
  SAVE_ESN_NUMBER,
  SAVE_FIRSTNET_SIM_NUMBER,
  SAVE_RMA_NUMBER,
  SAVE_SKIP_STEP,
  SAVE_TRACKING_NUMBER,
  SAVE_FLIER_GUIDE,
  SCAN_AFFIX_COMPLETE,
  START_NEXT_ORDER,
  UPDATE_RESHIP_STATUS,
  ORDER_CANCELED_FLAG
} from '../actions/orderActions';

const initialState = {
  collateralStepComplete: false,
  esnNumber: '',
  firstNetSimNumber: '',
  flierGuide: '',
  partStatus: '',
  printStepComplete: false,
  processingServiceKit: {},
  processingStarted: false,
  reshipStepComplete: false,
  resumeOrderData: {},
  rmaNumber: '',
  scanAndAffixComplete: false,
  stepSkipped: '',
  uspsTrackingNumber: '',
  order_canceled_flag: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_PROCESSING_KIT:
      return { ...initialState };
    case COLLATERAL_STEP_COMPLETE:
      return { ...state, collateralStepComplete: true };
    case PRINT_STEP_COMPLETE:
      return { ...state, printStepComplete: true };
    case RESUME_KIT_SUCCESSFUL:
      return { ...state, partStatus: action.partStatus };
    case RESUME_ORDER:
      return { ...state, resumeOrderData: action.rowInfo };
    case SAVE_ESN_NUMBER:
      return { ...state, esnNumber: action.payload };
    case SAVE_FIRSTNET_SIM_NUMBER:
      return { ...state, firstNetSimNumber: action.payload };
    case SAVE_RMA_NUMBER:
      return { ...state, rmaNumber: action.payload };
    case SAVE_SKIP_STEP:
      return { ...state, stepSkipped: action.payload };
    case SAVE_TRACKING_NUMBER:
      return { ...state, uspsTrackingNumber: action.payload };
    case SAVE_FLIER_GUIDE:
      return { ...state, flierGuide: action.payload };
    case SCAN_AFFIX_COMPLETE:
      return { ...state, scanAndAffixComplete: true };
    case START_NEXT_ORDER:
      return {
        ...state,
        processingServiceKit: action.payload,
        esnNumber: '',
        processingStarted: true,
        rmaNumber: '',
        uspsTrackingNumber: '',
        order_canceled_flag: false
      };
    case UPDATE_RESHIP_STATUS:
      return { ...state, reshipStepComplete: true };
    case ORDER_CANCELED_FLAG:
      return { ...state, order_canceled_flag: true };
    default:
      return state;
  }
};
