import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './OrderInfo.scss';

const OrderInfo = ({ kitInfo, orderToPack, step, workflowOrder }) => {
  const { kitSequenceNumber } = kitInfo;
  if (!kitSequenceNumber) return <></>;
  const orderID = parseInt(kitSequenceNumber.toString().slice(2));
  const totalCount = orderToPack;
  return (
    <div className="workflow-header__content">
      {step !== workflowOrder.length + 1 ? (
        <dl className="workflow-header-dl">
          <dt>You're packing order</dt>
          <dd className="workflow-header__currentOrder">{orderID}</dd>
        </dl>
      ) : null}
      <dl className="workflow-header-dl">
        <dt>Orders to pack:</dt>
        <dd>{totalCount}</dd>
      </dl>
    </div>
  );
};

OrderInfo.defaultProps = {
  kitInfo: {},
  orderToPack: 0
};

OrderInfo.propTypes = {
  kitInfo: PropTypes.object,
  orderToPack: PropTypes.number,
  step: PropTypes.number.isRequired
};

const mapStateToProps = ({ order, serviceKit }) => ({
  kitInfo: serviceKit.processingServiceKit,
  orderToPack: order.orderToPack
});

export default connect(mapStateToProps, null)(memo(OrderInfo));
