import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AlertType, CollateralType } from '../../../../utilities/enums';
import { outOfStock } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';

class NoCollateral extends PureComponent {
  componentDidMount() {
    this.props.openAlert(AlertType.OutOfCollateral);
  }

  componentWillUnmount() {
    this.props.closeAlert();
  }

  goToNextStep = async () => {
    await this.props.outOfStock(this.props.skipStepName);
    if (this.props.stepSkipped === this.props.skipStepName) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[this.props.skipStepName];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Contact Asurion FSL Management."
        footer={
          <PrimaryButton
            text="I contacted management, Continue"
            onClick={this.goToNextStep.bind(this)}
          />
        }
      >
        <Row gutter>
          <Col>
            <>
              <p>
                You indicated that you’re out of {CollateralType[this.props.skipStepName]} setup
                guides. Before proceeding, contact Asurion FSL Management.
              </p>
              <p>
                <strong style={{ display: 'inline-flex' }}>
                  If you are NOT out of {CollateralType[this.props.skipStepName]} setup
                  guides,&nbsp;
                  <div onClick={this.props.resetCurrentStep} className="hyperlink-text">
                    GO BACK
                  </div>
                  .
                </strong>
              </p>
            </>
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

NoCollateral.defaultProps = {};

NoCollateral.propTypes = {
  outOfStock: PropTypes.func,
  skipStepName: PropTypes.string,
  stepSkipped: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, stepSkipped } = serviceKit;
  return {
    stepSkipped,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NoCollateral);
