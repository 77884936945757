import React, { memo } from 'react';
import { convertDateToES } from '../../../../../utilities/genericUtility';
import './RoomSection.scss';

const RoomHeading = ({ heading, postedOn }) => {
  return (
    <div className="room-heading__container">
      <div className="room-heading__title">
        <label>{heading}</label>
        Added {convertDateToES(postedOn)}
      </div>
    </div>
  );
};

const RoomBody = ({ message }) => (
  <div className="room-body__container">
    <div className="room-body__content">{message}</div>
  </div>
);

const RoomContent = ({ helpRooms }) => {
  if (helpRooms) {
    helpRooms.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());
    return helpRooms.map((room, index) => (
      <div className="room-content__container" key={index}>
        <RoomHeading heading={room.title} postedOn={room.dateTime} />
        <RoomBody message={room.content} />
      </div>
    ));
  } else return null;
};
export default memo(RoomContent);
