import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { printPackagingLabel } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PackingLabel from '../../../PackingLabel/PackingLabel';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import PrintIcon from './icon-print.svg';
import './PrintLabel.scss';

class PrintLabel extends Component {
  handleClick = async () => {
    await this.props.printPackagingLabel();
    if (this.props.printComplete) {
      window.print();
      this.props.nextStep(this.props.step);
    }
  };

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Print packing label."
        footer={
          <PrimaryButton onClick={this.handleClick.bind(this)} text="Print" icon={PrintIcon} />
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Print a new label by clicking the print button.</li>
              <li>Or, press enter/return on the keyboard.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <div className="step-1-graphic">
              <PackingLabel class="printdialog" {...this.props} />
            </div>
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

PrintLabel.defaultProps = {
  printComplete: false
};

PrintLabel.propTypes = {
  printComplete: PropTypes.bool,
  printPackagingLabel: PropTypes.func
};

const mapStateToProps = ({ serviceKit }) => {
  return {
    printComplete: serviceKit.printStepComplete
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      printPackagingLabel
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrintLabel);
