import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCurrentTimeStamp, setCurrentTimeStamp } from '../../../../utilities/genericUtility';
import { getS3File, clearHotNewsCountData } from '../../../../actions/configurationActions';
import FilterTab from '../../../Common/FilterTab/FilterTab';
import NewsContent from './NewsContent';
import Section from '../../../Common/Section/Section';
import './NewsSection.scss';

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNew: false
    };
  }

  componentWillUnmount() {
    setCurrentTimeStamp();
    this.props.clearHotNewsCountData();
  }

  setShowNew = flag => {
    this.setState({
      showNew: flag
    });
  };

  render() {
    const newContent = this.props.hotNews.filter(
      news => Date.parse(news.createdTimeStamp) + '' > getCurrentTimeStamp()
    );
    return (
      <Section name="Hot news">
        {this.props.showError && <div className="error-message">{this.props.errorMessage}</div>}
        <div className="hotnews-subsection">
          <FilterTab
            name="All"
            selected={!this.state.showNew}
            onClick={() => this.setShowNew(false)}
          />
          <FilterTab
            name="New"
            selected={this.state.showNew}
            onClick={() => this.setShowNew(true)}
          />
        </div>
        <NewsContent
          hotNews={this.state.showNew ? newContent : this.props.hotNews}
          getFile={this.props.getS3File}
        />
      </Section>
    );
  }
}

const mapStateToProps = ({ configuration, error }) => ({
  errorMessage: error.errorMessage,
  hotNews: configuration.hotNews,
  showError: error.showInputError
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getS3File,
      clearHotNewsCountData
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewsSection);
