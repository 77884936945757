import { SHOW_ERROR, SHOW_INPUT_ERROR, HIDE_ERROR } from '../actions/errorActions';

const initialState = {
  showError: false,
  showInputError: false,
  errorMessage: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        showError: true,
        errorMessage: action.payload
      };
    case SHOW_INPUT_ERROR:
      return {
        ...state,
        showInputError: true,
        errorMessage: action.payload
      };
    case HIDE_ERROR:
      return { ...initialState, errorMessage: state.errorMessage };
    default:
      return state;
  }
};
