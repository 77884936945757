export const HIDE_LOADING = 'HIDE_LOADING';
export const SHOW_LOADING = 'SHOW_LOADING';
export const SSOT_ENABLED = 'SSOT_ENABLED';


export const hideLoading = () => dispatch => dispatch({ type: HIDE_LOADING });

export const showLoading = message => dispatch => dispatch({ type: SHOW_LOADING, message });

export const showSSOT = () => dispatch => dispatch({ type: SSOT_ENABLED });
