export const WorkflowNames = {
  affixandscan: 'affixandscan',
  affixandscanreshiplabel: 'affixandscanreshiplabel',
  done: 'done',
  packcollateral: 'packcollateral',
  packcollateraldual: 'packcollateraldual',
  packcollateralfirstnet: 'packcollateralfirstnet',
  packcollateralinbox: 'packcollateralinbox',
  packcollateralesim : 'packcollateralesim',
  packcollateralfirstnetesim : 'packcollateralfirstnetesim',
  printpackinglabel: 'printpackinglabel',
  scanfirstnetsim: 'scanfirstnetsim',
  scanhomeplusguide: 'scanhomeplusguide',
  scanreturnenvelope: 'scanreturnenvelope',
  scanrma: 'scanrma',
  scanskuesn: 'scanesn',
  scanvphguide: 'scanvphguide',
  scanrighttocancel: 'scanrighttocancel',
  scanvphtosbooklet: 'scanvphtosbooklet',
  scanzip: 'scanzip',
  printesnlabel: 'printesnlabel',
  scanesn: 'scanskuesn',
};

export const WorkflowTaskMapping = {
  'SCAN FIRSTNETSIM': WorkflowNames.scanfirstnetsim,
  AFFIX: WorkflowNames.affixandscan,
  AFFIXANDSCANRESHIPLABEL: WorkflowNames.affixandscanreshiplabel,
  COLLATERAL: WorkflowNames.packcollateral,
  COLLATERALDUAL: WorkflowNames.packcollateraldual,
  COLLATERALFIRSTNET: WorkflowNames.packcollateralfirstnet,
  COLLATERALINBOX: WorkflowNames.packcollateralinbox,
  COLLATERALESIM: WorkflowNames.packcollateralesim,
  COLLATERALFIRSTNETESIM: WorkflowNames.packcollateralfirstnetesim,
  ENVELOPE: WorkflowNames.scanreturnenvelope,
  PRINT: WorkflowNames.printpackinglabel,
  SCANFIRSTNETSIM: WorkflowNames.scanfirstnetsim,
  SCANHOMEPLUSGUIDE: WorkflowNames.scanhomeplusguide,
  SCANRMA: WorkflowNames.scanrma,
  SCANVPHGUIDE: WorkflowNames.scanvphguide,
  SCANRIGHTTOCANCEL: WorkflowNames.scanrighttocancel,
  SCANVPHTOSBOOKLET: WorkflowNames.scanvphtosbooklet,
  SCANZIP: WorkflowNames.scanzip,
  PRINTESNLABEL: WorkflowNames.printesnlabel,
  SCANESN: WorkflowNames.scanesn
};

export const WorkflowLabel = {
  affixandscan: 'Affix & Scan',
  affixandscanreshiplabel: 'Reship label',
  packcollateral: 'Setup guide',
  packcollateraldual: 'Setup guide',
  packcollateralfirstnet: 'Setup guide',
  packcollateralinbox: 'Setup guide',
  packcollateralesim: 'Setup guide',
  packcollateralfirstnetesim: 'Setup guide',
  printpackinglabel: 'Packing label',
  scanfirstnetsim: 'FirstNet SIM',
  scanreturnenvelope: 'Scan tracking number',
  scanrma: 'Scan RMA',
  scanskuesn: 'Affix & Scan',
  scanvphguide: 'VHDP Brochure',
  scanrighttocancel: 'Right to Cancel Brochure',
  scanvphtosbooklet: 'VHDP Booklet',
  scanhomeplusguide: 'AHP brochure',
  scanzip: 'Scan ZIP',
  printesnlabel: 'Print ESN',
  scanesn: 'Scan ESN'
};

export const CollateralType = {
  packcollateral: '',
  packcollateraldual: 'Dual SIM',
  packcollateralfirstnet: 'FirstNet',
  packcollateralinbox: 'SIM',
  packcollateralesim: 'ESIM',
  packcollateralfirstnetesim: 'FirstNetESIM'
};

export const AlertType = {
  ExceptionalFlow: 'ExceptionalFlow',
  FirstNetSimAvailable: 'FirstNetSimAvailable',
  OutOfCollateral: 'OutOfCollateral',
  OutOfEnvelope: 'OutOfEnvelope',
  OutOfHomePlusGuide: 'OutOfHomePlusGuide',
  OutOfVPHGuide: 'OutOfVPHGuide',
  OutOfRightToCancelBrochures: 'OutOfRightToCancelBrochures',
  OutOfVPHTOSBooklet: 'OutOfVPHTOSBooklet',
  RetryPrinting: 'RetryPrinting',
  SKUAvailable: 'SKUAvailable'
};

export const MenuContentType = {
  ExpertList: 'ExpertList',
  HotNews: 'HotNews',
  NeedHelp: 'NeedHelp'
};

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const MONTH = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const CURRENT_TIME = 'currentTime';

export const HelpTabs = {
  CONTACTS: 'Contacts',
  ROOMS: 'Help rooms'
};

export const ContactKeys = {
  WEEKENDCONTACT: 'managers-weekend',
  HELPROOM: 'managers-helproom'
};


export const FlowTypes = {
  DASHBOARD:'DSH',
  RETURN_SHIPMENT:'RSH',
  FSL_DASHBOARD:'FDSH',
  ORDER_TO_KIT:'OTK',
  UNFINISHED:'UNF',
  EXCEPTION:'EXC',
  RESTAGING:'RST',
}