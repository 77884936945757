import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { outOfStock, packCollateral } from '../../../../actions/orderActions';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import CollateralDual from './CollateralDual';
import CollateralEsim from './CollateralEsim';
import CollateralFirstNet from './CollateralFirstNet';
import CollateralFirstNetEsim from './CollateralFirstNetEsim';
import CollateralInbox from './CollateralInbox';
import NoCollateral from './NoCollateral';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import SetupGuide from './SetUpGuide.png';
import SetupGuideUSCC from './SetUpGuideUSCC.svg';
import './Collateral.scss';

class Collateral extends Component {
  state = {
    noCollateral: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noCollateral) {
      this.setState({
        noCollateral: false
      });
    }
  }

  packCollateralItem = async type => {
    await this.props.packCollateral(type);
    if (this.props.collateralStepComplete) this.props.nextStep(this.props.step);
  };

  noCollateralFlow() {
    this.setState({
      noCollateral: true
    });
  }

  skipStep = async () => {
    await this.props.outOfStock(this.props.type);
    if (this.props.stepSkipped === this.props.type) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[this.props.type];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  getRule = (clientId) => {
    switch(clientId){
      case '121':
        return 'required|collateraluscellular';
      case '244':
        return 'required|collateralcox';
      case '31':
          return 'required|collateralverizonkittykat';
      default:
        return 'required|collateralkittykat';
    }
  };

  render() {
    const usccClient = this.props.selectedClient.externalClientId === '121';
    if (this.state.noCollateral)
      return (
        <NoCollateral
          {...this.props}
          skipStepName={this.props.type}
          noCollateral={() => this.noCollateralFlow()}
        />
      );
    const { isSkipAllowed } = this.props.workflowOrder.outOfStock[this.props.type];
    switch (this.props.type) {
      case WorkflowTaskMapping.COLLATERALDUAL:
        return (
          <CollateralDual
            {...this.props}
            packCollateral={this.packCollateralItem}
            noCollateral={() => this.noCollateralFlow()}
            isSkipAllowed={isSkipAllowed}
            skipStep={this.skipStep}
          />
        );
      case WorkflowTaskMapping.COLLATERALFIRSTNET:
        return (
          <CollateralFirstNet
            {...this.props}
            packCollateral={this.packCollateralItem}
            noCollateral={() => this.noCollateralFlow()}
            isSkipAllowed={isSkipAllowed}
            skipStep={this.skipStep}
          />
        );
      case WorkflowTaskMapping.COLLATERALINBOX:
        return (
          <CollateralInbox
            {...this.props}
            packCollateral={this.packCollateralItem}
            noCollateral={() => this.noCollateralFlow()}
            isSkipAllowed={isSkipAllowed}
            skipStep={this.skipStep}
          />
        );
        case WorkflowTaskMapping.COLLATERALESIM:
          return (
            <CollateralEsim
              {...this.props}
              packCollateral={this.packCollateralItem}
              noCollateral={() => this.noCollateralFlow()}
              isSkipAllowed={isSkipAllowed}
              skipStep={this.skipStep}
            />
          );
        case WorkflowTaskMapping.COLLATERALFIRSTNETESIM:
          return (
             <CollateralFirstNetEsim
               {...this.props}
               packCollateral={this.packCollateralItem}
               noCollateral={() => this.noCollateralFlow()}
               isSkipAllowed={isSkipAllowed}
               skipStep={this.skipStep}
              />
            ); 
      case WorkflowTaskMapping.COLLATERAL:
      default:
        return (
          <WorkflowStep
            {...this.props}
            title="Pack a Setup Guide."
            footer={
              <>
                <PrimaryInput
                  nextStep={() => this.packCollateralItem('SG')}
                  placeholder="Scan barcode"
                  rules= {this.getRule(this.props.selectedClient.externalClientId)}
                  title="Collateral barcode"
                  type="barcode"
                />
                {isSkipAllowed && (
                  <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
                )}
              </>
            }
            after={
              !isSkipAllowed && (
                <SecondaryButton
                  onClick={this.noCollateralFlow.bind(this)}
                  text="Out of setup guides?"
                />
              )
            }
          >
            <Row gutter>
              <Col oneoftwo>
                <ul>
                  <li>Get a Setup Guide from inventory.</li>
                  <li>Scan the setup guide barcode on the inventory shelf.</li>
                  <li>Place it in the shipping box.</li>
                </ul>
              </Col>
              <Col oneoftwo>
                <img src={usccClient ? SetupGuideUSCC : SetupGuide} className="step-4-graphic" alt="" />
              </Col>
            </Row>
          </WorkflowStep>
        );
    }
  }
}

Collateral.defaultProps = {
  collateralStepComplete: false
};

Collateral.propTypes = {
  collateralStepComplete: PropTypes.bool,
  packCollateral: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

const mapStateToProps = ({ serviceKit }) => {
  const { collateralStepComplete, processingServiceKit, stepSkipped } = serviceKit;
  return {
    collateralStepComplete,
    stepSkipped,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      packCollateral
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Collateral);
