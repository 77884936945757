import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { outOfStock, scanRmaNumber } from '../../../../actions/orderActions';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import NoEnvelope from '../Envelope/NoEnvelope';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import rmaEnvelope from './rma-envelope.svg';

class RmaLabel extends Component {
  state = {
    noEnvelope: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noEnvelope) {
      this.setState({
        noEnvelope: false
      });
    }
  }

  goToNextStep() {
    if (isEmpty(this.props.updatedRmaNumber)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  noEnvelopeFlow() {
    this.setState({
      noEnvelope: true
    });
  }

  skipStep = async () => {
    await this.props.outOfStock(WorkflowTaskMapping.SCANRMA);
    if (this.props.stepSkipped === WorkflowTaskMapping.SCANRMA) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[WorkflowTaskMapping.SCANRMA];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  render() {
    const attClient = this.props.selectedClient.externalClientId === '313';
    if (this.state.noEnvelope)
      return <NoEnvelope {...this.props} skipStepName={WorkflowTaskMapping.SCANRMA} />;
    //Made this false always to not allowing to continue without scanning for all clients.
    //Keeping this in comment for preventing from loosing code for skip button.  
    const { isSkipAllowed } = false//this.props.workflowOrder.outOfStock[WorkflowTaskMapping.SCANRMA];
    return (
      <WorkflowStep
        {...this.props}
        title="Scan a return envelope."
        footer={
          <>
            <PrimaryInput
              nextStep={this.goToNextStep.bind(this)}
              onSubmit={this.props.scanRmaNumber}
              placeholder="Scan RMA"
              rules="required|rma"
              title="Envelope tracking barcode"
              type="tracking number"
            />
            {isSkipAllowed && (
              <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
            )}
          </>
        }
        after={
          !isSkipAllowed && (
            <SecondaryButton
              onClick={this.noEnvelopeFlow.bind(this)}
              text="Out of return envelopes?"
            />
          )
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Get a new return envelope from inventory.</li>
              <li>Scan the TOP barcode on the return envelope.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={rmaEnvelope} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

RmaLabel.defaultProps = {
  updatedRmaNumber: ''
};

RmaLabel.propTypes = {
  scanReturnEnvelope: PropTypes.func.isRequired,
  updatedRmaNumber: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, rmaNumber, stepSkipped } = serviceKit;
  return {
    updatedRmaNumber: rmaNumber,
    stepSkipped,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      scanRmaNumber
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RmaLabel);
