import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import ReactTable from 'react-table';
import { generateCorrelationID, isEmpty } from '../../../utilities/genericUtility';
import SearchSpan from '../../Common/SearchSpan/SearchSpan';
import ClientLabel from './ClientLabel';
import { FlowTypes } from '../../../utilities/enums';

const mapOfOrderStatus = {
  'CANCELLED-UNKITTED': 'Canceled & De-kitted',
  CANCELLED: 'Canceled',
  IN_PROCESS: 'Unfinished',
  KITTED: 'Ready for pickup',
  OUTOFSKU: 'Out of stock',
  PICKED_UP: 'Out for delivery',
  UNKITTED: 'Return complete'
};

const DashTable = ({
  exceptions,
  orders,
  resumeable,
  rowSelected,
  searchString,
  showCustomerInfo
}) => {
  let columns = [
    {
      Header: 'Order',
      accessor: 'kitSequenceNumber',
      Cell: props => (
        <span
          className={classNames('orderID', {
            orderID__selected:
              !isEmpty(searchString) &&
              searchString.length <= 2 &&
              props.value.toString().endsWith(searchString)
          })}
        >
          {props.value.toString().slice(2)}
        </span>
      ),
      sortMethod: (a, b) => {
        let aLastTwo = a;
        let bLastTwo = b;

        if (aLastTwo && bLastTwo) {
          aLastTwo = a.slice(-2);
          bLastTwo = b.slice(-2);
        }

        return parseInt(aLastTwo) - parseInt(bLastTwo);
      }
    },
    {
      Header: 'Customer',
      accessor: 'customerFullName',
      Cell: props => {
        const properties = props.value.split('|');
        const customerName = properties[0];
        const clientId = parseInt(properties[1]);
        return !isEmpty(customerName) ? (
          <div className="table-customer-name">
            <SearchSpan value={customerName} searchString={searchString}></SearchSpan>
            <ClientLabel externalClientId={clientId} />
          </div>
        ) : (
          <div className="table-customer-name">
            <span className="greyText">Unavailable</span>
            <ClientLabel externalClientId={clientId} />
          </div>
        );
      }
    },
    {
      Header: 'Appt. window',
      accessor: 'appointmentWindow',
      Cell: props =>
        props.value ? (
          <SearchSpan value={props.value} searchString={searchString}></SearchSpan>
        ) : (
          <span className="greyText">Unavailable</span>
        )
    },
    {
      Header: 'SKU',
      accessor: 'serviceKitSKU',
      Cell: props =>
        props.value ? (
          <SearchSpan value={props.value} searchString={searchString}></SearchSpan>
        ) : (
          <span className="greyText">Unavailable</span>
        )
    }
  ];

  if (resumeable) {
    columns = columns.filter(column => column.accessor !== 'esn');
    columns.push({
      Header: '',
      accessor: 'kitSequenceNumber',
      Cell: props => (
        <span
          className="resumeLink"
          onClick={(e) => {
            e.stopPropagation();
            generateCorrelationID(props?.original?.kitReferenceNumber, FlowTypes.UNFINISHED);
            rowSelected(props.value);
          }}
        >
          Resume
        </span>
      )
    });
  }

  if (exceptions) {
    columns.push({
      Header: 'Order Status',
      accessor: 'status',
      Cell: props => mapOfOrderStatus[props.value] ? mapOfOrderStatus[props.value] : <></>
    });
  }

  return (
    <ReactTable
      data={orders}
      columns={columns}
      getTrProps={(state, rowInfo, column) => {
        return {
          onClick: e => {
            showCustomerInfo(rowInfo.original);
          }
        };
      }}
      minRows={0}
      noDataText="No information available."
      showPageSizeOptions={true}
      showPagination={orders.length > 10}
    />
  );
};

DashTable.propTypes = {
  orders: PropTypes.array,
  resumeable: PropTypes.bool.isRequired,
  rowSelected: PropTypes.func
};

export default memo(DashTable);
