import React, { Component } from "react";
import "./WorkflowProgressBarItem.scss";

class WorkflowProgressBarItem extends Component {
  render() {
    return (
      <div className="workflow-progress-bar__item">
        <span className="workflow-progress-bar__item__text">
          {this.props.name}
        </span>
      </div>
    );
  }
}

export default WorkflowProgressBarItem;
