import React, { Component } from 'react';
import { connect } from 'react-redux';
import FocusLock from 'react-focus-lock';
import SimpleReactValidator from 'simple-react-validator';
import BarcodeIcon from './icon-barcode.svg';
import errorSound from './errorSevere-buzzer.mp3';
import './PrimaryInput.scss';

class PrimaryInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      placeholder: this.props.placeholder,
      valid: false,
      value: ''
    };
    this.validator = new SimpleReactValidator({
      messages: {
        element: message => <div>{message}</div>,
        in: 'Wrong barcode. Try again.'
      },
      validators: {
        esn: {
          message: 'Wrong barcode. Scan the “ESN (dec)” barcode.',
          rule: (val, params, validator) =>
            validator.helpers.testRegex(val, /^\d{11,18}$/i) && params.indexOf(val) === -1,
          required: true
        },
        sku: {
          message: 'Wrong barcode. Scan the “ESN (dec)” barcode.',
          rule: (val, params, validator) => validator.helpers.testRegex(val, /^\d{11,18}$/i) && params.indexOf(val) === -1 && val.toString() === this.props.esnNumber,
          required: true
        },
        imei: {
          message: 'Wrong barcode. Scan the “IMEI” barcode.',
          rule: (val, params, validator) =>
            validator.helpers.testRegex(val, /^\d{11,18}$/i) && params.indexOf(val) === -1,
          required: true
        },
        collateraluscellular: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return (val, params, validator) => val.toString() === 'USCCSAMEDAYV1' || val.toString() === 'USCCSAMEDAYV2'
          },
          required: true
        },
        collateralcox: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === 'COXSAMEDAYV1';
          },
          required: true
        },
        collateralverizonkittykat:{
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return (
              val.toString() === 'KITTYKAT' ||
              val.toString() === 'VZNSAMEDAYV1' ||
              val.toString() === 'VZNSAMEDAYV2'
            );
          },
          required: true
        },
        collateralkittykat: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === 'KITTYKAT';
          },
          required: true
        },
        collateralmain: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === '84793';
          },
          required: true
        },
        collateraldual: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === '84797';
          },
          required: true
        },
        collateralesim: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === '84796';
          },
          required: true
        },
        collateralfirstnetesim: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === '84796';
          },
          required: true
        },
        firstnetsetup: {
          message: 'Wrong setup guide. Choose another.',
          rule: (val, params, validator) => {
            return val.toString() === '84794';
          },
          required: true
        },
        firstnetsim: {
          message: 'Invalid barcode. Try again.',
          rule: (val, params, validator) => val.length === 20,
          required: true
        },
        homePlusGuide: {
          message: 'Wrong barcode. Try again.',
          rule: (val, params, validator) =>
            val.toString() === 'HOMETECHV2' ||
            val.toString() === 'HOMETECHV3' ||
            val.toString() === 'HOMETECHV4' ||
            val.toString() === 'ATTNEWADDV1',
          required: true
        },
        rma: {
          // name the rule
          message: 'Invalid barcode. Try again.',
          rule: (val, params, validator) => {
            const value = val.substr(val.indexOf('AS'), 9);
            return (
              validator.helpers.testRegex(val, /^\d{18}AS\d{7}[0-9A-Z]?$/g) &&
              params.indexOf(value) === -1 &&
              parseInt(value.substring(value.length - 7)) !== 0
            );
          },
          messageReplace: (message, params) =>
            message.replace(':values', this.helpers.toSentence(params)), // optional
          required: true // optional
        },
        usps: {
          // name the rule
          // Any numbers with leng†h 34 by SBXA-4319, Rest of validation is on API side.
          // Any numbers with leng†h 20,26 or 30. 
          // Any numbers of any length if starts with 91.
          // Numbers with length 16 to 22 if number starts with 9. 
          // EA912324154AB - start with E followed by 1 non number followed by any 9 digits followed by 2 non numbers at the end.
          // Ea12354563US = starts with any 2 alphabets followed by any number(any length) followed by US at the end
          message: 'Invalid USPS tracking number. Try again.',
          rule: (val, params, validator) =>
            validator.helpers.testRegex(
              val,
              /(\b\d{30}\b)|(\b\d{34}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}$|^9\d{15,21}$| ^91[0-9]+$| ^[A-Za-z]{2}[0-9]+US$/i
            ) && params.indexOf(val) === -1,
          messageReplace: (message, params) =>
            message.replace(':values', this.helpers.toSentence(params)), // optional
          required: true // optional
        },
        vph: {
          message: 'Wrong barcode. Try again.',
          rule: (val, params, validator) =>
            val.toString() === 'INS80112EN' ||
            val.toString() === 'INS80142EN' ||
            val.toString() === 'INS80146EN',
          required: true
        },
        // Verizon Reship flow
        vph_booklet: {
          message: 'Wrong barcode. Try again.',
          rule: (val, params, validator) => val.toString() === 'INS80096EN',
          required: true
        },
        rtc: {
          message: 'Wrong barcode. Try again.',
          rule: (val, params, validator) => val.toString() === 'MKT70338',
          required: true
        },
        zip8: {
          message: 'Invalid ZIP code. Try again.',
          rule: (val, params, validator) => val.length > 8 && val.toString().endsWith('9918'),
          required: true
        },
        zip7: {
          message: 'Invalid ZIP code. Try again.',
          rule: (val, params, validator) => val.length > 8 && val.toString().endsWith('9917'),
          required: true
        }
      }
    });
    this.handleChange = this.handleChange.bind(this);
    this.errorSound = new Audio(errorSound);
  }

  handleChange(event) {
    this.setState({ value: event.target.value.toUpperCase() });
  }

  componentDidUpdate() {
    if (this.props.showError && !this.state.error) {
      this.setState({
        error: true,
        valid: false,
        value: '',
        placeholder: this.props.errorMessage
      });
      this.errorSound.play();
    } else if (this.props.isError && this.props.showModal && !this.state.error) {
      this.props.openModal();
      this.setState({
        error: true,
        valid: false,
        value: '',
        placeholder: ''
      });
      this.errorSound.play();
    }
  }

  submitForm = e => {
    if (this.validator.allValid()) {
      this.setState({
        error: false,
        placeholder: `Valid ${this.props.type}. Success!`,
        valid: true,
        value: ''
      });
      if (this.props.onSubmit) {
        this.props.onSubmit(this.state.value);
      }
      setTimeout(
        function () {
          this.props.nextStep();
        }.bind(this),
        500
      );
    } else {
      this.setState({
        error: true,
        placeholder: this.validator.getErrorMessages()[this.props.title],
        value: ''
      });
      this.errorSound.play();
      this.forceUpdate();
    }
    e.preventDefault();
  };

  render() {
    return (
      <FocusLock persistentFocus>
        <form onSubmit={this.submitForm}>
          <div
            className={
              'workflow-primary-field ' +
              (this.state.error ? 'workflow-primary-field--error' : '') +
              (this.state.valid ? 'workflow-primary-field--valid' : '')
            }
          >
            <label htmlFor="primary-field" className="workflow-primary-field__label" />
            <div className="workflow-primary-field__input-group ">
              <input
                type="text"
                value={this.state.value}
                onChange={this.handleChange}
                className={this.props.disabled ? 'workflow-primary-field__input-disabled' : 'workflow-primary-field__input'}
                id="primary-field"
                placeholder={this.state.placeholder}
                autoFocus
                autoComplete="off"
                disabled={this.props.disabled}
              />
              {/*Validation*/}
              {this.validator.message(this.props.title, this.state.value, this.props.rules)}
              <button type="submit" disabled={this.props.disabled} className="workflow-primary-field__btn js-scan-button">
                <img src={BarcodeIcon} className="workflow-primary-field__btn__icon" alt="" />
              </button>
            </div>
          </div>
        </form>
      </FocusLock>
    );
  }
}

const mapStateToProps = ({ error, serviceKit }) => ({
  errorMessage: error.errorMessage,
  isError: error.showError,
  showError: error.showInputError,
  esnNumber: serviceKit.esnNumber
});

export default connect(mapStateToProps, null)(PrimaryInput);
