import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getExpertListInfo } from './../../../../actions/configurationActions';
import Section from '../../../Common/Section/Section';
import ExpertListSection from './ExpertListSection';

class ExpertList extends PureComponent {
  componentDidMount() {
    this.props.getExpertListInfo();
  }

  render() {
    return (
      <Section name="List of experts" rightContent="">
        <ExpertListSection>{this.props.expertListArray}</ExpertListSection>
      </Section>
    );
  }
}

const mapStateToProps = ({ configuration }) => ({ expertListArray: configuration.expertListInfo });

const mapDispatchToProps = dispatch => bindActionCreators({ getExpertListInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExpertList);
