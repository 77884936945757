import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { loadStockIndicator, scanSkuEsn, affixAndESNScanLabel } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import prettyBox from './pretty-box-label.png';
import './SKU.scss';

class SKUStep extends Component {
  state = {
    stockUpdated: false,
    currentStock: -1
  };

  componentDidMount = async () => {
    const quantityAvailable = await this.props.loadStockIndicator();
    this.setState({
      stockUpdated: true,
      currentStock: quantityAvailable
    });
  };

  goToNextStep() {
    if (isEmpty(this.props.esnNumber)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Scan &amp; pack a box with SKU:"
        footer={
          <PrimaryInput
            nextStep={this.goToNextStep.bind(this)}
            onSubmit={this.props.kitInfo.workflow.serviceKitTasks.includes("printesnlabel") ? this.props.scanSkuEsn : this.props.affixAndESNScanLabel}
            placeholder="Scan ESN"
            rules="required|esn"
            title="SKU Barcode"
            type="SKU"
            showModal={this.props.showError && this.props.errorMessage.actual}
            openModal={() => this.props.openModal('WrongSKUScan')}
          />
        }
        banner={
          <div className="workflow-primary-field">
            <div className="workflow-step__sku">
              <span className="workflow-step__sku__number">{this.props.kitInfo.sku}</span>
            </div>
            {this.state.stockUpdated && (
              <div
                className={classNames(
                  'workflow-step__stock_indicator',
                  { 'in-stock': this.state.currentStock > 0 },
                  { 'out-of-stock': this.state.currentStock < 1 }
                )}
              >
                <span>
                  {this.state.currentStock > 0
                    ? `${this.state.currentStock} in stock`
                    : 'Out of stock'}
                </span>
              </div>
            )}
          </div>
        }
        after={<SecondaryButton text="SKU out of stock?" onClick={this.props.secondaryClick} />}
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Get the box from inventory.</li>
              <li>Scan the TOP barcode on the box, labeled “ESN (dec)”.</li>
              <li>Place it in the shipping box, on top of the Setup Guide.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={prettyBox} className="step-5-graphic" alt="" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

SKUStep.propTypes = {
  esnNumber: PropTypes.string,
  kitInfo: PropTypes.object,
  scanSkuEsn: PropTypes.func.isRequired,
  affixAndESNScanLabel: PropTypes.func.isRequired,
};

SKUStep.defaultProps = {
  esnNumber: '',
  kitInfo: {}
};

const mapStateToProps = ({ serviceKit, error }) => {
  const { esnNumber, processingServiceKit } = serviceKit;
  const { showError, errorMessage } = error;
  return {
    errorMessage,
    esnNumber,
    kitInfo: processingServiceKit,
    showError
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadStockIndicator,
      scanSkuEsn,
      affixAndESNScanLabel
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SKUStep);
