import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AlertType } from '../../../../utilities/enums';
import { getClientId, getClientName } from '../../../../utilities/clientUtility';
import { outOfStock, selectClientForWorkflow } from '../../../../actions/orderActions';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import Col from '../../../Common/Column';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';

class NoFirstNetSim extends Component {
  state = {
    currentCarrierCount: -1,
    otherCarrierName: ''
  };

  componentDidMount = async () => {
    await this.getDataOnLoad();
    this.props.openAlert(AlertType.FirstNetSimAvailable);
  };

  getDataOnLoad = async () => {
    const orderToPack = this.props.serviceKitOrders.filter(
      order => order.serviceKitOrderStatusType === 'OrdersToPack'
    )[0];
    const clientData = orderToPack.serviceKitOrderClientCounts.filter(
      order => order.externalClientId === this.props.selectedClient.externalClientId
    )[0];
    const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
      order =>
        order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
    );
    this.setState({
      currentCarrierCount: clientData ? clientData.count : 0,
      otherCarrierName: otherCarrierData[0]
        ? getClientName(otherCarrierData[0].externalClientId)
        : null
    });
    return orderToPack.serviceKitDisplayRows.length;
  };

  handleClick = async e => {
    await this.props.outOfStock(WorkflowTaskMapping.SCANFIRSTNETSIM);
    if (this.props.orderToPack > 0) {
      if (this.state.currentCarrierCount === 0)
        await this.props.selectClientForWorkflow(getClientId(this.state.otherCarrierName));
      await this.props.setAsPacked();
      if (this.props.processingStarted) this.props.resetCurrentFlow();
    } else {
      await this.props.closeWorkflow();
    }
  };

  componentWillUnmount() {
    this.props.closeAlert();
  }

  render() {
    const buttonLabel =
      this.props.orderToPack > 0
        ? this.state.currentCarrierCount > 0
          ? 'Start Next Order'
          : `Start ${this.state.otherCarrierName} orders`
        : 'Complete';
    return (
      <WorkflowStep
        {...this.props}
        title="Contact Asurion FSL Management."
        showStep={false}
        footer={
          <PrimaryButton
            onClick={this.handleClick.bind(this)}
            text={`I contacted management, ${buttonLabel}`}
          />
        }
        after={<div />}
      >
        <Row>
          <Col oneofone>
            <>
              <p>
                You indicated that you’re out of FirstNet SIM cards. Before proceeding, contact Asurion FSL Management.
              </p>
              <p>
                <strong style={{ display: 'inline-flex' }}>
                  If you are NOT out of FirstNet SIM cards,&nbsp;
                  <div onClick={this.props.resetCurrentStep} className="hyperlink-text">
                    GO BACK
                  </div>
                  .
                </strong>
              </p>
            </>
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

NoFirstNetSim.propTypes = {
  kitInfo: PropTypes.object,
  noSkuAvailable: PropTypes.func
};

NoFirstNetSim.defaultProps = {
  kitInfo: {}
};

const mapStateToProps = ({ order, serviceKit }) => {
  const { processingServiceKit, processingStarted } = serviceKit;
  const { orderToPack, selectedClient, serviceKitDisplayRows } = order;
  return {
    kitInfo: processingServiceKit,
    orderToPack,
    processingStarted,
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      selectClientForWorkflow
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NoFirstNetSim);
