import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getClientName } from '../../../utilities/clientUtility';
import Button from '../../Common/Button/Button';
import DifferenceSpan from '../../Common/DifferenceSpan/DifferenceSpan';
import ClientSelection from './ClientSelection';
import Modal from '../Modal';
import OrderId from '../../Common/OrderId/OrderId';

const ModalRouter = props => {
  switch (props.modal) {
    case 'ClientSelection':
      const clientSelection = props.clientCounts.map(({ externalClientId, count }) => {
        if (count > 0)
          return (
            <ClientSelection
              clientName={getClientName(externalClientId)}
              onSelect={() => props.selectClient(externalClientId)}
              orderCount={count}
              key={externalClientId}
            />
          );
        else return null;
      });
      return (
        <Modal
          {...props}
          title="Choose a carrier to pack orders for."
          message={<div className="flex-row">{clientSelection}</div>}
          buttons={
            <div className="flex-row client-selection--end">
              <Button text="return to dashboard" onClick={props.closeModal} type="link" />
            </div>
          }
        ></Modal>
      );
    case 'LeaveWorkflow':
      return (
        <Modal
          {...props}
          title="Are you sure you want to exit?"
          message={
            <>
              <p>Exiting now will set this order to unfinished.</p>
              <p>You can finish packing this order later, but you can’t start over.</p>
            </>
          }
          buttons={
            <div>
              <Button text="No, continue packing" onClick={props.closeModal} />
              <Button text="Yes, I need to exit" onClick={props.closeWorkflow} type="ghost" />
            </div>
          }
        />
      );
    case 'MidwayCancel':
      return (
        <Modal
          {...props}
          icon="error"
          title="Stop! This order is now canceled."
          message={
            <ol>
              <li>De-kit all items and return them to the correct area in inventory.</li>
              <li>Peel packing label off the outside of the box and discard.</li>
              <li>Return the box to inventory or use for the next order.</li>
            </ol>
          }
          buttons={
            <div>
              {props.orderToPack > 0 && (
                <Button text="Start next order" onClick={props.displayNextOrder} />
              )}
              <Button text="Return to dashboard" onClick={props.closeWorkflow} type="ghost" />
            </div>
          }
        />
      );
    case 'ResumeWorkflow':
      return (
        <Modal
          {...props}
          title={
            <div className="modal-custom-title">
              <label>Resuming order&nbsp;</label>
              <OrderId kitSequenceNumber={props.kitSequenceNumber} />
            </div>
          }
          isLoader={true}
        />
      );
    case 'TokenExpired':
      return (
        <Modal
          title="Your session has expired."
          message={
            <>
              <p>
                Please close this window and click the “FSL Part Kitting” link in ServiceBench to
                start a new session.
              </p>
              <p>Sessions are valid for 8 hours.</p>
            </>
          }
          buttons={<Button text="Close Window" onClick={props.closeWindow} />}
        />
      );
      case 'SSoTEnabled':
      return (
        <Modal
          title="Location is SSoT enabled."
          message={
            <>
              <p>
                This location is SSoT enabled you won't be able to kit through this application.
              </p>
            </>
          }
          buttons={<Button text="Close Window" onClick={props.closeWindow} />}
        />
      );
    case 'WrongSKUScan':
      return (
        <Modal
          icon="warning"
          title="Wrong SKU scanned"
          message={
            <>
              <DifferenceSpan
                before="You scanned:"
                expected={props.errorMessage.expected}
                type="error"
                value={props.errorMessage.actual}
              />
              <DifferenceSpan
                before="Required SKU:"
                expected={props.errorMessage.actual}
                type="info"
                value={props.errorMessage.expected}
              />
            </>
          }
          buttons={<Button text="Continue" onClick={props.closeModal} />}
        />
      );
    default:
      return <Modal {...props} />;
  }
};

ModalRouter.defaultProps = {
  clientCounts: [],
  kitSequenceNumber: '0000',
  orderToPack: 0
};

ModalRouter.propTypes = {
  clientCounts: PropTypes.array,
  kitSequenceNumber: PropTypes.string,
  orderToPack: PropTypes.number
};

const mapStateToProps = ({ order, serviceKit, error }) => ({
  clientCounts: order.clientCounts,
  kitSequenceNumber: serviceKit.resumeOrderData.kitSequenceNumber,
  errorMessage: error.errorMessage
});

export default connect(mapStateToProps, null)(memo(ModalRouter));
