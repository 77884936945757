import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clearClientSelection,
  clearProcessingKit,
  getOrders,
  nextServiceKitOrder,
  resumeOrder,
  selectClientForWorkflow
} from '../../actions/orderActions';
import { getHotNews } from '../../actions/configurationActions';
import { getRefreshRate, updateCurrentSession } from '../../utilities/sessionUtility';
import { userInfo } from '../../utilities/apiUtitlity';
import { WorkflowTaskMapping } from '../../utilities/enums';
import { wsConnect, wsDisconnect, wsMessage } from '../../actions/wsConnectActions';
import AppLoader from '../Common/AppLoader/AppLoader';
import Dashboard from '../Dashboard/Dashboard';
import DashTimer from '../Dashboard/DashTimer/DashTimer';
import ModalRouter from '../Modal/ModalRouter/ModalRouter';
import Workflow from '../Workflow/Workflow';
import './App.scss';

let refreshTimeout = null;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrderCancelled: false,
      modal: false,
      resumeStep: 0,
      returnWorkflow: false,
      sessionExpiry: false,
      workflow: false
    };
    const url = new URL(window.location.href);
    userInfo.token = url.searchParams.get('t');
    userInfo.refreshToken = url.searchParams.get('r');
    updateCurrentSession(this.sessionExpired.bind(this));
    window.onbeforeunload = e => {
      this.props.wsDisconnect();
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeWorkflow = this.closeWorkflow.bind(this);
    this.displayNextOrder = this.displayNextOrder.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openSelectClient = this.openSelectClient.bind(this);
    this.resumeWorkflow = this.resumeWorkflow.bind(this);
    this.selectClient = this.selectClient.bind(this);
    this.setAsPacked = this.setAsPacked.bind(this);
    this.startReturnProcess = this.startReturnProcess.bind(this);
  }

  sessionExpired() {
    this.setState({
      sessionExpiry: true
    });
    this.props.wsDisconnect(true);
    this.openModal('TokenExpired');
  }

  closeWindow() {
    // ==UserScript==
    // @grant       GM_addStyle
    // ==/UserScript==
    window.open(window.location, '_self').close();
  }

  openSelectClient = () => {
    let nonZeroClients = 0,
      externalClientId = 0;
    this.props.clientCounts.forEach(client => {
      if (client.count !== 0) {
        nonZeroClients++;
        if (externalClientId === 0) externalClientId = client.externalClientId;
      }
    });
    if (this.props.clientCounts.length > 1 && nonZeroClients > 1) this.openModal('ClientSelection');
    else this.selectClient(externalClientId);
  };

  selectClient = id => {
    this.props.selectClientForWorkflow(id);
    this.openWorkflow();
    this.closeModal();
  };

  openWorkflow = async () => {
    await this.props.nextServiceKitOrder();
    if (this.props.modalPresent) this.setState({ workflow: true });
  };

  resumeWorkflow = async selected => await this.props.resumeOrder(selected, this.resumeToStep);

  resumeToStep = stepName => {
    this.openModal('ResumeWorkflow');
    const index = this.props.workflowOrder.indexOf(WorkflowTaskMapping[stepName]);
    setTimeout(() => {
      this.setState({ modal: false, resumeStep: index + 2, workflow: true });
    }, 1000);
  };

  closeWorkflow = async () => {
    this.closeModal();
    this.setState({
      currentOrderCancelled: false,
      resumeStep: 0,
      returnWorkflow: false,
      workflow: false
    });
    this.props.clearProcessingKit();
    this.props.clearClientSelection();
  };

  openModal = newModal => {
    this.setState({
      currentOrderCancelled: newModal === 'MidwayCancel',
      modal: newModal
    });
  };

  closeModal = () => {
    if (!this.state.currentOrderCancelled) this.setState({ modal: false });
  };

  setAsPacked = async () => {
    await this.props.nextServiceKitOrder();
  };

  startReturnProcess = () => {
    this.setState({
      returnWorkflow: true,
      workflow: true
    });
  };

  componentDidMount = async () => {
    this.props.getOrders(false);
    this.scheduleGetOrders();
    await this.props.wsConnect();
    this.sendMessage(this.props.wsconnected);
    this.props.getHotNews();
  };

  componentDidUpdate = async () => {
    if(this.props.ssotenabled === true){
        this.openModal('SSoTEnabled');
        this.props.wsDisconnect(true);
    }
    if (this.props.wserrored) {
      if (!refreshTimeout) {
        clearTimeout(refreshTimeout);
        this.scheduleGetOrders(true);
      }
    }
  };

  scheduleGetOrders = async notFirstCall => {
    const refreshRate = getRefreshRate();
    notFirstCall &&
      !this.props.modalPresent &&
      !this.state.sessionExpiry &&
      !this.state.returnWorkflow &&
      (await this.props.getOrders(true));
    if (!this.props.wsconnected)
      refreshTimeout = setTimeout(() => this.scheduleGetOrders(true), refreshRate);
  };

  sendMessage(flag) {
    if (!flag)
      setTimeout(() => {
        this.props.wsconnected ? this.props.wsMessage() : this.sendMessage(this.props.wsconnected);
      }, 1000);
  }

  displayNextOrder = async () => {
    if (this.props.orderToPack > 0) {
      const orderToPack = this.props.serviceKitOrders.filter(
        order => order.serviceKitOrderStatusType === 'OrdersToPack'
      )[0];
      const clientData = orderToPack.serviceKitOrderClientCounts.filter(
        order => order.externalClientId === this.props.selectedClient.externalClientId
      )[0];
      if (clientData.count === 0) {
        const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
          order =>
            order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
        );
        this.props.selectClientForWorkflow(otherCarrierData[0].externalClientId);
      }
      await this.setAsPacked();
      this.setState({
        currentOrderCancelled: false,
        modal: false,
        resumeStep: 0,
        returnWorkflow: false
      });
    }
  };

  render() {
    const isModalActive = this.state.modal;
    const isWorkflowActive = this.state.workflow;
    const dashTimer = <DashTimer />;
    return (
      <div className="App">
        <AppLoader />
        <Dashboard
          isHelpDrawerActive={this.state.helpDrawer}
          openSelectClient={this.openSelectClient}
          resumeWorkflow={this.resumeWorkflow}
          startReturnProcess={this.startReturnProcess}
          timer={dashTimer}
        />
        {isWorkflowActive ? (
          <Workflow
            closeModal={this.closeModal}
            closeWorkflow={this.closeWorkflow}
            isHelpDrawerActive={this.state.helpDrawer}
            modal={this.state.modal}
            openHelpDrawer={this.openHelpDrawer}
            openModal={this.openModal}
            reshipFlow={this.props.reshipFlow}
            resumeToStep={this.state.resumeStep}
            returnWorkflow={this.state.returnWorkflow}
            selectedClient={this.props.selectedClient}
            setAsPacked={this.setAsPacked}
            timer={dashTimer}
            workflowOrder={this.props.workflowOrder}
          />
        ) : null}
        {isModalActive ? (
          <ModalRouter
            closeModal={this.closeModal}
            closeWindow={this.closeWindow}
            closeWorkflow={this.closeWorkflow}
            displayNextOrder={this.displayNextOrder}
            modal={this.state.modal}
            orderToPack={this.props.orderToPack}
            selectClient={this.selectClient}
          />
        ) : null}
      </div>
    );
  }
}

App.defaultProps = {
  modalPresent: false
};

App.propTypes = {
  clearProcessingKit: PropTypes.func,
  clientCounts: PropTypes.array,
  getOrders: PropTypes.func,
  modalPresent: PropTypes.bool,
  nextServiceKitOrder: PropTypes.func,
  resumeOrder: PropTypes.func,
  wsConnect: PropTypes.func,
  wsconnected: PropTypes.bool,
  wsDisconnect: PropTypes.func,
  wsMessage: PropTypes.func
};

const mapStateToProps = ({ order, serviceKit, websocket, appInteractions }) => {
  const { clientCounts, orderToPack, selectedClient, serviceKitDisplayRows } = order;
  const { processingServiceKit, processingStarted } = serviceKit;
  return {
    clientCounts,
    modalPresent: processingStarted,
    orderToPack,
    reshipFlow: processingServiceKit && processingServiceKit.serviceType === 'RESHIP',
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    workflowOrder:
      processingServiceKit &&
      processingServiceKit.workflow &&
      processingServiceKit.workflow.serviceKitTasks,
    wsconnected: websocket.connected,
    wserrored: websocket.errored,
    ssotenabled: appInteractions.ssotenabled
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearClientSelection,
      clearProcessingKit,
      getHotNews,
      getOrders,
      nextServiceKitOrder,
      resumeOrder,
      selectClientForWorkflow,
      wsConnect,
      wsDisconnect,
      wsMessage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
