import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Col from '../../../Common/Column';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import PrintIcon from './printer.png';
import Barcode from 'react-barcode';
import './PrintESNLabel.scss';

class PrintEsnLabel extends Component {
    constructor(props) {
        super(props);
    }
    printHandleClick = () => {
        window.print();
        this.props.nextStep(this.props.step);
    };

    render() {
        return (
            <WorkflowStep
                {...this.props}
                title="Print ESN label"
                footer={
                    <PrimaryButton onClick={this.printHandleClick.bind(this)} text="Print" icon={PrintIcon} />
                }
            >
                <Row gutter>
                    <Col oneoftwo>
                        <ul>
                            <li>Print ESN label </li>
                        </ul>
                    </Col>
                    <Col oneoftwo>
                        <div className="step-7-graphic">
                            <Barcode
                                fontSize={24}
                                height={70}
                                width={3}
                                text={this.props.esnNumber}
                                value={this.props.esnNumber}
                            />
                        </div>
                    </Col>
                </Row>
            </WorkflowStep>
        );
    }
}


PrintEsnLabel.propTypes = {
    esnNumber: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
    return {
        esnNumber: serviceKit.esnNumber
    };
};


export default connect(mapStateToProps)(PrintEsnLabel);
