import React, { memo } from 'react';
import { isEmpty, objectIncludesStringValue } from '../../../utilities/genericUtility';
import './SearchSpan.scss';

const SearchSpan = ({ searchString, value }) => {
  let beginContent = 0;
  let lastContent = 0;
  let searchContent = null;
  if (objectIncludesStringValue(value, searchString)) {
    beginContent = value.toLowerCase().indexOf(searchString.toLowerCase()) + 1;
    lastContent = beginContent + searchString.length - 1;
    const searchedData = value.substring(
      beginContent !== 0 ? beginContent - 1 : beginContent,
      lastContent
    );
    searchContent = <span className="dash-table_search-string">{searchedData}</span>;
  }
  if (isEmpty(searchString)) return <>{value}</>;
  else {
    let startContent = value.substring(0, beginContent !== 0 ? beginContent - 1 : beginContent);
    if (objectIncludesStringValue(startContent, searchString)) {
      startContent = <SearchSpan value={startContent} searchString={searchString} />;
    }
    let endContent = value.substring(lastContent, value.length);
    if (objectIncludesStringValue(endContent, searchString)) {
      endContent = <SearchSpan value={endContent} searchString={searchString} />;
    }
    return (
      <div className="dash-table-search">
        {startContent}
        {searchContent}
        {endContent}
      </div>
    );
  }
};

export default memo(SearchSpan);
