import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import AlertRouter from '../Alert/AlertRouter/AlertRouter';
import HelpDrawer from './HelpDrawer/HelpDrawer';
import PackingLabel from '../PackingLabel/PackingLabel';
import StepRouter from './StepRouter/StepRouter';
import WorkflowHeader from './WorkflowHeader/WorkflowHeader';
import WorkflowProgressBar from './WorkflowProgressBar/WorkflowProgressBar';
import './Workflow.scss';

class Workflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: '',
      helpDrawer: false,
      resetCurrent: false,
      resumed: false,
      step: 1
    };
    this.closeAlert = this.closeAlert.bind(this);
    this.closeHelpDrawer = this.closeHelpDrawer.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.openAlert = this.openAlert.bind(this);
    this.openHelpDrawer = this.openHelpDrawer.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.resetCurrentFlow = this.resetCurrentFlow.bind(this);
    this.resetCurrentStep = this.resetCurrentStep.bind(this);
  }

  componentDidMount() {
    this.checkResumeToStep();
    document.addEventListener('keydown', this.handleKeypress);
  }

  componentDidUpdate() {
    this.checkResumeToStep();
    if (!this.props.modal && !this.props.returnWorkflow) this.checkKitInfoData();
    if(!this.props.modal && this.props.canceledFlag){
      this.props.openModal('MidwayCancel');
      this.resetCurrentFlow();
    }
  }

  checkResumeToStep = () => {
    const { resumeToStep } = this.props;
    const { step, resumed } = this.state;
    if (resumeToStep && resumeToStep >= 2 && resumeToStep !== step && !resumed) {
      this.setState({
        resumed: true,
        step: resumeToStep
      });
    }
  };

  checkKitInfoData = () => {
    const orders = this.props.orders.filter(
      order => order.serviceKitOrderStatusType === 'Exceptions'
    );
    const { kitReferenceNumber } = this.props.kitInfo;
    orders[0].serviceKitDisplayRows.forEach(order => {
      if (order.kitReferenceNumber === kitReferenceNumber) {
        this.props.openModal('MidwayCancel');
        this.resetCurrentFlow();
      }
    });
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress);
  }

  openHelpDrawer = () => {
    this.setState({ helpDrawer: true });
  };

  closeHelpDrawer = () => {
    this.setState({ helpDrawer: false });
  };

  openAlert = newAlert => {
    this.setState({
      alert: newAlert
    });
  };

  closeAlert = () => {
    this.setState({ alert: false });
  };

  goToStep = step => {
    this.setState({
      step: step
    });
  };

  nextStep = step => {
    const currentStep = this.state.step;
    const lastStep = !this.props.returnWorkflow ? this.props.workflowOrder.length + 1 : 2;
    if (currentStep !== lastStep && currentStep === step) {
      this.setState({
        step: currentStep + 1
      });
    } else if ((currentStep === lastStep && currentStep === step) || step === lastStep) {
      this.setState({
        step: 1
      });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    if (step !== 1) {
      this.setState({
        step: step - 1
      });
    }
  };

  resetCurrentFlow = () => {
    this.setState({
      step: 1
    });
  };

  resetCurrentStep = () => {
    this.setState({
      resetCurrent: true
    });
    setTimeout(() => {
      this.setState({
        resetCurrent: false
      });
    }, 100);
  };

  handleKeypress = e => {
    // Left key
    if (e.keyCode === 39) {
      // this.nextStep();
    }
    // Right key
    else if (e.keyCode === 37) {
      // this.prevStep();
    }
    // esc
    else if (e.keyCode === 27) {
      const { step } = this.state;
      const {
        closeModal,
        closeWorkflow,
        modal,
        openModal,
        returnWorkflow,
        workflowOrder
      } = this.props;
      if (step === 1 || step === workflowOrder.length + 1 || returnWorkflow) {
        closeWorkflow();
      } else {
        if (modal) closeModal();
        else openModal('LeaveWorkflow');
      }
    }
  };

  render() {
    const { alert, helpDrawer, resetCurrent, step } = this.state;
    const { currentJob, returnWorkflow, setAsPacked, workflowOrder } = this.props;
    const mainWorkFlow = !returnWorkflow ? (
      <>
        <WorkflowProgressBar step={step} workflowOrder={workflowOrder} />
        <PackingLabel class={step > 1 ? "packing-label--print printEsn" : "packing-label--print"} printEsn={step > 1 ? true : false} currentJob={currentJob} />
      </>
    ) : (
      <div />
    );
    return (
      <div>
        <div className={'workflow ' + (helpDrawer ? 'workflow--squeezed' : '')}>
          <WorkflowHeader
            {...this.props}
            closeHelpDrawer={this.closeHelpDrawer}
            currentJob={currentJob}
            isHelpDrawerActive={helpDrawer}
            openHelpDrawer={this.openHelpDrawer}
            step={step}
          >
            {alert ? (
              <AlertRouter
                alert={alert}
                isHelpDrawerActive={helpDrawer}
                prevStep={this.prevStep}
                resetCurrentStep={this.resetCurrentStep}
                step={step}
                workflowOrder={workflowOrder}
              />
            ) : null}
          </WorkflowHeader>
          <StepRouter
            {...this.props}
            closeAlert={this.closeAlert}
            currentJob={currentJob}
            goToStep={this.goToStep}
            nextStep={this.nextStep}
            openAlert={this.openAlert}
            resetCurrent={resetCurrent}
            resetCurrentFlow={this.resetCurrentFlow}
            resetCurrentStep={this.resetCurrentStep}
            prevStep={this.prevStep}
            setAsPacked={setAsPacked}
            step={step}
          />
          {mainWorkFlow}
        </div>
        {helpDrawer ? (
          <HelpDrawer
            closeHelpDrawer={this.closeHelpDrawer}
            returnWorkflow={returnWorkflow}
            step={step}
            workflowOrder={workflowOrder}
          />
        ) : null}
      </div>
    );
  }
}

Workflow.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closeWorkflow: PropTypes.func.isRequired,
  isHelpDrawerActive: PropTypes.bool,
  kitInfo: PropTypes.object,
  modal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  openModal: PropTypes.func.isRequired,
  order: PropTypes.object,
  resumeToStep: PropTypes.number.isRequired,
  returnWorkflow: PropTypes.bool.isRequired,
  setAsPacked: PropTypes.func.isRequired
};

const mapStateToProps = ({ order, serviceKit }) => {
  return {
    orders: order.serviceKitDisplayRows,
    kitInfo: serviceKit.processingServiceKit,
    canceledFlag: serviceKit.order_canceled_flag
  };
};

export default connect(mapStateToProps, null)(Workflow);
