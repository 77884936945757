import React, { memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import Col from '../../../Common/Column';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import dekitImage from './dekit.svg';
import repackageImage from './repackage.svg';
import './CompleteReturn.scss';

const partStatusMessages = {
  CONSUMED: {
    banner: 'Repackage order',
    buttonValue: 'RETURN COMPLETE',
    cssValue: 'consumedBanner',
    notice: [
      'This device needs to be sent back to Asurion.',
      <p>Repackage this order and affix a <strong>PRODUCT RETURN</strong> sticker to the outside of the shipping box.</p>,
      'Contact Asurion FSL Management for a shipping label.'
    ],
    image: repackageImage
  },
  IN_STOCK: {
    banner: 'De-kit order',
    buttonValue: 'RETURN COMPLETE',
    cssValue: 'instockBanner',
    notice: [
      'This order has been canceled.',
      'De-kit this order and return the device to the correct area in inventory.',
      'Discard all other items from this order.'
    ],
    image: dekitImage
  }
};

const CompleteReturn = props => {
  let displayMessages = {
    banner: 'Unable to retrieve status',
    buttonValue: 'DONE',
    cssValue: 'unableBanner',
    notice: [
      'Move the order to the exceptions table.',
      'Recheck return status in 24 hours from the kitted orders dashboard.'
    ]
  };
  if (!isEmpty(props.partStatus) && !isEmpty(partStatusMessages[props.partStatus]))
    displayMessages = partStatusMessages[props.partStatus];
  return (
    <WorkflowStep
      {...props}
      title="Check status &amp; complete return."
      footer={<PrimaryButton text={displayMessages.buttonValue} onClick={props.closeWorkflow} />}
      banner={
        <div className="workflow-primary-field">
          <div className={classNames('workflow-step__sku', displayMessages.cssValue)}>
            <span className="workflow-step__sku__number">{displayMessages.banner}</span>
          </div>
        </div>
      }
    >
      <Row gutter>
        <Col twoofthree>
          <ul>
            {displayMessages.notice.map((note, i) => (
              <li key={i}>{note}</li>
            ))}
          </ul>
        </Col>
        <Col oneoftwo>
          <img src={displayMessages.image} className="return-graphic" alt="" />
        </Col>
      </Row>
    </WorkflowStep>
  );
};

CompleteReturn.defaultProps = {
  partStatus: ''
};

CompleteReturn.propTypes = {
  partStatus: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { partStatus } = serviceKit;
  return {
    partStatus
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(memo(CompleteReturn));
