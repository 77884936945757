import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { affixAndScanLabel, scanSkuEsn, retrieveNDESSkuInfo, setCurrentNDESSkuInfo } from '../../../../actions/orderActions';
import { AlertType } from '../../../../utilities/enums';
import Col from '../../../Common/Column';
import PackingLabel from '../../../PackingLabel/PackingLabel';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import NoSKU from '../SKU/NoSKU';
// import SVG from "react-inlinesvg";

import styled from 'styled-components';
import { isEmpty } from '../../../../utilities/genericUtility';
class NDESScanSku extends PureComponent {
    state = {
        noSkuFlow: false
    };

    componentDidMount() {
        this.props.retrieveNDESSkuInfo();
    }

    componentDidUpdate() {
        if (this.props.resetCurrent && this.state.noSkuFlow) {
          this.setState({
            noSkuFlow: false
          });
        }
      }

    componentWillUnmount() {
        this.props.setCurrentNDESSkuInfo({});
    }

    noSkuInStock = () => {
        this.setState({
          noSkuFlow: true
        });
    };

    isOutOfStock = () => {
        if (
            this.props.skuQuantity === undefined ||
            this.props.skuQuantity === null
        ) {
            return true;
        } else if (this.props.skuQuantity === 0) {
            return true;
        }
        return false;
    }

    goToNextStep = async () => {
        if (isEmpty(this.props.esnNumber)) setTimeout(() => this.goToNextStep(), 1000);
        else this.props.nextStep(this.props.step);
    };

    render() {
        if (this.state.noSkuFlow) return <NoSKU {...this.props} />;
        else return (
            <WorkflowStep
                {...this.props}
                title="Scan &amp; pack a bag with item:"
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={this.props.scanSkuEsn}
                        placeholder="Scan imei"
                        rules="required|imei"
                        title="SKU Barcode"
                        type="SKU"
                        disabled={
                            this.props.skuQuantity != undefined &&
                            this.props.skuQuantity != null &&
                            this.props.skuQuantity === 0
                        }
                    />
                }
                after={
                    this.isOutOfStock() ?
                    <SecondaryButton text="SKU out of stock?" onClick={this.noSkuInStock} /> :
                    <></>
                }
            >
                <Banner><span>Bin #: {this.props.binNum}</span></Banner>
                {this.props.skuQuantity === 0 ? <OutOfStockError>*No inventory available in fixed location</OutOfStockError> : <></>}
                <Row gutter>
                    <Col oneoftwo>
                        <Wrapper>
                            <ULHeading>SKU:</ULHeading>
                            <ul>
                                <List>Get the item from inventory</List>
                                <List> {"Scan the IMEI"}</List>
                                <List>Place item in bag</List>
                            </ul>
                        </Wrapper>
                    </Col>
                    <Col>
                        <BorderBox>
                            <ULHeading>Item description:</ULHeading>
                            <TextStyle>{this.props.skuDescription}</TextStyle>
                        </BorderBox>
                    </Col>
                </Row>
            </WorkflowStep>
        );
    }
}

NDESScanSku.defaultProps = {
    kitInfo: {},
    scanAndAffixComplete: false,
    esnNumber: '',
};

NDESScanSku.propTypes = {
    kitInfo: PropTypes.object,
    scanAndAffixComplete: PropTypes.bool,
    esnNumber: PropTypes.string,
};

const mapStateToProps = ({ serviceKit, order }) => {
    const { esnNumber, processingServiceKit, scanAndAffixComplete } = serviceKit;
    const { currentOrderSkuInfo } = order;
    return {
        kitInfo: processingServiceKit,
        scanAndAffixComplete,
        binNum: currentOrderSkuInfo.binNumber,
        skuDescription: currentOrderSkuInfo.skuDescription,
        skuQuantity: currentOrderSkuInfo.quantity,
        esnNumber
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            affixAndScanLabel,
            scanSkuEsn,
            retrieveNDESSkuInfo,
            setCurrentNDESSkuInfo
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NDESScanSku);


const Banner = styled.div`
    width: 950px;
    height: 80px;
    background: #103562;
    padding:0 !important;
    margin-left: -3rem;
    span{
        font-style: normal;
        font-weight: 900;
        font-size: 58px;
        line-height: 73px;
        display: flex;
        align-items: center;
        letter-spacing: 4px;
        color: #FFFFFF;
        margin-left: 35px;
    }
`;

const ULHeading = styled.div`
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    display: flex;
    align-items: center;
    color: #000000;
`;

const TextStyle = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #000000;
`;

const List = styled.li`
    ${TextStyle}
`;


const Wrapper = styled.div`
    margin-top: 53px ;
    margin-left: 28px;
`;


const BorderBox = styled.div`
    width: 444px;
    height: 250px;
    border: 1px solid #A5AAAF;
    border-radius: 8px;
    margin-top: 28px;
    padding: 20px;
`;

const OutOfStockError = styled.span`
    color: red;
`;




