import React, { memo } from 'react';
import classNames from 'classnames';
import './DashWidgetButton.scss';

const DashWidgetButton = ({ onClick, title }) => {
  const classes = classNames('dash-widget-button');
  return (
    <button onClick={onClick} className={classes}>
      {title}
    </button>
  );
};

export default memo(DashWidgetButton);
