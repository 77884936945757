import { isEmpty } from '../utilities/genericUtility';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_INPUT_ERROR = 'SHOW_INPUT_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

const error = {
  code: '',
  message: ''
};

export const showErrorMessage = message => dispatch => {
  dispatch({ type: SHOW_ERROR, payload: isEmpty(error.message) ? message : error.message });
  setTimeout(() => dispatch({ type: HIDE_ERROR }), 3000);
};

export const showInputErrorMessage = message => dispatch => {
  dispatch({ type: SHOW_INPUT_ERROR, payload: isEmpty(error.message) ? message : error.message });
  setTimeout(() => dispatch({ type: HIDE_ERROR }), 3000);
};

export const hasError = res => {
  error.code = '';
  error.message = '';
  if (res && res.Error) {
    error.code = res.Error.Code;
    error.message = res.Error.Message;
    return true;
  } else if (res && res.error) {
    const err = res.error;
    error.code = err.code;
    error.message = err.description ? err.description : err.message;
    return true;
  } else if (res instanceof Error) return true;
  else return false;
};
