import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { affixAndScanLabel } from '../../../../actions/orderActions';
import { AlertType } from '../../../../utilities/enums';
import Col from '../../../Common/Column';
import PackingLabel from '../../../PackingLabel/PackingLabel';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
// import SVG from "react-inlinesvg";
import Bag from './Bag.svg'
import Label from './Label.svg'
import Lines from './Lines.svg'
import styled from 'styled-components';
class AffixAndScanNDES extends PureComponent {
    componentDidMount() {
        this.props.openAlert(AlertType.RetryPrinting);
    }

    componentWillUnmount() {
        this.props.closeAlert();
    }

    goToNextStep = async () => {
        this.props.nextStep(this.props.step);
    };

    render() {
        return (
            <WorkflowStep
                {...this.props}
                title="Affix packing label &amp; scan it."
                subtitle={
                    <>
                        <span>Place label on the </span>
                        <SubtitleBold>opposite</SubtitleBold>
                        <span> side of bag from where it seals, towards the middle</span>
                    </>
                }
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={this.props.affixAndScanLabel}
                        placeholder="Scan barcode"
                        rules={'required|in:' + this.props.kitInfo.kitReferenceNumber}
                        title="Packing label barcode"
                        type="barcode"
                    />
                }
            >
                <Row gutter>

                    <ImageWrapper>
                        <BagStyle src={Bag} alt='bag' />
                        <LineStyle src={Lines} alt='bag' />
                        <LabelStyle src={Label} alt='bag' />
                    </ImageWrapper>

                </Row>
            </WorkflowStep>
        );
    }
}

AffixAndScanNDES.defaultProps = {
    kitInfo: {},
    scanAndAffixComplete: false
};

AffixAndScanNDES.propTypes = {
    kitInfo: PropTypes.object,
    scanAndAffixComplete: PropTypes.bool
};

const mapStateToProps = ({ serviceKit }) => {
    const { processingServiceKit, scanAndAffixComplete } = serviceKit;
    return {
        kitInfo: processingServiceKit,
        scanAndAffixComplete
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            affixAndScanLabel
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AffixAndScanNDES);


const ImageWrapper = styled.div`
    display: flex;
    width: 70%;
`;

const BagStyle = styled.img`
    width: 300px;
    height: 220px;
`;
const LineStyle = styled.img`
    width: 215.71px;
    height: 215.79px;   
    margin-left: -37px;
    margin-top: 15px;
`;


const LabelStyle = styled.img`
    width: 400px;
    height: 255.5px;
    margin-left: -20px;
    margin-top: -12px;
`;

const SubtitleBold = styled.span`
    font-weight: 900;
`;
