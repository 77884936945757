import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import attSimInfo from './SetUpGuideSim.svg';
import './Collateral.scss';

const CollateralInbox = props => {
  return (
    <WorkflowStep
      {...props}
      title="Pack a SIM Setup Guide."
      footer={
        <>
          <PrimaryInput
            nextStep={() => props.packCollateral('INBOX')}
            placeholder="Scan barcode"
            rules={'required|collateralmain'}
            title="Collateral barcode"
            type="barcode"
          />
          {props.isSkipAllowed && (
            <Button text="Skip Step" onClick={() => props.skipStep()} type="compact" />
          )}
        </>
      }
      after={
        !props.isSkipAllowed && (
          <SecondaryButton text="Out of sim setup guides?" onClick={props.noCollateral} />
        )
      }
    >
      <Row gutter>
        <Col oneoftwo>
          <ul>
            <li>
              Get a SIM Setup Guide from the <span className="color-label red">red bin</span> in
              inventory.
            </li>
            <li>Scan the setup guide barcode on the bottom of the first page.</li>
            <li>Place it in the shipping box.</li>
          </ul>
        </Col>
        <Col oneoftwo>
          <img src={attSimInfo} className="step-4-graphic" alt="" />
        </Col>
      </Row>
    </WorkflowStep>
  );
};

CollateralInbox.defaultProps = {};

CollateralInbox.propTypes = {
  packCollateral: PropTypes.func.isRequired
};

export default memo(CollateralInbox);
