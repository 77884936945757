import React, { memo } from 'react';
import Section from '../../Section/Section';

const Collateral = () => (
  <div>
    <Section title="Scanner not working?">
      <label>Enter the barcode number manually using the keyboard and press enter.</label>
    </Section>
    <Section title="Damaged AT&T HomeTech Protection brochure?">
      <label>Rip in half, throw away, and choose another.</label>
    </Section>
  </div>
);

export default memo(Collateral);
