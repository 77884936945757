import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Col = props => {
  const {
    oneofone,
    oneoftwo,
    oneofthree,
    twoofthree,
    oneoffour,
    threeoffour,
    tag: Tag,
    ...attributes
  } = props;

  let className = classNames(
    'pp-col',
    { 'pp-col__1of1': oneofone },
    { 'pp-col__1of2': oneoftwo },
    { 'pp-col__1of3': oneofthree },
    { 'pp-col__2of3': twoofthree },
    { 'pp-col__1of4': oneoffour },
    { 'pp-col__3of4': threeoffour }
  );

  return (
    <Tag className={className} {...attributes}>
      {props.children}
    </Tag>
  );
};

Col.defaultProps = {
  tag: 'div'
};

Col.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /*
     sets only one column with width of 100%
   */
  oneofone: PropTypes.bool,
  /*
     sets only one column with width of 50%
   */
  oneoftwo: PropTypes.bool,
  /*
    sets only one column with width of 33.3%
   */
  oneofthree: PropTypes.bool,
  /*
    sets only one column with width of 66.6%
   */
  twoofthree: PropTypes.bool,
  /*
    sets only one column with width of 25%
   */
  oneoffour: PropTypes.bool,
  /*
    sets only one column with width of 75%
   */
  threeoffour: PropTypes.bool,
  children: PropTypes.object
};

export default memo(Col);
