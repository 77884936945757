import React, { memo } from 'react';
import './ExpertListSection.scss';

const Section = ({ children }) => (
  <div className="expert-list-container">
    {children.map(aename => (
      <p className="listStyle">{aename}</p>
    ))}
  </div>
);

export default memo(Section);
