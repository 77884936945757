import React, { Component } from 'react';

class DashTimerLabel extends Component {
  state = {
    showContent: false,
    updated: false,
    allSlotsCompleted: false
  };

  componentDidUpdate() {
    const { previousSlot } = this.props;
    const previousts = previousSlot.split(':');
    if (previousts[0] !== '00' && !this.state.updated) {
      const currentDate = new Date();
      const previousDate = new Date();
      previousDate.setHours(previousts[0], previousts[1], 0, 0, 0);
      const minDiff = (currentDate - previousDate) / (60 * 1000);
      this.setState({
        showContent: minDiff < 30,
        updated: true
      });
      if (minDiff < 30)
        setTimeout(() => {
          this.setState({
            showContent: false
          });
        }, (30 - minDiff) * 60 * 1000);
    }
    if (this.props.slotsCompleted && !this.state.allSlotsCompleted) {
      const currentDate = new Date();
      const previousDate = new Date();
      previousDate.setHours(previousts[0], previousts[1], 0, 0, 0);
      const diff = currentDate - previousDate;
      const minDiff = diff / (60 * 1000);
      if (minDiff > 30) {
        this.setState({
          allSlotsCompleted: true
        });
      } else {
        setTimeout(() => {
          this.setState({
            allSlotsCompleted: true
          });
        }, 30 * 60 * 1000 - diff);
      }
    }
  }

  render() {
    const valToShow = this.state.allSlotsCompleted ? 'Pickup complete' : 'Pickup in progress';
    return this.state.showContent || this.props.slotsCompleted ? (
      <span className="dash-timer__info">{valToShow}</span>
    ) : (
      <></>
    );
  }
}

export default DashTimerLabel;
