import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { affixReshipLabel, outOfStock } from '../../../../actions/orderActions';
import { AlertType } from '../../../../utilities/enums';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import NoEnvelope from '../Envelope/NoEnvelope';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import reshipContent from './reship-content.svg';
import './AffixReshipLabel.scss';

class AffixReshipLabel extends Component {
  state = {
    noEnvelope: false
  };

  componentDidMount() {
    this.props.openAlert(AlertType.ExceptionalFlow);
  }

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noEnvelope) {
      this.setState({
        noEnvelope: false
      });
    }
  }

  componentWillUnmount() {
    this.props.closeAlert();
  }

  goToNextStep() {
    if (!this.props.reshipStepComplete) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  skipStep = async () => {
    await this.props.outOfStock(WorkflowTaskMapping.AFFIXANDSCANRESHIPLABEL);
    if (this.props.stepSkipped === WorkflowTaskMapping.AFFIXANDSCANRESHIPLABEL) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[
        WorkflowTaskMapping.AFFIXANDSCANRESHIPLABEL
      ];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  noEnvelopeFlow() {
    this.setState({
      noEnvelope: true
    });
  }

  render() {
    if (this.state.noEnvelope)
      return (
        <NoEnvelope {...this.props} skipStepName={WorkflowTaskMapping.AFFIXANDSCANRESHIPLABEL} />
      );
    const { isSkipAllowed } = this.props.workflowOrder.outOfStock[
      WorkflowTaskMapping.AFFIXANDSCANRESHIPLABEL
    ];
    return (
      <WorkflowStep
        {...this.props}
        title="Affix new return label &amp; scan it."
        footer={
          <>
            <PrimaryInput
              nextStep={() => this.goToNextStep()}
              onSubmit={this.props.affixReshipLabel}
              placeholder="Scan ZIP"
              rules={this.props.selectedClient.externalClientId === '31' ? 'required|zip8' : ''}
              title="Envelope Zip"
              type="tracking number"
            />
            {isSkipAllowed && (
              <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
            )}
          </>
        }
        after={
          !isSkipAllowed && (
            <SecondaryButton
              onClick={this.noEnvelopeFlow.bind(this)}
              text="Out of return labels or envelopes?"
            />
          )
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Get a new return envelope from inventory.</li>
              <li>
                Peel a label off of the roll and affix it{' '}
                <span style={{ fontWeight: 700 }}>on top of</span> the existing return envelope
                label.
              </li>
              <li>Scan the TOP barcode, labeled “ZIP”.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={reshipContent} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

AffixReshipLabel.defaultProps = {};

AffixReshipLabel.propTypes = {};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, stepSkipped, reshipStepComplete } = serviceKit;
  return {
    reshipStepComplete,
    stepSkipped,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      affixReshipLabel,
      outOfStock
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AffixReshipLabel);
