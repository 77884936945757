import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { outOfStock, scanFlier } from '../../../../actions/orderActions';
import { WorkflowTaskMapping } from '../../../../utilities/enums';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import NoHomePlusGuide from './NoHomePlusGuide';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import SecondaryButton from '../../WorkflowStep/SecondaryButton/SecondaryButton';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import homePlusGuide from './homePlusGuide.png';

class RmaLabel extends Component {
  state = {
    noHomePlusGuide: false
  };

  componentDidUpdate() {
    if (this.props.resetCurrent && this.state.noHomePlusGuide) {
      this.setState({
        noHomePlusGuide: false
      });
    }
  }

  goToNextStep() {
    if (isEmpty(this.props.flierGuide)) setTimeout(() => this.goToNextStep(), 1000);
    else this.props.nextStep(this.props.step);
  }

  noHomePlusGuideFlow() {
    this.setState({
      noHomePlusGuide: true
    });
  }

  skipStep = async () => {
    await this.props.outOfStock(WorkflowTaskMapping.SCANHOMEPLUSGUIDE);
    if (this.props.stepSkipped === WorkflowTaskMapping.SCANHOMEPLUSGUIDE) {
      const { nextKitTask } = this.props.workflowOrder.outOfStock[
        WorkflowTaskMapping.SCANHOMEPLUSGUIDE
      ];
      this.props.goToStep(this.props.workflowOrder.serviceKitTasks.indexOf(nextKitTask) + 1);
    }
  };

  render() {
    if (this.state.noHomePlusGuide)
      return (
        <NoHomePlusGuide {...this.props} skipStepName={WorkflowTaskMapping.SCANHOMEPLUSGUIDE} />
      );
    const { isSkipAllowed } = this.props.workflowOrder.outOfStock[
      WorkflowTaskMapping.SCANHOMEPLUSGUIDE
    ];
    return (
      <WorkflowStep
        {...this.props}
        title="Pack an AT&T HomeTech Protection brochure."
        footer={
          <>
            <PrimaryInput
              nextStep={this.goToNextStep.bind(this)}
              onSubmit={itemNumber =>
                this.props.scanFlier(itemNumber, WorkflowTaskMapping.SCANHOMEPLUSGUIDE)
              }
              placeholder="Scan barcode"
              rules="required|homePlusGuide"
              title="Envelope tracking barcode"
              type="tracking number"
            />
            {isSkipAllowed && (
              <Button text="Skip Step" onClick={() => this.skipStep()} type="compact" />
            )}
          </>
        }
        after={
          !isSkipAllowed && (
            <SecondaryButton
              onClick={this.noHomePlusGuideFlow.bind(this)}
              text="Out of AHP brochures?"
            />
          )
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
             <li>Get an AT&T HomeTech Protection (AHP) brochure from inventory.</li>
              <li>Scan the barcode on the back of the brochure.</li>
              <li>Place it in the shipping box.</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={homePlusGuide} alt="" className="step-3-graphic" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

RmaLabel.defaultProps = {
  flierGuide: ''
};

RmaLabel.propTypes = {
  scanFlier: PropTypes.func.isRequired,
  flierGuide: PropTypes.string
};

const mapStateToProps = ({ serviceKit }) => {
  const { processingServiceKit, flierGuide, stepSkipped } = serviceKit;
  return {
    flierGuide,
    stepSkipped,
    workflowOrder: processingServiceKit && processingServiceKit.workflow
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      outOfStock,
      scanFlier
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RmaLabel);
