import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styled from "styled-components"

export const Banner = ({
    isOpen,
    onCloseClick,
    message,
    alertBannerType,
    iconSrc,
}) => {
    return isOpen &&
        <BannerWrapper type={alertBannerType}>
            <Icon>{iconSrc}</Icon>
            <Message>{message}</Message>
            <CrossIcon onClick={()=>onCloseClick()}><CloseOutlined /></CrossIcon>
        </BannerWrapper>
}


const BannerWrapper = styled.div`
    color: ${props => props.type === 'error' ? '#FFFFFF' : '#000000'};
    background: ${props => props.type === 'error' ? '#DB1B1B' : '#37E7A7'};
    font-weight: 400;
    font-size: 20px;
    width: 100%;
    height: 61px;
    display: flex;
    padding: 18px 10px;
`;

const Message = styled.div`
    width: 96%;
`;

const Icon = styled.div`
    width: 2%;
`;

const CrossIcon = styled.div`
    width: 2%;
`;